<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
       <h1>kdjfkdfjd</h1>
      <v-card>
       
        <!-- <v-data-table
          :headers="headers2"
          :items="users2"
          :search="search"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td>
              <td>
                {{ props.item["departmentName-2021"] }}
                <span v-if="props.item['classGroupName-2021']">{{
                  props.item["classGroupName-2021"].toUpperCase()
                }}</span>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table> -->
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
//
export default {
  components: {},
  data: () => ({
    filterSelectedDepartment: null,
    filterSelectedDepartmentClassGroup: null,

    selectedDepartment: null,
    selectedClassGroup: null,

    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    defaultItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
    bodyNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-9.jpeg");
          list.push(item);
        }
      }
      return list;
    },
    yyschool() {
      return this.userData.ppschool
    },
  },
  created() {
    this._setupp();
    this.query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        item.children = null;

        if (this.goPparent) {
          item.ref.parent.parent.get().then((doc) => {
            let pparent = doc.data();
            pparent.ref = doc.ref;
            pparent.id = doc.id;
            item.pparent = pparent;
          });
        }

        if (this.goChild) {
          item.ref
            .collection(this.childPath)
            .get()
            .then((docs) => {
              item.children = [];
              docs.forEach((doc) => {
                let child = doc.data();
                child.ref = doc.ref;
                child.id = doc.id;
                item.children.push(child);
              });
            });
        }
        this.users.push(item);
      });
    });
    //this._getResults();
  },
  watch: {
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
    filterSelectedDepartmentClassGroup: function () {
      console.log(this.filterSelectedDepartmentClassGroup);
    },
  },
  methods: {
    _setupp() {
      fb.db
        .doc(this.yyschool)
        .collection("departments")
        .where("startYear", "==", 2021)
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("classGroups")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  dep.classGroups.push(cgroup);
                });
              });
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.users.splice(this.editedIndex, 1);
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);

      for (const dep of this.departments) {
        if (
          this.editedItem["department-2021"] &&
          dep.id == this.editedItem["department-2021"].id
        ) {
          this.selectedDepartment = dep;
          this.selectedClassGroup = this.editedItem["classGroup-2021"];
          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== ""
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email) x.email = this.editedItem.email;
        if (this.editedItem.phone) x.phone = this.editedItem.phone;

        if (
          this.selectedClassGroup != null &&
          this.selectedClassGroup != undefined
        ) {
          x["classGroupName-2021"] = this.selectedClassGroup.name;
          x["classGroup-2021"] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-2021"] = this.selectedDepartment.name;
          x["department-2021"] = this.selectedDepartment.ref;
        }

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 