<template>
  <v-container>
    <v-tabs v-model="tabClassGroups" class="overflow-hidden">
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="pa-0"
        v-for="(classGroup, tabIndex) in lesson.classGroups"
        :href="'#tabClassGroups-' + (tabIndex + 1)"
        :key="'tabClassGroups' + tabIndex"
      >
        {{ classGroup.departmentName }}{{ classGroup.classGroupName }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabClassGroups" class="mt-4">
      <v-tab-item
        :value="'tabClassGroups-' + (tabIndex + 1)"
        v-for="(classGroup, tabIndex) in lesson.classGroups"
        :key="'tabItemClassGroups' + tabIndex"
      >
        <StudentsByClassGroupTable v-if="students[classGroup.classGroupRef.path]"
          :students="students[classGroup.classGroupRef.path]"
        ></StudentsByClassGroupTable>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import StudentsByClassGroupTable from "./StudentsByClassGroupTable.vue";
export default {
  data() {
    return {
      tabClassGroups: null,
    };
  },
  components: {
    StudentsByClassGroupTable,
  },
  props: {
    lesson: {
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
  },
  created(){
  }
};
</script>
