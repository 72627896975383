<template>
  <Sorils
    v-bind="$attrs"
    :categoryRefPath="$attrs.lesson.categoryRef.path"
    :allAssignments="false"
    :toCreate="false"
  ></Sorils>
</template>
<script>
// const fb = require("@/firebaseConfig.js");
import myfunction from "@/components/global/myfunction.js";
import Sorils from "@/views/Pages/Assignments/Eyesh800Embedded.vue";
export default {
  name: "light-table-transparent",
  data() {
    return {
      dialogDelete: false,
    };
  },
  mixins: [myfunction],
  props: {
    path: {
      type: String,
      required: true,
    },
    // query: {
    //   type: Object,
    //   required: true,
    // },
    headerNames: {
      type: Array,
      required: true,
    },
    // bodyNames: {
    //   type: Array,
    //   required: true,
    // },
    addButtonText: {
      type: String,
      required: true,
    },
  },
  components: {
    Sorils,
  },
  created() {
  },
};
</script>
