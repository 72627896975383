var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-6 mx-auto",staticStyle:{"max-width":"1500px","background":"white !important"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h5',{staticClass:"text-h5 text-typo font-weight-bold mb-2"},[_vm._v(" Зарлагдсан сорилууд (ЭЕШ800) ")]),_c('p',{staticClass:"text-body"},[_vm._v(" ЭЕШ800 -гаас зардлагдсан сорилууд - "),(_vm.filteredAssignmentsFinal)?_c('span',[_vm._v(_vm._s(_vm.filteredAssignmentsFinal.length))]):_vm._e()])]),_c('v-row',{staticClass:"mr-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.showInGrids)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showInGrids = !_vm.showInGrids}}},[_c('v-icon',[_vm._v("mdi-view-grid-outline")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showInGrids = !_vm.showInGrids}}},[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)],1)],1)],1),(
      _vm.filteredAssignmentsFinal &&
      _vm.filteredAssignmentsFinal.length > 0 &&
      _vm.showInGrids
    )?_c('v-row',{attrs:{"justify":"start"}},_vm._l((_vm.filteredAssignmentsFinal),function(assignment,aindex){return _c('v-col',{key:assignment.id + 'assignments' + aindex,attrs:{"lg":"4","md":"6","cols":"12"}},[_c('AssignmentCardEmbedded',_vm._b({attrs:{"assignment":assignment,"assignmentsTypes":_vm.assignmentsTypes,"lessonCategories":_vm.lessonCategories,"fromEYESH":_vm.isEyesh ? false : true,"noDetail":true}},'AssignmentCardEmbedded',_vm.$attrs,false))],1)}),1):_vm._e(),(
      _vm.filteredAssignmentsFinal &&
      _vm.filteredAssignmentsFinal.length > 0 &&
      _vm.showInGrids == false
    )?_c('v-row',{attrs:{"justify":"start"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"overflow-y":"auto","width":"100%"},attrs:{"items":_vm.filteredAssignmentsFinal,"headers":_vm.headers,"hide-details":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.index))]),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(props.item.name))]),_c('td',[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.createdMonth))]),_vm._v("/"+_vm._s(props.item.createdYear)+" ")]),_c('td',[_vm._v(_vm._s(props.item.duration))]),_c('td',[_vm._v(_vm._s(props.item.finalScore))]),_c('td',[(props.item.finished)?_c('small',{staticClass:"border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger",attrs:{"small":"","elevation":"0","ripple":false}},[_vm._v("Дууссан")]):_vm._e(),(props.item.public)?_c('small',{staticClass:"border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary",attrs:{"small":"","elevation":"0","ripple":false}},[_vm._v("Нээлттэй")]):_c('small',{staticClass:"border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info",attrs:{"small":"","elevation":"0","ripple":false}},[_vm._v("xаалттай")])])])]}}],null,false,3736530810)})],1):(_vm.filteredAssignmentsFinal.length == 0)?_c('v-row',{attrs:{"justify":"center"}},[_c('h5',{staticClass:"text-center py-16 red--text"},[_vm._v("Одоогоор сорил байxгүй байна.")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }