var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"mb-10"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" ЭЕШ800 сорилын өсөлт, амжилтын дүн шинжилгээ "),(_vm.ownAssignments)?_c('span',[_vm._v("(Нийт сорилын тоо: "+_vm._s(_vm.ownAssignments.length)+")")]):_vm._e()]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v(" Энэxүү xуудас нь ЭЕШ сорилын өсөлт, амжилтын дүн шинжилгээг сургууль, судлагдаxуун бүрээр нь xаруулна. ")])])])],1),(_vm.loading == false)?_c('div',[_c('v-card-text',{staticClass:"px-0 py-0 scoreTables mt-4"},_vm._l((_vm.categories),function(category,lindex){return _c('v-card',{key:'categories' + lindex,staticClass:"mt-10"},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"space-between\n    "}},[_c('h2',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(category.name)+" "),_c('v-btn',{staticClass:"pa-0 px-1",attrs:{"small":"","elevation":"0","color":"red","dark":"","height":"20"},on:{"click":function($event){return _vm._cal(category)}}},[_c('small',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")]),_vm._v(" update")],1)])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){category.viewMode = !category.viewMode}}},[(category.viewMode)?_c('v-icon',[_vm._v("mdi-table")]):_c('v-icon',[_vm._v("mdi-chart-line")])],1)],1),(category.viewMode)?_c('div',[(category.sorils && category.students)?_c('v-data-table',{attrs:{"headers":category.sorils,"items":category.students,"hide-default-footer":"","item-key":"id","sort-by":"average","sort-desc":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function({ item, index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},_vm._l((category.sorils.slice(
                2,
                category.sorils.length - 1
              )),function(header,hindex){return {key:_vm.getSlotName(hindex),fn:function({ item }){return [(item.sorils[hindex])?_c('td',{key:'headerr2' + hindex,staticStyle:{"cursor":"pointer"},attrs:{"title":"Материал xараx"},on:{"click":function($event){return _vm.openAssignment(header)}}},[_vm._v(" "+_vm._s(item.sorils[hindex].totalAverage)+" "),(hindex != 0)?_c('v-btn',{staticClass:"px-0",style:(item.sorils[hindex].totalAverage -
                      item.sorils[hindex - 1].totalAverage >
                    0
                      ? 'color: green'
                      : item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage <
                        0
                      ? 'color: red'
                      : ''),attrs:{"small":"","ripple":false,"text":""}},[(
                      item.sorils[hindex].totalAverage -
                        item.sorils[hindex - 1].totalAverage >
                      0
                    )?_c('v-icon',{class:item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''},[_vm._v("mdi-arrow-top-right")]):(
                      item.sorils[hindex].totalAverage -
                        item.sorils[hindex - 1].totalAverage <
                      0
                    )?_c('v-icon',{class:item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''},[_vm._v("mdi-arrow-bottom-right")]):(
                      item.sorils[hindex].totalAverage != '-' &&
                      item.sorils[hindex].totalAverage ==
                        item.sorils[hindex - 1].totalAverage
                    )?_c('v-icon',{class:item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''},[_vm._v("mdi-minus")]):_vm._e(),(
                      item.sorils[hindex].totalAverage != '-' &&
                      item.sorils[hindex - 1].totalAverage != '-'
                    )?_c('span',[_vm._v(" "+_vm._s(( item.sorils[hindex].totalAverage - item.sorils[hindex - 1].totalAverage ).toFixed(2))+" ")]):_vm._e()],1):_vm._e()],1):_c('td',{key:'headerr3' + hindex},[_vm._v("-")])]}}}),{key:"body.append",fn:function({ headers }){return [_c('tr',{staticStyle:{"background-color":"#b4b4b4"}},[_c('td',[_vm._v(_vm._s(category.students.length + 1))]),_c('td',[_vm._v("Бүх Сургуулиуд")]),_vm._l((headers.slice(2)),function(header,hindex){return _c('td',{key:'headerr' + hindex},[_vm._v(" "+_vm._s(_vm.getAverages(header, hindex, category))+" "),(hindex != 0 && header.value != 'average')?_c('v-btn',{staticClass:"px-0",style:(_vm.getAverages(header, hindex, category) -
                        _vm.getAverages(header, hindex - 1, category) >
                      0
                        ? 'color: green'
                        : _vm.getAverages(header, hindex, category) -
                            _vm.getAverages(header, hindex - 1, category) <
                          0
                        ? 'color: red'
                        : ''),attrs:{"small":"","ripple":false,"text":""}},[(
                        _vm.getAverages(header, hindex, category) -
                          _vm.getAverages(header, hindex - 1, category) >
                        0
                      )?_c('v-icon',{class:_vm.getAverages(header, hindex, category) < 10
                          ? 'ml-2'
                          : ''},[_vm._v("mdi-arrow-top-right")]):(
                        _vm.getAverages(header, hindex, category) -
                          _vm.getAverages(header, hindex - 1, category) <
                        0
                      )?_c('v-icon',{class:_vm.getAverages(header, hindex, category) < 10
                          ? 'ml-2'
                          : ''},[_vm._v("mdi-arrow-bottom-right")]):_c('v-icon',{class:_vm.getAverages(header, hindex, category) < 10
                          ? 'ml-2'
                          : ''},[_vm._v("mdi-minus")]),_vm._v(" "+_vm._s(( _vm.getAverages(header, hindex, category) - _vm.getAverages(header, hindex - 1, category) ).toFixed(2))+" ")],1):_vm._e()],1)})],2)]}}],null,true)}):_vm._e()],1):_c('div',[_vm._v(" chart here ")])],1)}),1)],1):_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-progress-circular',{staticClass:"mt-16",attrs:{"size":70,"width":7,"color":"red","indeterminate":""}})],1),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.showAssignmentDialog),callback:function ($$v) {_vm.showAssignmentDialog=$$v},expression:"showAssignmentDialog"}},[(_vm.selectedAssignmentSlideUrl)?_c('v-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm._getSlideLink())}}),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"bg-gradient-danger white--text mb-3 mr-3",on:{"click":function($event){_vm.showAssignmentDialog = !_vm.showAssignmentDialog}}},[_vm._v("Хаах")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }