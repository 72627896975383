<template>
  <v-container>
    <v-row>
      <v-col lg="12" md="12" sm="12">
        <div class="mb-10">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Тусгай сорилуудын xоорондоx өсөлт, амжилтын дүн шинжилгээ
            <span v-if="ownAssignments"
              >(Нийт сорилын тоо: {{ ownAssignments.length }})</span
            >
          </h5>
          <p class="text-sm text-body mb-0">
            Энэxүү xуудас нь ЭЕШ сорилын өсөлт, амжилтын дүн шинжилгээг
            сургууль, судлагдаxуун бүрээр нь xаруулна.
          </p>
        </div>
      </v-col>
    </v-row>
    <div v-if="loading == false">
      <v-card-text class="px-0 py-0 scoreTables">
        <v-card
          v-for="(category, lindex) in categories"
          :key="'categories' + lindex"
        >
          <v-row
            class="ma-0"
            justify="space-between
      "
          >
            <h2 class="pa-1">
              {{ category.name }}
              <v-btn
                @click="_cal(category)"
                small
                elevation="0"
                class="pa-0 px-1"
                color="red"
                dark
                height="20"
              >
                <small> <v-icon small>mdi-refresh</v-icon> update</small></v-btn
              >
            </h2>
            <v-btn icon @click="category.viewMode = !category.viewMode">
              <v-icon v-if="category.viewMode">mdi-table</v-icon>
              <v-icon v-else>mdi-chart-line</v-icon>
            </v-btn>
          </v-row>
          <div v-if="category.viewMode">
            <v-data-table
              :headers="category.sorils"
              :items="category.students"
              hide-default-footer
              item-key="id"
              sort-by="average"
              :sort-desc="true"
              :items-per-page="-1"
              v-if="category.sorils && category.students"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>
              <template
                v-slot:[getSlotName(hindex)]="{ item }"
                v-for="(header, hindex) in category.sorils.slice(
                  2,
                  category.sorils.length - 1
                )"
              >
                <td
                  :key="'headerr2' + hindex"
                  v-if="item.sorils[hindex]"
                  title="Материал xараx"
                  style="cursor: pointer"
                  @click="openAssignment(header)"
                >
                  {{ item.sorils[hindex].totalAverage }}

                  <v-btn
                    class="px-0"
                    small
                    :ripple="false"
                    v-if="hindex != 0"
                    text
                    :style="
                      item.sorils[hindex].totalAverage -
                        item.sorils[hindex - 1].totalAverage >
                      0
                        ? 'color: green'
                        : item.sorils[hindex].totalAverage -
                            item.sorils[hindex - 1].totalAverage <
                          0
                        ? 'color: red'
                        : ''
                    "
                  >
                    <v-icon
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      v-if="
                        item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage >
                        0
                      "
                      >mdi-arrow-top-right</v-icon
                    >
                    <v-icon
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      v-else-if="
                        item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage <
                        0
                      "
                      >mdi-arrow-bottom-right</v-icon
                    >
                    <v-icon
                      v-else-if="
                        item.sorils[hindex].totalAverage != '-' &&
                        item.sorils[hindex].totalAverage ==
                          item.sorils[hindex - 1].totalAverage
                      "
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      >mdi-minus</v-icon
                    >
                    <span
                      v-if="
                        item.sorils[hindex].totalAverage != '-' &&
                        item.sorils[hindex - 1].totalAverage != '-'
                      "
                    >
                      {{
                        (
                          item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage
                        ).toFixed(2)
                      }}
                    </span>
                  </v-btn>
                </td>
                <td :key="'headerr3' + hindex" v-else>-</td>
              </template>
              <template v-slot:body.append="{ headers }">
                <tr style="background-color: #b4b4b4">
                  <td>{{ category.students.length + 1 }}</td>
                  <td>Бүх Сургуулиуд</td>
                  <td
                    v-for="(header, hindex) in headers.slice(2)"
                    :key="'headerr' + hindex"
                  >
                    {{ getAverages(header, hindex, category) }}

                    <v-btn
                      class="px-0"
                      small
                      :ripple="false"
                      v-if="hindex != 0 && header.value != 'average'"
                      text
                      :style="
                        getAverages(header, hindex, category) -
                          getAverages(header, hindex - 1, category) >
                        0
                          ? 'color: green'
                          : getAverages(header, hindex, category) -
                              getAverages(header, hindex - 1, category) <
                            0
                          ? 'color: red'
                          : ''
                      "
                    >
                      <v-icon
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        v-if="
                          getAverages(header, hindex, category) -
                            getAverages(header, hindex - 1, category) >
                          0
                        "
                        >mdi-arrow-top-right</v-icon
                      >
                      <v-icon
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        v-else-if="
                          getAverages(header, hindex, category) -
                            getAverages(header, hindex - 1, category) <
                          0
                        "
                        >mdi-arrow-bottom-right</v-icon
                      >
                      <v-icon
                        v-else
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        >mdi-minus</v-icon
                      >
                      {{
                        (
                          getAverages(header, hindex, category) -
                          getAverages(header, hindex - 1, category)
                        ).toFixed(2)
                      }}
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <!-- <v-sparkline :value="value" type="trend" auto-draw :labels="labels">
          </v-sparkline> -->
            chart here
          </div>
        </v-card>
      </v-card-text>
    </div>
    <div v-else class="d-flex flex-row justify-center">
      <v-progress-circular
        class="mt-16"
        :size="70"
        :width="7"
        color="red"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-dialog v-model="showAssignmentDialog" width="1000">
      <v-card v-if="selectedAssignmentSlideUrl">
        <div v-html="_getSlideLink()"></div>
        <div class="d-flex flex-row justify-end">
          <v-btn
            class="bg-gradient-danger white--text mb-3 mr-3"
            @click="showAssignmentDialog = !showAssignmentDialog"
            >Хаах</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
// import { store } from "@/store.js";
import { sync } from "vuex-pathify";
import moment from "moment";
export default {
  name: "light-table-transparent",
  data() {
    return {
      schools: null,
      actionstatus: null,
      search: "",
      snackbar: false,
      students: null,
      categories: null,
      ownAssignments: null,
      total: 0,
      loading: true,
      selectedAssignmentSlideUrl: null,
      showAssignmentDialog: false,
    };
  },
  props: {
    schoolPath: {
      type: String,
      required: true,
    },
    selectedLessonCategoryPath: {
      type: String,
      required: true,
    },
    collectionPath: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedAssignmentSlideUrl(val) {
      if (val) {
        this.showAssignmentDialog = true;
      }
    },
  },
  computed: {
    ...sync("*"),

    getAverages() {
      return (header, hindex, category) => {
        var totalAverage = 0;
        if (header.value == "average") {
          var counter = 0;
          category.students.forEach((student) => {
            if (student.average != "-" && student.average != "0.00") {
              totalAverage = totalAverage + Number(student.average);
              counter++;
            }
          });
          totalAverage = totalAverage / counter;
        } else {
          var counter2 = 0;
          category.students.forEach((student) => {
            if (
              student.sorils[hindex] &&
              student.sorils[hindex].totalAverage != "-" &&
              student.average != "0.00"
            ) {
              totalAverage =
                totalAverage + Number(student.sorils[hindex].totalAverage);
              counter2++;
            }
          });

          totalAverage = totalAverage / counter2;
        }
        if (isNaN(totalAverage)) totalAverage = 0;
        return totalAverage.toFixed(2);
      };
    },
  },

  methods: {
    _getSlideLink() {
      var xx = null;
      if (this.selectedAssignmentSlideUrl) {
        if (this.selectedAssignmentSlideUrl.includes("docs.google.com")) {
          xx = this.selectedAssignmentSlideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            this.selectedAssignmentSlideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },
    openAssignment(assignment) {
      if (assignment.slideUrl)
        this.selectedAssignmentSlideUrl = assignment.slideUrl;
    },
    getSlotName(hindex) {
      return "item.sorils[" + hindex + "].totalAverage";
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY/MM/DD");
      }
      return "-";
    },
  },
  created() {
    console.log(this.schoolPath, this.selectedLessonCategoryPath);
    fb.db
      .doc(this.schoolPath)
      .get()
      .then((doc) => {
        this.school = doc.data();
        this.school.id = doc.id;
        this.school.ref = doc.ref;

        fb.db
          .doc(this.selectedLessonCategoryPath)
          .get()
          .then((doc) => {
            console.log(doc);
            var categories = [];
            let eyesh = doc.data();
            eyesh.ref = doc.ref;
            eyesh.id = doc.id;
            eyesh.viewMode = true;
            eyesh.sorils = null;

            if (this.collectionPath == "assignmentsdatabase") {
              var query = fb.db
                .collectionGroup("assignmentsdatabase")
                .where("categoryRef", "==", eyesh.ref)
                .where("schoolIndex", "==", this.userData.schoolIndex)
                .where("examScope.sorilIndex", "==", 1)
                .where("deleted", "==", false)
                .orderBy("createdAt", "asc");
            } else {
              query = fb.db
                .collection(this.collectionPath)
                .where("categoryRef", "==", eyesh.ref)
                .where("schoolIndex", "==", this.userData.schoolIndex)
                .where("examScope.sorilIndex", "==", 1)
                .where("deleted", "==", false)
                .orderBy("createdAt", "asc");
            }
            query.onSnapshot((docs) => {
              eyesh.sorils = [
                {
                  text: "No",
                  align: "start",
                  value: "index",
                  sortable: false,
                },
                { text: "Сурагчийн нэр", value: "name" },
              ];
              var counter = 0;
              docs.forEach((doc) => {
                let assign = doc.data();
                assign.id = doc.id;
                assign.ref = doc.ref;
                assign.text =
                  assign.name +
                  " " +
                  (assign.createdYear ? assign.createdYear : "") +
                  "/" +
                  (assign.createdMonth ? assign.createdMonth : "");
                assign.value = "sorils[" + counter + "].totalAverage";

                eyesh.sorils.push(assign);
                counter++;
              });
              eyesh.sorils.push({ text: "Дундаж", value: "average" });
            });
            eyesh.students = null;

            eyesh.ref
              .collection("lesson-students")
              .where("ppschool", "==", this.schoolPath)
              .where("deleted", "==", false)
              .get()
              .then(async (docs) => {
                eyesh.students = [];
                docs.forEach(async (doc) => {
                  let student = doc.data();
                  student.ref = doc.ref;
                  student.id = doc.id;
                  if (student.lastName) {
                    student.name = student.firstName + ". " + student.lastName;
                  } else {
                    student.name = student.firstName;
                  }
                  var sorilCounter = 1;
                  let sorils = eyesh.sorils.filter(
                    (soril) => soril.categoryRef != undefined
                  );
                  student.average = 0;
                  student.sorils = [];
                  var counter = 0;
                  await sorils.forEach((soril) => {
                    soril.ref
                      .collection("results-simplified")
                      .doc(student.id)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          let result = doc.data();
                          result.id = doc.id;
                          result.ref = doc.ref;
                          student.average = student.average + result.totalScore;
                          if (student.average != 0) counter++;
                          if (sorils.length == sorilCounter) {
                            student.average = (
                              student.average / counter
                            ).toFixed(2);

                            setTimeout(() => {
                              this.loading = false;
                            }, 1000);
                          } else {
                            this.loading = true;
                          }
                          if (isNaN(student.average)) student.average = "0.00";
                          student.sorils.push({
                            studentName: student.firstName,
                            assignmentId: result.assignmentId,
                            assignmentName: result.assignmentName,
                            totalAverage: result.totalScore.toFixed(2),
                          });

                          sorilCounter++;
                        } else {
                          if (sorils.length == sorilCounter) {
                            student.average = (
                              student.average / counter
                            ).toFixed(2);
                            setTimeout(() => {
                              this.loading = false;
                            }, 1000);
                          } else {
                            this.loading = true; //TODOA
                          }
                          if (isNaN(student.average)) student.average = "0.00";
                          student.sorils.push({
                            studentName: student.firstName,
                            assignmentId: soril.id,
                            assignmentName: soril.name,
                            totalAverage: "-",
                          });
                          sorilCounter++;
                        }
                      });
                  });
                  // console.log(student);
                  eyesh.students.push(student);
                });
              });
            categories.push(eyesh);

            this.categories = categories;
            console.log(this.categories);
          });
      });
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
