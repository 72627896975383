<template>
  <v-container
    class="py-6 mx-auto"
    style="max-width: 1500px; background: white !important"
  >
    <v-row>
      <v-col cols="6">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Зарлагдсан сорилууд (ЭЕШ800)
        </h5>
        <p class="text-body">
          ЭЕШ800 -гаас зардлагдсан сорилууд -
          <span v-if="filteredAssignmentsFinal">{{
            filteredAssignmentsFinal.length
          }}</span>
        </p>
      </v-col>
      <v-row justify="end" class="mr-4">
        <v-col cols="1">
          <v-btn icon v-if="showInGrids" @click="showInGrids = !showInGrids"
            ><v-icon>mdi-view-grid-outline</v-icon></v-btn
          >
          <v-btn icon v-else @click="showInGrids = !showInGrids"
            ><v-icon>mdi-view-list-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-row>
    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids
      "
    >
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="(assignment, aindex) in filteredAssignmentsFinal"
        :key="assignment.id + 'assignments' + aindex"
      >
        <AssignmentCardEmbedded
          :assignment="assignment"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
          :fromEYESH="isEyesh ? false : true"
          :noDetail="true"
        ></AssignmentCardEmbedded>
      </v-col>
    </v-row>

    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids == false
      "
    >
      <v-data-table
        style="overflow-y: auto; width: 100%"
        class="elevation-1"
        :items="filteredAssignmentsFinal"
        :headers="headers"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <!-- <td v-if="props.item.categoryRef">{{ props.item.categoryRef.id }}</td>
            <td v-else>-</td>
            <td>{{ props.item.ref.path }}</td> -->
            <td>{{ props.item.index }}</td>
            <td style="width: 20%">{{ props.item.name }}</td>
            <td>
              <span class="blue--text">{{ props.item.createdMonth }}</span
              >/{{ props.item.createdYear }}
            </td>
            <!-- <td>{{ props.item.ref.path }}</td>
            <td>
              <span v-if="props.item.categoryRef">{{
                props.item.categoryRef.path
              }}</span>
              <span v-else>-</span>
            </td> -->
            <td>{{ props.item.duration }}</td>
            <td>{{ props.item.finalScore }}</td>
            <td>
              <small
                v-if="props.item.finished"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                elevation="0"
                :ripple="false"
                >Дууссан</small
              >
              <small
                v-if="props.item.public"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                elevation="0"
                :ripple="false"
                >Нээлттэй</small
              >
              <small
                v-else
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                elevation="0"
                :ripple="false"
                >xаалттай</small
              >
            </td>
            <!-- <td>
              <v-icon text @click="_selectPurchaseItem(props.item)">
                mdi-close
              </v-icon>
            </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-else-if="filteredAssignmentsFinal.length == 0" justify="center">
      <h5 class="text-center py-16 red--text">Одоогоор сорил байxгүй байна.</h5>
    </v-row>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCardEmbedded from "./AssignmentCardEmbedded.vue";

export default {
  components: { AssignmentCardEmbedded },
  data: () => ({
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },
      {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    showInGrids: true,
    filteredAssignments: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories: null,
  }),
  props: {
    allAssignments: {
      type: Boolean,
      default: true,
    },
    categoryRefPath: {
      type: String,
    },
  },
  computed: {
    isEyesh() {
      return fb.EYESH_APP;
    },
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
      //return this.userData.school.ref.path + "/opened-assignments";
    },
    filteredAssignmentsFinal() {
      var list = [];
      if (this.filteredAssignments) {
        var counter = 0;
        this.filteredAssignments.forEach((element) => {
          element.index = counter + 1;
          list.push(element);
        });
      }
      return list;
    },
  },
  created() {
    this._setupp();
  },
  watch: {},
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.assignmentType &&
        this.editedItem.selectedLessonCategory
      ) {
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2;
            if (fb.EYESH_APP) {
              x2 = await fb.db.doc(this.zzschool).get();
            } else {
              x2 = await fb.dbeyesh800.doc(this.zzschool).get();
            }
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }

          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;
          this.schoolIndex = this.userData.schoolIndex
            ? this.userData.schoolIndex
            : null;
          if (this.EYESH_APP) {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.dbeyesh800
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _findx(ccc) {
      console.log(ccc, this.lessonCategories.length);
      var number = 0;
      if (this.lessonCategories) {
        for (var lcategory of this.lessonCategories) {
          if (lcategory.ref.path == ccc) {
            number = lcategory.counter;
            break;
          }
        }
      }
      return number;
    },
    async findAssignment() {
      console.log(this.categoryRefPath);
      var query;
      query = fb.dbeyesh800
        .collection(this.assignmentsDatabasePath)
        .where("deleted", "==", false);
      if (this.categoryRefPath && fb.EYESH_APP) {
        query = query.where(
          "categoryRef",
          "==",
          fb.db.doc(this.categoryRefPath)
        );
      } else {
        var x = await fb.db.doc(this.categoryRefPath).get();
        query = query.where("selectedLessonCategory.name", "==", x.data().name);
      }
      query
        .orderBy("createdAt", "desc")
        .where("createdByEmail", "==", "granditxxk@gmail.com")
        .onSnapshot((docs) => {
          this.filteredAssignments = [];
          docs.forEach(async (doc) => {
            let assign = doc.data();
            assign.id = doc.id;
            assign.ref = doc.ref;

            var xx = await this.userData.school.ref
              .collection("opened-assignments")
              .doc(assign.id)
              .get();

            if (xx.exists) {
              assign.isPublicForSchool = true;
            } else {
              assign.isPublicForSchool = false;
            }
            assign._notOrginal = true;
            assign._originalAssignmentRef = assign.ref;
            assign._originalAssignmentRefPath = assign.ref.path;
            assign.categoryRef = assign.selectedLessonCategory.ref;
            assign.categoryRefPath = assign.selectedLessonCategory.ref.path;
            if (!assign.yeshLessonCode) {
              var lessonCategory =
                await assign.selectedLessonCategory.ref.get();
              if (lessonCategory.exists) {
                assign.ref.update({
                  yeshLessonCode: lessonCategory.data().yeshLessonCode,
                });
              }
            }
            let found = this.filteredAssignments.find(
              (u) => u.id === assign.id
            );
            if (!found) this.filteredAssignments.push(assign);
          });
          console.log(this.filteredAssignments.length);
        });
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.db
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories.push(item);
                  });
                  this.findAssignment();
                });
            }
            this.findAssignment();
          });
      } else {
        fb.dbeyesh800
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories.push(item);
                  });
                  this.findAssignment();
                });
            }
            this.findAssignment();
          });
      }
    },
  },
};
</script>
