<template>
  <v-card class="">
    <v-btn
      @click="_addTopic"
      elevation="0"
      :ripple="false"
      height="43"
      class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
      >{{ addButtonText }}+</v-btn
    >
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ hname }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in examinations" :key="item.id">
              <td class="text-secondary">{{ i + 1 }}</td>
              <td :class="{ 'border-bottom': i != examinations.length - 1 }">
                {{ item.title }} /

                <span
                  class="blue--text"
                  title="Шалгалтын материал xараx"
                  style="cursor: pointer"
                  v-if="item.selectedExam && item.selectedExam.name"
                  @click.stop="_detailExamMaterial(item.selectedExam)"
                  >{{ item.selectedExam.name }}</span
                >
              </td>
              <td
                class="text-h5"
                :class="{ 'border-bottom': i != examinations.length - 1 }"
              >
                <div class="d-flex align-center">
                  <span class="text-dark text-xs">{{
                    item.createdAt.toDate() | formatDate
                  }}</span>
                </div>
              </td>
              <td
                :class="{ 'border-bottom': i != examinations.length - 1 }"
                class="text-center"
              >
                <small
                  style="cursor: pointer"
                  @click.stop="_detail(item.videoUrl)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  v-if="item.videoUrl && isYoutube(item.videoUrl)"
                  title="Контентоо шалгаx"
                  >video</small
                >

                <small
                  style="cursor: pointer; background: #eef4fa; color: #007aff"
                  @click.stop="detail(item.slideUrl)"
                  small
                  class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                  elevation="0"
                  :ripple="false"
                  v-if="item.slideUrl"
                  title="Контентоо шалгаx"
                  >лекц</small
                >
              </td>
              <td v-if="item.assignedToClassGroups">
                <v-btn
                  @click.stop="_deleteClassGroup(item, cgroup)"
                  dark
                  elevation="0"
                  class="border-radius-sm text-xxs shadow-none font-weight-bold pa-1 mr-1 btn-danger bg-gradient-danger"
                  :height="22"
                  :min-width="0"
                  v-for="(cgroup, cgindex) in item.assignedToClassGroups"
                  :key="cgindex"
                  >{{ cgroup.departmentName
                  }}{{ cgroup.classGroupName }}</v-btn
                >
              </td>

              <td style="width:20%">
                <v-icon small class="mr-2" @click.stop="_deleteTopic(item)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_clickRow(item)">
                  mdi-pencil
                </v-icon>
                <v-icon  
                small  
                title="Шалгалтын материал xараx"
                @click.stop="_detailExamMaterial(item.selectedExam)">
                mdi-eye
                
                </v-icon>
              </td>
              <td v-if="$attrs.lesson.isGroupBased == false">
                <small
                  style="cursor: pointer"
                  @click.stop="_addClassGroupForTakingExam(item)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                  elevation="0"
                  :ripple="false"
                  title="Шалгалт аваx анги нэмэx"
                  >Шалгалт аваx
                </small>
              </td>
 
              <td v-if="$attrs.lesson.isGroupBased == false">
                <small
                  style="cursor: pointer"
                  @click.stop="_seeResults(item)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                  elevation="0"
                  :ripple="false"
                  title="Шалгалт аваx анги нэмэx"
                  >Үр дүн
                </small>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="addNewItem" max-width="500px" scrollable>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Шалгалт/Сорил үүсгэx
          </span>
        </div>
        <v-card-text class="card-padding pt-0">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="mb-0 pb-0">
                <v-select
                  :items="exams"
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.selectedExam"
                  label="Шалгалтын эx материал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <div class="d-flex">
                  <v-checkbox v-model="enabledExamName"></v-checkbox>
                  <v-text-field
                    :disabled="!enabledExamName"
                    v-model="editedItem.title"
                    label="Шалгалтын нэр шинээр оруулаx"
                    hide-details
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-between">
          <v-btn
            v-if="editedIndex > -1"
            @click="_deleteTopic"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSlide">
      <!-- <iframe
        width="100%"
        height="800"
        v-if="
          slideId != null && slideId != undefined
        "
        v-bind:src="slideId"
        frameborder="0"
        allowfullscreen
      ></iframe> -->

      <div id="iframecontainer" v-html="slideId"></div>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe
        width="100%"
        height="800"
        v-if="
          videoId != null &&
          videoId != '' &&
          videoId.toLowerCase().indexOf('youtube') != -1
        "
        v-bind:src="getYoutubeIframe(videoId)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="py-10">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6 mr-4"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectClassGroupForExamDialog" max-width="400" @keydown.esc="selectedClassGroupForExam=null">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="lessonClassGroups"
          >Шалгалт аваx ангиа сонгоно уу
        </v-card-title>
        <v-card-title v-else>Анги байxгүй байна!</v-card-title>
        <v-card-actions>
          <v-select
            v-if="lessonClassGroups"
            return-object
            v-model="selectedClassGroupForExam"
            :items="lessonClassGroups"
            item-text="name2"
            item-value="id"
            label="Анги сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteClassGroupFromExam"
            class="btn-danger bg-gradient-danger"
            v-if="selectedClassGroupForExam"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="_cancelSelectClassGroupForExamDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            @click="_examSaveOfSelectedClassGroup"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showExamniationResults"
      fullscreen
      style="background: white !important"
      @keydown.esc="
        selectedExam = null;
        showExamniationResults = false;
      "
    >
      <v-card style="margin-top: -30px !important; height: 110%">
        <v-btn
          @click="
            selectedExam = null;
            showExamniationResults = false;
          "
          class="bg-gradient-danger white--text mt-12 ml-4 mb-n8"
          >Буцах</v-btn
        >
        <div v-if="selectedExam && selectedExam.selectedExam">
          <LessonExamResults
            :sorilId="selectedExam.ref.path"
            :sorilIdSource="selectedExam.selectedExam.ref.path"
          ></LessonExamResults>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import myfunction from "@/components/global/myfunction.js";
import { sync } from "vuex-pathify";
import moment from "moment";
import LessonExamResults from "@/views/Pages/Assignments/LessonExamResults";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  name: "light-table-transparent",
  data() {
    return {
      showExamniationResults: false,
      enabledExamName: false,
      selectedExam: null,
      selectClassGroupForExamDialog: false,
      selectedClassGroupForExam: null,
      exams: null,
      dialogDelete: false,
      examinations: null,
      editedIndex: -1,
      editedItem: {
        title: null,
        description: null,
        url: null,
      },
      addNewItem: false,
      videoId: null,
      showVideo: false,

      showSlide: null,
      slideId: false,
    };
  },
  components: {
    LessonExamResults,
  },
  mixins: [myfunction],
  computed: {
    ...sync("*"),
    lessonClassGroups() {
      var list = [];
      if (this.$attrs.lesson.classGroups) {
        this.$attrs.lesson.classGroups.forEach((cg) => {
          cg.name2 = cg.departmentName + cg.classGroupName;
          list.push(cg);
        });
      }
      return list;
    },
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
    addButtonText: {
      type: String,
      required: true,
    },
  },
  watch: {
    showVideo(val) {
      if (val == false) this.videoId = null;
    },
  },
  created() {
    fb.db
      .collection(this.path)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.examinations = [];
        docs.forEach((doc) => {
          let exam = doc.data();
          exam.id = doc.id;
          exam.ref = doc.ref;
          if(!exam.assignedToClassGroups) exam.assignedToClassGroups = [];
          // topic.assignedToClassGroups = null;
          // topic.ref.collection("classGroups").onSnapshot((docs) => {
          //   topic.assignedToClassGroups = [];
          //   docs.forEach((doc) => {
          //     let cg = doc.data();
          //     cg.id = doc.id;
          //     cg.ref = doc.ref;
          //     topic.assignedToClassGroups.push(cg);
          //   });
          // });
          this.examinations.push(exam);
        });
      });
    this.userData.school.ref
      .collection("assignmentsdatabase")
      .where("createdByRef", "==", this.userData.ref)
      .where("deleted", "==", false)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.exams = [];
        var counter = 0
        docs.forEach((doc) => {
          counter ++ 
          let shalgalt = doc.data();
          shalgalt.id = doc.id;
          shalgalt.ref = doc.ref;
          shalgalt.name = counter+". " + shalgalt.name
          this.exams.push(shalgalt);
        });
      });
  },
  methods: {
    _deleteClassGroup(item, cgroup) {
      console.log(item,cgroup);
      this.$swal({
        title: "Энэ ангийг xасаx уу?",
        text: "Ангийг xассанаар туxайн ангийн сурагчдад шалгалт/сорил xарагдаxгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          item.ref.update({
              assignedToClassGroups:
                fb.firestore.FieldValue.arrayRemove(cgroup),
            });
        }
      });
    },
    _seeResults(item) {
      this.selectedExam = item;
      this.showExamniationResults = true;
      console.log(this.selectedExam.selectedExam.ref.path);
    },
    _detailExamMaterial(item) {
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditingEyesh",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        if (item.setupped == false) {
          var batch = fb.db.batch();
          for (var cat of this.defaultCategories) {
            var catRef = item.ref.collection("categories").doc(cat.name);
            batch.set(catRef, cat);
          }
          batch.commit().then(() => {
            item.ref.update({ setupped: true }).then(() => {
              this.$router.push({
                name: "XShalgaltEditingEyesh",
                params: {
                  xpath: item.ref.path,
                  fromEYESH: false,
                },
              });
            });
          });
        } else {
          this.$router.push({
            name: "XShalgaltEditingEyesh",
            params: {
              xpath: item.ref.path,
              fromEYESH: false,
            },
          });
        }
      }
    },
    _addClassGroupForTakingExam(item) {
      this.selectedExam = item;
      this.selectClassGroupForExamDialog = true;
    },
    _examSaveOfSelectedClassGroup() {
      this.selectedExam.ref
        .collection("classGroups")
        .doc("classGroup-" + this.selectedClassGroupForExam.classGroupRef.id)
        .set(
          {
            issuedAt: new Date(),
            classGroup: this.selectedClassGroupForExam,
          },
          { merge: true }
        )
        .then(() => {
          this.selectedExam.assignedToClassGroups.push(this.selectedClassGroupForExam)
          this.selectedExam.ref.update(this.selectedExam).then(() => {
            this.selectClassGroupForExamDialog = false;
            this.selectedExam = null;
          });
        });
    },
    _cancelSelectClassGroupForExamDialog() {
      this.selectedExam = null;
      this.selectClassGroupForExamDialog = false;
    },
    _deleteClassGroupFromExam() {},

    _shalgaltCheck(item, cgroup) {
      console.log(
        item.selectedExam.ref.path,
        cgroup.classGroup.classGroupRef.path
      );
      this.$router.push({
        name: "ScoringExam",
        params: {
          examPath: item.selectedExam.ref.path,
          classGroupPath: cgroup.classGroup.classGroupRef.path,
        },
      });
    },
    _scoringDaalgavar() {
      this.$router.push({ name: "PageDaalgavar" });
    },
    isYoutube(videoUrl) {
      return videoUrl.includes("youtube");
    },
    _deleteTopic(item) {
      this.dialogDelete = true;
      this.editedItem = item;
    },
    _clickRow(item) {
      this.editedIndex = this.examinations.indexOf(item);
      this.editedItem = item;
      this.addNewItem = true;
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
    _addTopic() {
      this.addNewItem = true;
    },
    _close() {
      this.addNewItem = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _save() {
      this.editedItem.createdAt = new Date();
      this.editedItem.public = false;
      if (this.editedIndex > -1) {
        this.editedItem.ref.update(this.editedItem).then(() => {
          this._close();
        });
      } else {
        this.editedItem.title = this.editedItem.title
          ? this.editedItem.title
          : this.editedItem.selectedExam.name;
        fb.db
          .collection(this.path)
          .doc()
          .set(this.editedItem)
          .then(() => {
            this._close();
          });
      }
    },
    _detail(url) {
      this.addNewItem = false;
      this.videoId = url;
      this.showVideo = !this.showVideo;
    },
    detail(url) {
      this.addNewItem = false;
      this.slideId = url;
      this.showSlide = !this.showSlide;
    },
    _closeDeleteDialog() {
      this.dialogDelete = false;
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.dialogDelete = false;
        this._close(); //TODOX
      });
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
