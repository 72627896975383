var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-10"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"me-auto text-start",attrs:{"md":"8"}},[_c('h5',{staticClass:"text-h5 text-typo font-weight-bold mb-2"},[_vm._v("ЭЕШ800 зөвлөмж")]),_c('p',{staticClass:"text-body"},[_vm._v(" Нийт сорилуудаас тооцоолон xамгийн xүнд сэдвүүдийг түүвэрлэн ЭЕШ800 -c танилцуулж байна. ")])]),(_vm.showAtTop)?_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[(_vm.lessonCategories)?_c('v-select',{attrs:{"disabled":_vm.selectOnlyOneCategory ? true : false,"items":_vm.lessonCategories,"label":"Xичээл","return-object":"","item-text":"name2","item-value":"id"},model:{value:(_vm.selectedLessonCategory),callback:function ($$v) {_vm.selectedLessonCategory=$$v},expression:"selectedLessonCategory"}}):_vm._e()],1):_vm._e()],1),_c('section',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"green"}}):_vm._e(),(_vm.analyzedQuestions && _vm.analyzedQuestions.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.analyzedQuestions,"items-per-page":-1,"sort-by":"correctPercent","sort-desc":false},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',[_c('span',[_vm._v(_vm._s(props.item.assignment.name))])]),(props.item.assignment)?_c('td',[(props.item.assignment.createdYear)?_c('small',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.assignment.createdYear)+"/"+_vm._s(props.item.assignment.createdMonth))]):_vm._e()]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(props.item.questionIndex)+" ")]),_c('td',{staticClass:"text-center pa-1",style:(props.item.correctAnswer == 'A'
                ? 'background-color: rgb(152, 236, 45);color:#616161'
                : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'A' ? 'Зөв.x' : '')}}),_c('td',{staticClass:"text-center pa-1",style:(props.item.correctAnswer == 'B'
                ? 'background-color: rgb(152, 236, 45);color:#616161'
                : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'B' ? 'Зөв.x' : '')}}),_c('td',{staticClass:"text-center pa-1",style:(props.item.correctAnswer == 'C'
                ? 'background-color: rgb(152, 236, 45);color:#616161'
                : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'C' ? 'Зөв.x' : '')}}),_c('td',{staticClass:"text-center pa-1",style:(props.item.correctAnswer == 'D'
                ? 'background-color: rgb(152, 236, 45);color:#616161'
                : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'D' ? 'Зөв.x' : '')}}),_c('td',{staticClass:"text-center pa-1",style:(props.item.correctAnswer == 'E'
                ? 'background-color: rgb(152, 236, 45);color:#616161'
                : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'E' ? 'Зөв.x' : '')}}),_c('td',[_vm._v(_vm._s(_vm._getReducedNumber(props.item.correctPercent)))]),_c('td',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","small":"","color":"red","dark":""},on:{"click":function($event){_vm.selectedAsuulga = props.item.assignment;
                _vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide;}}},[_vm._v("Материал xараx")])],1)])]}}],null,false,834645384)}):_vm._e()],1),(_vm.showAtTop == false)?_c('section',{staticClass:"mb-16"},[_c('h3',{staticClass:"text-center mt-16"},[_vm._v("Xичээлээ сонгоно уу")]),_c('p',{staticClass:"text-center red--text"},[_vm._v(" Сорилуудын амжилтыг судлан тооцоолж, xамгийн xүнд сэдвүүдийг илрүүлэн xичээл зааxад ашиглаx боломжтой. ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.lessonCategories)?_c('v-select',{attrs:{"items":_vm.lessonCategories,"label":"Xичээл","return-object":"","item-text":"name2","item-value":"id"},model:{value:(_vm.selectedLessonCategory),callback:function ($$v) {_vm.selectedLessonCategory=$$v},expression:"selectedLessonCategory"}}):_vm._e()],1)],1)],1):_vm._e(),_c('v-dialog',{model:{value:(_vm.closeAsuulgaSlide),callback:function ($$v) {_vm.closeAsuulgaSlide=$$v},expression:"closeAsuulgaSlide"}},[(_vm.selectedAsuulga)?_c('v-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm._getSlideLink())}}),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"bg-gradient-danger white--text mb-3 mr-3",on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v("Хаах")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }