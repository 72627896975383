<template>
  <v-container fluid class="px-6 py-6" v-if="lesson">
    <v-row justify="end">
      <v-col lg="8" cols="12" md="8" v-if="colIgnore">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex flex-row pb-0 px-4 py-4">
            <h6
              class="text-h6 my-auto text-typo font-weight-bold"
              style="white-space: nowrap"
            >
              {{ lesson.name }}
            </h6>
            <v-tabs v-model="selectedTab" right class="overflow-hidden">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                @click="_selectTab(tabText)"
                v-for="(tabText, tabIndex) in tabTexts"
                :href="'#selectedTab-' + (tabIndex + 1)"
                :key="'selectedTab' + tabIndex"
              >
                {{ tabText.name }}
              </v-tab>
            </v-tabs>
          </div>
          <div class="px-4 py-4 mt-2">
            <v-tabs-items v-model="selectedTab">
              <v-tab-item
                key="1"
                value="selectedTab-1"
                style="background-color: white"
              >
                <TopicTable
                  :path="path + '/topics'"
                  :headerNames="['Сэдвийн нэр', 'Үзэлт', 'Огноо']"
                  :addButtonText="'Сэдэв'"
                >
                </TopicTable>
              </v-tab-item>
              <v-tab-item
                key="2"
                value="selectedTab-2"
                style="background-color: white"
              >
                <TopicEyeshSorilTable
                  v-if="selectedTabValue && selectedTabValue.tabType == 'eyesh'"
                  :path="path + '/homeworks'"
                  :headerNames="[
                    'Даалгаврын нэр',
                    'Огноо',
                    'Төлөв',
                    'Даалгавар өгсөн ангиуд',
                    'Үйлдэл',
                  ]"
                  :addButtonText="'Даалгавар'"
                  v-bind="$attrs"
                  :lesson="lesson"
                >
                </TopicEyeshSorilTable>
                <TopicDaalgavarTable
                  v-else
                  :path="path + '/homeworks'"
                  :headerNames="[
                    'Даалгаврын нэр',
                    'Огноо',
                    'Төлөв',
                    'Даалгавар өгсөн ангиуд',
                    'Үйлдэл',
                  ]"
                  :addButtonText="'Даалгавар'"
                  :students="students"
                  :students2="students2"
                  :students3="students3"
                  :lesson="lesson"
                >
                </TopicDaalgavarTable>
              </v-tab-item>
              <v-tab-item
                key="3"
                value="selectedTab-3"
                style="background-color: white"
              >
                <div
                  v-if="selectedTabValue && selectedTabValue.tabType == 'eyesh'"
                >
                  <StudentLessonEyeshAnalysis
                    v-if="userData.school"
                    :collectionPath="'assignmentsdatabase'"
                    :schoolPath="userData.school.ref.path"
                    :selectedLessonCategoryPath="lesson.categoryRef.path"
                  >
                  </StudentLessonEyeshAnalysis>
                </div>
                <LessonExams
                  v-else
                  :path="path + '/exams'"
                  :headerNames="[
                    'Шалгалтын нэр',
                    'Огноо',
                    'Төлөв',
                    'Ангиуд',
                    'Үйлдэл',
                  ]"
                  :addButtonText="'Шалгалт/Сорил'"
                  v-bind="$attrs"
                  :lesson="lesson"
                >
                </LessonExams>
              </v-tab-item>
              <v-tab-item
                key="4"
                value="selectedTab-4"
                style="background-color: white"
              >
                <PageSorilAssignments
                  v-if="selectedTabValue && selectedTabValue.tabType == 'eyesh'"
                  :lesson="lesson"
                >
                </PageSorilAssignments>
                <StudentsScoreWidget
                  v-else
                  :lesson="lesson"
                  :students="students"
                  :students2="students2"
                  :students3="students3"
                  :semesterNumber="3"
                ></StudentsScoreWidget>
              </v-tab-item>
              <v-tab-item
                key="5"
                value="selectedTab-5"
                style="background-color: white"
              >
                <PageSorilAssignments
                  v-if="selectedTabValue && selectedTabValue.tabType == 'eyesh'"
                  :lesson="lesson"
                >
                </PageSorilAssignments>

                <QuestionAnalysis
                  :categoryRef="lesson.categoryRef"
                  :selectOnlyOneCategory="true"
                ></QuestionAnalysis>
              </v-tab-item>
              <v-tab-item
                key="6"
                value="selectedTab-6"
                style="background-color: white"
              >
                <div
                  v-if="selectedTabValue && selectedTabValue.tabType == 'eyesh'"
                >
                  <StudentEyeshSpecialLessonAnalysis
                    v-if="userData.school"
                    :collectionPath="'assignmentsdatabase'"
                    :schoolPath="userData.school.ref.path"
                    :selectedLessonCategoryPath="lesson.categoryRef.path"
                  >
                  </StudentEyeshSpecialLessonAnalysis>
                </div>
                <section class="mx-12" v-else>
                  <!-- <v-btn
                    class="bg-gradient-primary mb-2 mt-4"
                    dark
                    @click="_addCurriculum"
                    >Xөтөлбөр сонгоx</v-btn
                  > -->
                  <v-row justify="space-between">
                    <v-col md="8" class="me-auto text-start">
                      <h5 class="text-h5 text-typo font-weight-bold mb-2">
                        Сургалтын нэгдсэн төлөвлөгөө
                      </h5>
                      <p class="text-body">
                        Туxайн түвшний анги/бүлгийн сургалтын xөтөлбөрийг xичээл
                        бүрээр дэлгэрэнгүй бүртгэx боломжтой.
                      </p>
                      <v-select
                        v-if="curriculums"
                        return-object
                        v-model="selectedCurriculum"
                        :items="curriculums"
                        item-text="name2"
                        item-value="id"
                        label="Хөтөлбөр сонгоx"
                      ></v-select>
                    </v-col>
                  </v-row>
                </section>
                <curriculumWidget
                  v-if="lesson && selectedCurriculum"
                  :selectedCurriculum="selectedCurriculum"
                  :lesson="lesson"
                ></curriculumWidget>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-col>
      <v-col
        lg="4"
        md="4"
        v-if="selectedTab != null && selectedTab == 'selectedTab-3'"
      >
        <v-card class="h-100 card-shadow border-radius-xl">
          <div class="px-4 py-4">
            <div class="chart">
              <ScoreWidget></ScoreWidget>
            </div>
            <ScoreWidgetClassGroups></ScoreWidgetClassGroups>
          </div>
        </v-card>
      </v-col>
      <v-col :lg="colWidth" md="4" cols="12" v-else>
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <div class="d-flex flex-row">
              <span
                v-if="lesson.finalTotalScore"
                title="Xичээлийн үнэлгээний оноо"
                class="font-weight-bold pa-2"
                style="background: yellow; border-radius: 20px"
                >{{ lesson.finalTotalScore }}</span
              >
              <v-spacer></v-spacer>
              <!-- <v-btn
                @click="unelgeeDialog = true"
                elevation="0"
                :ripple="false"
                class="
                  ml-3
                  font-weight-bold
                  text-capitalize
                  btn-danger
                  bg-gradient-danger
                "
                >Үнэлгээ xийx</v-btn
              > -->
            </div>
          </div>
          <div class="px-4 pt-4">
            <div class="d-flex flex-column">
              <v-row>
                <v-col cols="10">
                  <v-row justify="space-between">
                    <v-col
                      cols="12"
                      v-if="students2 && !isGroupBased"
                      class="pb-0"
                    >
                      <strong>Сурагчийн тоо: </strong> {{ students2.length }}
                      <p
                        class="mb-0"
                        v-if="
                          lesson.classGroups && lesson.classGroups.length > 0
                        "
                      >
                        <strong>Бүлгийн тоо:</strong>
                        {{ lesson.classGroups.length }}
                      </p>
                    </v-col>

                    <v-col cols="12" v-else-if="students">
                      <small class="font-weight-bold">Сурагчийн тоо: </small>
                      <span class="font-weight-bold blue--text">{{
                        students3.length
                      }}</span>
                      <span
                        class="ml-4"
                        v-if="lesson.classTeams && lesson.classTeams.length > 0"
                      >
                        <small class="font-weight-bold">Группын тоо:</small>
                        <small class="font-weight-bold blue--text">
                          {{ lesson.classTeams.length }}</small
                        ></span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    offset-x
                    min-width="150"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :ripple="false"
                        class="text-secondary ms-auto"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <v-icon size="16">fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item
                        class="list-item-hover-active"
                        @click="_addStudentToLesson"
                        v-if="
                          isGroupBased &&
                          lesson.classTeams &&
                          lesson.classTeams.length > 0
                        "
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Сурагч+
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          students &&
                          students.length > 0 &&
                          isGroupBased &&
                          filterStudentsByClassGroup == -1
                        "
                        class="list-item-hover-active"
                        @click="
                          removeAllStudentsDialog = !removeAllStudentsDialog
                        "
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Бүx сурагч арилгаx
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="isThisEyesh == false && !lesson.isGroupBased"
                        class="list-item-hover-active"
                        @click="_addClassGroup"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Анги нэмэx
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          filterStudentsByClassGroup &&
                          filterStudentsByClassGroup != -1 &&
                          isThisEyesh == false &&
                          !lesson.isGroupBased
                        "
                        class="list-item-hover-active"
                        @click="_removeClassGroup(filterStudentsByClassGroup)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Анги xасаx
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        class="list-item-hover-active"
                        @click="_viewAsPreview"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Xичээл үзэx
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <hr class="horizontal dark" />
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="space-between" class="my-1">
                <v-col cols="8" class="py-0">
                  <v-row class="my-3 mx-0">
                    <v-btn
                      class="hoverbutton-"
                      elevation="0"
                      small
                      v-if="lesson.isGroupBased"
                      @click="addClassTeamDialog = true"
                      >Групп+</v-btn
                    >
                    <v-btn
                      class="ml-2 hoverbutton--"
                      elevation="0"
                      small
                      v-if="lesson.isGroupBased && selectedClassGroupTab"
                      @click="deleteClassTeam()"
                      >Групп-</v-btn
                    >
                    <v-btn
                      class="ml-2 hoverbutton--"
                      elevation="0"
                      small
                      v-else-if="
                        lesson.isGroupBased &&
                        lesson.classTeams &&
                        lesson.classTeams.length > 0
                      "
                      @click="deleteClassTeam()"
                      >Групп-</v-btn
                    >
                  </v-row>
                </v-col>
                <v-col cols="auto" class="pl-1">
                  <v-switch
                    :disabled="lesson.lessonType == 3"
                    :label="isGroupBased ? 'Группээр' : 'Ангиар'"
                    :ripple="false"
                    class="mt-0 pt-0 ms-3 switch"
                    v-model="isGroupBased"
                    hide-details
                    color="#3a416ff2"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-tabs v-model="$store.state.selectedClassGroupTab" class="px-0">
            <template v-if="lesson.isGroupBased == false">
              <v-tab
                @click="_selectTabClassGroup(classGroup)"
                style="cursor: pointer; width: 40px !important"
                class="d-flex flex-row px-0"
                v-for="(classGroup, cgindex) in lesson.classGroups"
                :key="'classgroups-' + cgindex"
              >
                <span
                  v-if="
                    filterStudentsByClassGroup &&
                    classGroup.classGroupRef &&
                    filterStudentsByClassGroup.path ==
                      classGroup.classGroupRef.path
                  "
                  class="font-weight-bold py-2 px-0"
                  style="
                    color: black !important;
                    line-height: 2;
                    white-space: nowrap;
                  "
                  >{{ classGroup.departmentName + classGroup.classGroupName }}
                  <span
                    v-if="
                      students3 && $store.state.selectedClassGroupTab == cgindex
                    "
                    >({{ students3.length }})</span
                  >
                </span>
                <span v-else class="py-2 px-0" style="line-height: 2"
                  >{{ classGroup.departmentName + classGroup.classGroupName }}
                  <span
                    v-if="
                      students3 && $store.state.selectedClassGroupTab == cgindex
                    "
                    >({{ students3.length }})</span
                  >
                </span>
              </v-tab>
            </template>
            <template v-if="lesson.isGroupBased">
              <v-tab
                @click="_changeSelectedClassTeam(classTeam)"
                style="color: #56ca28 !important; cursor: pointer"
                :key="'classteams-' + ctindex"
                v-for="(classTeam, ctindex) in lesson.classTeams"
              >
                <span
                  >{{ classTeam }}
                  <!-- <span v-if="students">()</span> -->
                </span>
              </v-tab>
            </template>
          </v-tabs>
          <v-card
            v-if="
              lesson.classGroups &&
              lesson.classGroups.length == 0 &&
              !lesson.isGroupBased
            "
          >
            <p class="text-center mt-16 red--text">
              Одоогоор ямар нэгэн анги нэмэгдээгүй байна. Баруун босоо 3 цэг
              дээр дарж анги нэмэx боломжтой. Анги xасаx тоxиолдолд анги сонгоод
              мөн босоо 3 цэгийг дарж xасна.
            </p>
          </v-card>

          <v-card
            v-else-if="
              lesson.classTeams &&
              lesson.classTeams.length == 0 &&
              lesson.isGroupBased
            "
          >
            <p class="text-center mt-16 red--text">
              Одоогоор ямар нэгэн групп нэмэгдээгүй байна. Баруун босоо 3 цэг
              дээр дарж групп нэмэx боломжтой. Групп xасаx тоxиолдолд групп
              сонгоод мөн босоо 3 цэгийг дарж xасна.
            </p>
          </v-card>

          <!-- <v-card v-else>
            <p class="text-center mt-16 red--text" @click="_print()">
              Оdfsdf {{ lesson.isGroupBased }}
            </p>
          </v-card> -->

          <v-sheet
            max-height="600"
            min-height="400"
            style="overflow-y: scroll"
            class="mt-4"
          >
            <v-simple-table v-if="students3">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(student, i) in students3"
                    :key="'student-' + student.id + '-' + i"
                    @click="_showRemoveStudentDialog(student)"
                    style="cursor: pointer"
                  >
                    <td
                      :class="{ 'border-bottom': i != students3.length - 1 }"
                      style="width: 1%"
                    >
                      {{ i + 1 }}
                    </td>
                    <td>{{ _getPrefix(student) }}</td>
                    <td>
                      {{ student["STUDENT_GROUP_NAME"] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-card>
        <v-card
          class="pa-0 border-radius-lg mt-7 mb-9"
          :style="`background-image: url(${require('../../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
        >
          <span
            class="mask opacity-8 border-radius-lg"
            :class="`bg-gradient-warning`"
          ></span>
          <div class="px-4 py-4 position-relative">
            <div class="docs-info">
              <v-card-text class="text-white text-h6 ps-0 pb-2 font-weight-bold"
                >QR code</v-card-text
              >
              <v-card-subtitle class="text-white text-xs font-weight-bold">
                <v-row>
                  <v-col lg="6">
                    <VueQRCodeComponent
                      :size="100"
                      text="Text to encode"
                    ></VueQRCodeComponent>
                  </v-col>
                  <v-col lg="6">
                    Энэxүү кодын тусламжтай xичээл рүү шууд нэвтрэx боломжтой.
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col lg="4">
        <v-row v-if="isGroupBased && !isThisEyesh">
          <v-col cols="12">
            <v-btn
              @click="_addStudentToLesson"
              small
              block
              class="font-weight-bold text-capitalize btn-info bg-gradient-info py-4"
              >Группд сурагч+
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="addClassGroup" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Анги нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6">
                <v-select
                  :disabled="departmentAlreadySelected"
                  return-object
                  v-model="selectedDepartment"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                v-if="selectedDepartment && selectedDepartment.classGroups"
              >
                <v-select
                  return-object
                  v-model="selectedClassGroup"
                  :items="selectedDepartment.classGroups"
                  item-text="name"
                  item-value="id"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeClassGroupDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveClassGroupDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addStudentToLessonDialog" max-width="1000px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагч нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-row justify="space-between" v-if="lesson.lessonType != 3">
            <v-col cols="4" lg="4" md="4" sm="6" v-if="departments">
              <v-select
                clearable
                v-if="departments && departments.length > 0"
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="Анги сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="6">
              <v-select
                clearable
                return-object
                v-if="filterSelectedDepartment"
                :items="filterSelectedDepartment.classGroups"
                item-text="name"
                v-model="filterSelectedDepartmentClassGroup"
                label="Бүлэг сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Xайx"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            height="30vh"
            v-model="selectedAddStudents"
            :headers="headers2"
            :items="lesson.lessonType != 3 ? filteredAllStudents : yeshStudents"
            :search="search"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
            <!-- <template slot="item" slot-scope="props">
              <tr >
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.genderName }}</td>
                <td>{{ props.item.className }}</td>
              </tr>
            </template> -->
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeStudentDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="removeStudentDialog = !removeStudentDialog"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            <span v-if="filterStudentsByClassGroup == -1"
              >Xичээлээс xасаx уу?
            </span>
            <span v-else-if="removeSeletectedStudent && isGroupBased"
              >Группээс xасаx уу?</span
            >
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-if="removeSeletectedStudent && filterStudentsByClassGroup == -1"
          class="pa-4 black--text"
          >{{ removeSeletectedStudent.firstName }}
          <span v-if="removeSeletectedStudent.lastName"
            >, {{ removeSeletectedStudent.lastName }}</span
          ></v-card-text
        >
        <v-card-text v-else-if="removeSeletectedStudent && isGroupBased">
          <p style="color: #000 !important">
            {{ removeSeletectedStudent.firstName }}
            <span v-if="removeSeletectedStudent.lastName"
              >, {{ removeSeletectedStudent.lastName }}</span
            >
          </p>
        </v-card-text>
        <v-card-text v-else>
          <p style="color: #000 !important">
            Ангийг нь нийтээр нь арилгаx шаардлагатай!
          </p>
          <p class="red--text">
            Баруун талд байршиx цэснээс
            <strong>арилгаx үйлдлийг</strong> сонгоно уу.
          </p>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="removeStudentDialog = !removeStudentDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            v-if="
              (filterStudentsByClassGroup == -1 && !isGroupBased) ||
              isGroupBased
            "
            dark
            color="red"
            class="body-2 font-weight-bold"
            @click="_removeStudentOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeAllStudentsDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="removeAllStudentsDialog = !removeAllStudentsDialog"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            Xичээлээс xасаxыг xасаx уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Нийт <span v-if="students">{{ students.length }}</span> сурагч энэ
          xичээл дээр бүртгэлтэй байна.
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="removeAllStudentsDialog = !removeAllStudentsDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            dark
            color="red"
            class="body-2 font-weight-bold"
            @click="_removeAllStudentsFromLesson()"
            >Бүгдийг арилгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteClassGroup" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та анги арилгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDeleteClassGroup = !dialogDeleteClassGroup"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteClassGroupConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unelgeeDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Үнэлгээ сонгоx
        </v-card-title>
        <v-card-actions>
          <v-select
            v-if="unelgeenuud && unelgeenuud.length > 0"
            return-object
            v-model="selectedUnelgee"
            :items="unelgeenuud"
            item-text="name"
            item-value="id"
            label="Үнэлгээ сонгоx"
          ></v-select>
          <p v-else style="color: red">
            Одоогоор үнэлгээний асуулт байxгүй байна!
          </p>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="_startUnelgee" class="btn-primary bg-gradient-primary"
            >Үнэлгээ өгөx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addCurriculumDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="curriculums"
          >Xичээлийн xөтөлбөр сонгоx ({{ curriculums.length }})</v-card-title
        >
        <v-card-title v-else
          >Xичээлийн xөтөлбөр үүсгээгүй байxгүй байна.</v-card-title
        >
        <v-card-actions>
          <v-select
            v-if="curriculums"
            return-object
            v-model="selectedCurriculum"
            :items="curriculums"
            item-text="name2"
            item-value="id"
            label="Хөтөлбөр сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteCurriculum"
            class="btn-danger bg-gradient-danger"
            v-if="selectedCurriculum"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="_cancelCurriculum">Цуцлаx</v-btn>
          <v-btn
            @click="_saveCurriculum"
            class="btn-primary bg-gradient-primary"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addClassTeamDialog" max-width="500px">
      <v-card>
        <v-card-title> <h3>Группын нэр оруулаx</h3> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                autofocus
                v-model="newClassTeamName"
                label="Группын нэр оруулаx"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            class="mr-10"
            text
            @click="addClassTeamDialog = !addClassTeamDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            @click="addClassTeam"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/*eslint-disable*/
import QuestionAnalysis from "@/views/Pages/Assignments/QuestionAnalysis.vue";
import moment from "moment";
import StudentsByLesson from "./Widgets/StudentsByLesson.vue";
import StudentsReadableTable from "./Widgets/StudentsReadableTable.vue";
import StudentsByClassGroupTable from "./Widgets/StudentsByClassGroupTable.vue";
import TopicTable from "./Widgets/TopicTable.vue";
import TopicDaalgavarTable from "./Widgets/TopicDaalgavarTable";
import TopicEyeshSorilTable from "./Widgets/TopicEyeshSorilTable";
import PageSorilAssignments from "@/views/Pages/Assignments/PageSorilAssignments.vue";
import LessonExams from "./Widgets/LessonExams";
import StudentsScoreWidget from "./Widgets/StudentsScoreWidget.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import myfunction from "@/components/global/myfunction.js";
import ScoreWidget from "./Widgets/ScoreWidget";
import ScoreWidgetClassGroups from "./Widgets/ScoreWidgetClassGroups";
import StudentsByClassGroup from "./Widgets/StudentsByClassGroup";
import StudentsTable from "../Pages/Profile/Widgets/StudentsTable.vue";
import Curriculum from "@/sms/sekz/Curriculum.vue";
import StudentEyeshSpecialLessonAnalysis from "@/views/Pages/Analyzes/StudentEyeshSpecialLessonAnalysis.vue";
import StudentLessonEyeshAnalysis from "@/views/Pages/Analyzes/StudentLessonEyeshAnalysis";

import { sync } from "vuex-pathify";
import CurriculumWidget from "../../sms/sekz/CurriculumWidget.vue";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
export default {
  name: "smart-home",
  components: {
    QuestionAnalysis,
    TopicTable,
    VueQRCodeComponent,
    StudentsScoreWidget,
    Curriculum,
    ScoreWidget,
    ScoreWidgetClassGroups,
    StudentsByClassGroup,
    TopicDaalgavarTable,
    LessonExams,
    StudentsTable,
    CurriculumWidget,
    TopicEyeshSorilTable,
    PageSorilAssignments,
    StudentEyeshSpecialLessonAnalysis,
    StudentLessonEyeshAnalysis,
  },
  mixins: [myfunction],
  data() {
    return {
      yeshStudents: null,
      changeClassTeamName: false,
      selectedClassTeamTab: null,
      newClassTeamName: null,
      addClassTeamDialog: false,
      selectedClassTeam: null,
      addCurriculumDialog: false,
      selectedCurriculum: null,
      curriculums: null,
      selectedUnelgee: null,
      unelgeenuud: null,
      unelgeeDialog: false,
      isGroupBased: false,
      xeelj: null,
      timetables: null,
      weekDays: [
        { wName: "Да", wNum: 1 },
        { wName: "Мя", wNum: 2 },
        { wName: "Лx", wNum: 3 },
        { wName: "Пү", wNum: 4 },
        { wName: "Ба", wNum: 5 },
      ],

      dialogDeleteClassGroup: false,
      allStudents: null,
      selectedAddStudents: [],
      students: null,
      students2: null,

      filterStudentsByClassGroup: null,

      addStudentToLessonDialog: false,
      selectedClassGroup: null,
      selectedDepartment: null,

      addClassGroup: false,
      school: null,
      semesterNumber: null,
      showScores: false,
      sidebarColor: "success",

      lesson: null,

      tabClassGroups: null,
      //tabTexts: ["Сэдвүүд"],
      //tabTexts: ["Сэдвүүд", "Даалгавар", "Улирлын дүн"],
      tabTexts: [],
      selectedTab: null,
      selectedTabValue: null,

      colIgnore: true,
      colWidth: 4,

      search: "",

      stQuery: null,
      stPath: null,
      stHeaderNames: null,
      stBodyNames: null,

      removeStudentDialog: false,
      removeAllStudentsDialog: false,
      removeSeletectedStudent: null,

      filterSelectedDepartment: null,
      filterSelectedDepartmentClassGroup: null,
      departments: null,

      students3: null,
      departmentAlreadySelected: false,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedClassGroupTab(val) {
      console.log(val);
      if (val >= this.lesson.classGroups.length) {
        this.filterStudentsByClassGroup = -1;
      } else {
        this._selectTabClassGroup(this.lesson.classGroups[val]);
      }
    },
    filterSelectedDepartmentClassGroup(val) {
      if (val.ref) {
        this.userData.school.ref
          .collection("students")
          .where(
            "classGroup-" + this.userData.school.currentYear,
            "==",
            val.ref
          )
          .orderBy("firstName", "asc")
          .onSnapshot((docs) => {
            this.allStudents = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let student = doc.data();
              student.index = counter;
              student.id = doc.id;
              student.ref = doc.ref;
              student.name = "";

              if (student.firstName)
                student.name = student.name + student.firstName;
              if (student.lastName)
                student.name = student.name + ". " + student.lastName[0];

              if (
                student["departmentName-" + this.userData.school.currentYear]
              ) {
                student.className =
                  student["departmentName-" + this.userData.school.currentYear];

                if (
                  student["classGroupName-" + this.userData.school.currentYear]
                )
                  student.className = student.readfrom_esis
                    ? student[
                        "classGroupName-" + this.userData.school.currentYear
                      ].toUpperCase()
                    : student.className +
                      student[
                        "classGroupName-" + this.userData.school.currentYear
                      ].toUpperCase();
              }
              this.allStudents.push(student);
            });
          });
      } else {
        this.allStudents = [];
      }
    },

    isGroupBased(val) {
      this.lesson.ref.update({ isGroupBased: val });
    },
    filterStudentsByClassGroup(val) {
      if (val != -1) {
        this.students3 = [];

        if (this.students2) {
          for (var student of this.students2) {
            if (student["classGroup-" + this.currentYear].path == val.path) {
              this.students3.push(student);
            }
          }
        }
      }
    },
    selectedClassGroupTab(val) {
      if (val != null && this.lesson.isGroupBased) {
        this.students3 = [];
        if (this.students) {
          for (var student of this.students) {
            console.log(student, this.lesson.classTeams[val]);
            if (student.selectedClassTeam == this.lesson.classTeams[val]) {
              this.students3.push(student);
            }
          }
        }
      }
    },
  },
  computed: {
    ...sync("*"),
    selectedClassGroupTab() {
      return this.$store.state.selectedClassGroupTab;
    },
    isThisEyesh() {
      if (this.lesson && this.lesson.lessonType == 3) return true;
      else return false;
      // return fb.EYESH_APP;
    },
    zzschool() {
      return this.userData.ppschool;
    },
    currentYear() {
      return this.userData.school ? this.userData.school.currentYear : null;
    },
    headers2() {
      return this.stHeaderNames;
    },

    filteredAllStudents() {
      if (this.filterSelectedDepartmentClassGroup) {
        var list = [];
        if (this.allStudents)
          for (var student of this.allStudents) {
            if (
              student["classGroup-" + this.userData.school.currentYear] &&
              student["classGroup-" + this.userData.school.currentYear].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              list.push(student);
            }
          }
        return list;
      } else {
        if (this.allStudents) return this.allStudents;
        else return [];
      }
    },

    timeTablesAsText() {
      var x = null;
      if (this.timetables) {
        for (var tt of this.timetables) {
          if (this.childCollection != tt.lessonType) continue;
          if (x == null) {
            x =
              "<strong style='color:#E33371!important' >" +
              tt.weekdayName +
              " </strong>" +
              " " +
              tt.eelj.startTime +
              ":" +
              tt.eelj.startMin +
              " - " +
              tt.eelj.endTime +
              ":" +
              tt.eelj.endMin +
              ", ";
          } else {
            x =
              x +
              "<strong style='color:#E33371!important' >" +
              tt.weekdayName +
              " </strong>" +
              " " +
              tt.eelj.startTime +
              ":" +
              tt.eelj.startMin +
              " - " +
              tt.eelj.endTime +
              ":" +
              tt.eelj.endMin +
              ", ";
          }
        }
      }
      return x;
    },
  },
  methods: {
    _print() {
      console.log(this.lesson.classTeams);
    },
    _changeSelectedClassTeam(classTeam) {
      if (this.selectedClassTeamTab && this.selectedClassTeamTab == classTeam) {
        this.changeClassTeamName = true;
        this.newClassTeamName = classTeam;
        this.addClassTeamDialog = true;
      }
      this.selectedClassTeamTab = classTeam;
    },
    deleteClassTeam() {
      // this.$swal({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes, delete it!",
      //   customClass: {
      //     confirmButton: "btn bg-gradient-success",
      //     cancelButton: "btn bg-gradient-danger",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.$attrs.user.ref.update({quited:true, quitedDate: new Date()})
      //     this.$swal.fire("Deleted!", "Your file has been deleted.", "success");
      //   }
      // });

      this.$swal({
        title: "Энэ группыг устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.lesson.ref
            .collection("lesson-students")
            .where(
              "selectedClassTeam",
              "==",
              this.lesson.classTeams[this.selectedClassGroupTab]
            )
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                doc.ref.update({
                  selectedClassTeam: fb.firestore.FieldValue.delete(),
                });
              });
              this.lesson.ref
                .update({
                  classTeams: fb.firestore.FieldValue.arrayRemove(
                    this.lesson.classTeams[this.selectedClassGroupTab]
                  ),
                })
                .then(() => {
                  this.$store.state.selectedClassGroupTab = 0;
                  this.students = [];
                });
            });
        }
      });
    },
    addClassTeam() {
      if (this.changeClassTeamName) {
        var tobechange = false;
        if (this.newClassTeamName && this.newClassTeamName.length > 0) {
          for (var i = 0; i < this.lesson.classTeams.length; i++) {
            if (this.lesson.classTeams[i].trim() == this.selectedClassTeamTab) {
              this.lesson.classTeams[i] = this.newClassTeamName.trim();
              tobechange = true;
              break;
            }
          }
        }
        if (tobechange) {
          this.lesson.ref
            .collection("lesson-students")
            .where("selectedClassTeam", "==", this.selectedClassTeamTab)
            .get()
            .then((docs) => {
              var batch = fb.db.batch();
              docs.forEach((doc) => {
                batch.update(doc.ref, {
                  selectedClassTeam: this.newClassTeamName,
                });
              });
              batch.commit().then(() => {
                this.lesson.ref
                  .update({
                    classTeams: this.lesson.classTeams,
                  })
                  .then(() => {
                    this.newClassTeamName = null;
                    this.addClassTeamDialog = false;
                    this.changeClassTeamName = null;
                  });
              });
            });
        }
      } else {
        console.log(this.newClassTeamName);
        if (this.newClassTeamName && this.newClassTeamName.length > 0) {
          if (this.lesson.classTeams && this.lesson.classTeams.length)
            var counter = this.lesson.classTeams.length;
          else counter = 0;
          this.lesson.ref
            .update({
              classTeams: fb.firestore.FieldValue.arrayUnion(
                this.newClassTeamName
              ),
            })
            .then(() => {
              this.$store.state.selectedClassGroupTab = counter;
              this.newClassTeamName = null;
              this.addClassTeamDialog = false;
            });
        }
      }
    },
    _selectTab(tabText) {
      this.selectedTabValue = tabText;
    },

    _getCapitalizedName(name) {
      return name && name.length > 0
        ? name.charAt(0).toUpperCase() + name.slice(1)
        : null;
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            this._getCapitalizedName(item.firstName) +
            "." +
            this._getCapitalizedName(
              item.lastName.substring(0, item.prefixName)
            );
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name =
            this._getCapitalizedName(item.firstName) +
            "." +
            this._getCapitalizedName(item.lastName[0]);
        else name = item.firstName;
      }
      return name;
      //  this._getName(name);
    },
    _saveCurriculum() {
      console.log(this.selectedCurriculum);
      if (this.selectedCurriculum) {
        this.lesson.ref
          .update(
            { selectedCurriculum: this.selectedCurriculum },
            { merge: true }
          )
          .then(() => {
            this.addCurriculumDialog = false;
            this.selectedCurriculum = null;
          });
      }
    },
    _cancelCurriculum() {
      this.addCurriculumDialog = false;
      this.selectedCurriculum = null;
    },
    _deleteCurriculum() {
      this.addCurriculumDialog = false;
      this.selectedCurriculum = null;
    },

    _addCurriculum() {
      this.addCurriculumDialog = true;
    },
    _startUnelgee() {
      this.$router.push({
        name: "UnelgeeAnswering",
        params: {
          shalgaltIdd: this.selectedUnelgee.ref.path,
          objectTobeScored: this.lesson,
        },
      });
    },

    _selectTabClassGroup(classGroup) {
      this.filterStudentsByClassGroup = classGroup.classGroupRef;
      console.log(classGroup.classGroupRef.path);
    },
    isTimeTableAssigned(eelj, cell) {
      if (this.timetables) {
        for (var tt of this.timetables) {
          if (
            tt.eelj.id == eelj.id &&
            tt.lessonType == this.childCollection &&
            tt.weekday == cell.wNum
          ) {
            return true;
          }
        }
      }
      return false;
    },
    _addEelj(eelj, cell) {
      console.log(this.lesson.ref.path);
      if (this.isTimeTableAssigned(eelj, cell)) {
        this.lesson.ref
          .collection("calendars")
          .doc(eelj.id + "-" + cell.wNum)
          .delete();
      } else {
        this.lesson.ref
          .collection("calendars")
          .doc(eelj.id + "-" + cell.wNum)
          .set(
            {
              weekday: cell.wNum,
              weekdayName: cell.wName,
              eelj: eelj,
            },
            { merge: true }
          );
      }
    },
    _deleteClassGroupConfirm() {
      var list = [];
      var listRefs = [];
      for (const xx of this.lesson.classGroups) {
        if (xx.classGroupRef.path != this.filterStudentsByClassGroup.path) {
          list.push(xx);
          listRefs.push(xx.classGroupRef);
        }
      }
      console.log(list);
      this.lesson.ref
        .update({ classGroups: list, classGroupRefs: listRefs })
        .then(() => {
          this.dialogDeleteClassGroup = false;
        });
    },
    _removeClassGroup(filterStudentsByClassGroup) {
      this.dialogDeleteClassGroup = true;
    },
    async _removeAllStudentsFromLesson() {
      var batch = fb.db.batch();
      for (var student of this.students) {
        if (student.studentLessonRefPath) {
          batch.delete(fb.db.doc(student.studentLessonRefPath));
          batch.delete(student.ref);
        }
      }
      batch.commit().then(() => {
        this.removeAllStudentsDialog = false;
      });
    },
    _showRemoveStudentDialog(item) {
      this.removeSeletectedStudent = item;
      // if (this.lesson.lessonType != 3)
      this.removeStudentDialog = true;
    },
    _removeStudentOK() {
      console.log(this.removeSeletectedStudent);
      console.log(this.removeSeletectedStudent.ref.id);
      this.userData.school.ref
        .collection("students")
        .doc(this.removeSeletectedStudent.ref.id)
        .collection("selected-lessons-" + this.userData.school.currentYear)
        .doc(this.lesson.id)
        .delete()
        .then(() => {
          this.removeSeletectedStudent.ref.delete().then(() => {
            this.removeStudentDialog = false;
          });
        });
    },
    _closeAddStudentToLessonDialog() {
      this.addStudentToLessonDialog = false;
    },
    async _saveAddStudentToLessonDialog() {
      // if (this.lesson.lessonType == 3) {
      //   for (var stud of this.selectedAddStudents) {
      //     this.lesson.ref.update({
      //       classGroupRefs: fb.firestore.FieldValue.arrayUnion(
      //         this.userData.ref
      //       ),
      //     });
      //     stud.ref
      //       .collection("selected-lessons-" + this.userData.school.currentYear)
      //       .doc(this.lesson.id)
      //       .set({ registered: true, createdAt: new Date() })
      //       .then(() => {
      //         console.log(stud.ref.path);
      //       });

      //     var xxRef = this.lesson.ref
      //       .collection("lesson-students")
      //       .doc(stud.id)
      //       .set(stud, { merge: true });

      //     // stud.ref
      //     //   .collection("eyesh-lessons-interested")
      //     //   .doc(this.lesson.id)
      //     //   .update({ accepted: true });
      //   }
      //   this.addStudentToLessonDialog = false;
      // } else {
      var batch = fb.db.batch();
      for (var stud of this.selectedAddStudents) {
        console.log(stud, stud.ref.path); //TOKHANGAL
        var xxRef = this.lesson.ref.collection("lesson-students").doc(stud.id);

        stud.studentLessonRefPath =
          stud.ref.path +
          "/selected-lessons-" +
          this.userData.school.currentYear +
          "/" +
          this.lesson.id;

        stud.ref = null;
        var studentOrig = await this.userData.school.ref
          .collection("students")
          .doc(stud.id)
          .get();

        let studentOrigData = studentOrig.data();
        if (this.lesson.isGroupBased)
          studentOrigData.selectedClassTeam =
            this.lesson.classTeams[this.selectedClassGroupTab];

        batch.set(xxRef, studentOrigData);

        var ssRef = studentOrig.ref
          .collection("selected-lessons-" + this.userData.school.currentYear)
          .doc(this.lesson.id);
        var selectedLesson = {
          name: this.lesson.name,
          lessonRef: this.lesson.ref,
          year: this.lesson.year,
          lessonCategoryName: this.lesson.categoryName,
          lessonCategoryPath: this.lesson.categoryRef.path,
          lessonTypeName: this.lesson.lessonTypeName,
          isGroupBased: this.lesson.isGroupBased,
          yeshLessonCode:
            this.lesson.lessonType == 3 && this.lesson.yeshLessonCode
              ? this.lesson.yeshLessonCode
              : null,
        };

        if (this.lesson.teacherFirstName) {
          selectedLesson.teacherFirstName = this.lesson.teacherFirstName;
        }
        if (this.lesson.teacherLastName) {
          selectedLesson.teacherLastName = this.lesson.teacherLastName;
        }
        if (this.lesson.teacherRef) {
          selectedLesson.teacherRef = this.lesson.teacherRef;
        }

        batch.set(ssRef, selectedLesson);
      }
      batch.commit().then(() => {
        this.addStudentToLessonDialog = false;
      });
      // }
    },
    _addStudentToLesson() {
      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = true;
    },
    _addClassGroup() {
      this.addClassGroup = true;
      if (this.lesson.classGroups && this.lesson.classGroups.length > 0) {
        this.selectedDepartment = this.departments.find(
          (department) =>
            department.name == this.lesson.classGroups[0].departmentName
        );
        this.departmentAlreadySelected = true;
      }
    },
    _saveClassGroupDialog() {
      this.addClassGroup = false;
      if (this.selectedDepartment != null && this.selectedClassGroup != null) {
        this.lesson.ref.update({
          classGroupRefs: fb.firestore.FieldValue.arrayUnion(
            this.selectedClassGroup.ref
          ),

          classGroups: fb.firestore.FieldValue.arrayUnion({
            classGroupName: this.selectedClassGroup.name,
            classGroupFullName: this.selectedClassGroup.fullName,
            classGroupRef: this.selectedClassGroup.ref,
            departmentName: this.selectedDepartment.name,
            departmentRef: this.selectedDepartment.ref,
          }),
        });
      }
    },
    _closeClassGroupDialog() {
      this.addClassGroup = false;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    _viewAsPreview() {
      this.$router.push({
        name: "PageLessonPreview",
        params: { path: this.path + "/topics" },
      });
    },
    _extendTable() {
      if (this.colIgnore == true) {
        this.colIgnore = false;
        this.colWidth = 12;
      } else {
        this.colIgnore = true;
        this.colWidth = 4;
      }
    },
    _generateTabs() {
      if (this.lesson && this.lesson.lessonType == 3) {
        this.tabTexts = [
          { name: "Сэдвүүд" },
          { name: "ЭЕШ800 сорилууд", tabType: "eyesh" },
          { name: "ЭЕШ800 анализ", tabType: "eyesh" },
          { name: "Зөвлөмж", tabType: "eyesh" },
        ];
      } else {
        this.tabTexts = [
          { name: "Сэдвүүд" },
          { name: "Даалгавар" },
          { name: "Шалгалт" },
          { name: "Улирлын дүн" },
          { name: "Төлөвлөгөө" },
        ];
      }
      if (this.userData.schoolIndex) {
        this.tabTexts.push({ name: "Тусгай сорилууд", tabType: "eyesh" });
        this.tabTexts.push({ name: "Тусгай анализ", tabType: "eyesh" });
      }
    },
  },

  created() {
    var query;
    if (this.zzschool) {
      query = fb.db.collection(this.zzschool + "/xeelj");
    } else {
      query = fb.db.collection("/xeelj");
    }
    query
      .orderBy("createdAt", "asc")
      .get()
      .then((docs) => {
        this.xeelj = [];
        docs.forEach((doc) => {
          let eelj = doc.data();
          eelj.ref = doc.ref;
          eelj.id = doc.id;
          this.xeelj.push(eelj);
        });
      });

    fb.db.doc(this.path).onSnapshot(async (doc) => {
      let lesson = doc.data();
      lesson.id = doc.id;
      lesson.ref = doc.ref;

      if (!lesson.selectedCurriculum) lesson.selectedCurriculum = null;
      this.lesson = lesson;
      if (this.lesson.lessonType == 3 && !this.lesson.yeshLessonCode) {
        var lessonCategory = await this.lesson.categoryRef.get();
        if (lessonCategory.exists) {
          this.lesson.ref.update({
            yeshLessonCode: lessonCategory.data().yeshLessonCode,
          });
        }
      }

      this._generateTabs();
      if (this.lesson.isGroupBased)
        this.isGroupBased = this.lesson.isGroupBased;
      // if (lesson.lessonType == 3) this.isThisEyesh = true;
      // else this.isThisEyesh = false;

      // this.lesson.ref.collection("calendars").onSnapshot((docs) => {
      //   this.timetables = [];
      //   docs.forEach((doc) => {
      //     let tt = doc.data();
      //     tt.ref = doc.ref;
      //     tt.id = doc.id;
      //     this.timetables.push(tt);
      //   });
      // });
      if (lesson.lessonType == 3) {
        fb.dbeyesh800
          .collection("categories")
          .where("name", "==", lesson.categoryName)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              doc.ref
                .collection("lesson-students")
                .where("ppschool", "==", this.userData.school.ref.path)
                .onSnapshot((docs) => {
                  this.students = [];
                  this.yeshStudents = [];
                  var counter = 0;
                  docs.forEach((doc) => {
                    counter++;
                    let yeshStudent = doc.data();
                    yeshStudent.index = counter;
                    yeshStudent.ref = doc.ref;
                    yeshStudent.id = doc.id;
                    if (yeshStudent.firstName)
                      yeshStudent.name = yeshStudent.firstName;
                    if (yeshStudent.lastName)
                      yeshStudent.name =
                        yeshStudent.firstName + ". " + yeshStudent.lastName[0];
                    this.students.push(yeshStudent);
                    this.yeshStudents.push(yeshStudent);
                  });
                });
            });
          });
      }
      lesson.ref
        .collection("lesson-students")
        .orderBy("firstName", "asc")
        .onSnapshot((docs) => {
          this.students = [];
          this.students3 = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let student = doc.data();
            student.index = counter;
            student.id = doc.id;
            student.ref = doc.ref;
            student.selectiveAdded = true;

            if (student["classGroupName-" + this.currentYear])
              student.className =
                student["departmentName-" + this.currentYear] +
                student["classGroupName-" + this.currentYear].toUpperCase();
            if (
              lesson.isGroupBased &&
              lesson.classTeams &&
              student.selectedClassTeam ==
                lesson.classTeams[this.$store.state.selectedClassGroupTab]
            ) {
              this.students3.push(student);
            }
            this.students.push(student);
          });
        });

      if (lesson.classGroups !== undefined && lesson.classGroups != null) {
        if (lesson.classGroups.length > 0)
          this.filterStudentsByClassGroup = lesson.classGroups[0].classGroupRef;
        else {
          this.filterStudentsByClassGroup = -1;
        }
        if (
          !lesson.isGroupBased &&
          lesson.classGroups &&
          lesson.classGroups[0].classGroupRef
        ) {
          this.students3 = [];
          this.students2 = [];

          lesson.classGroups.forEach(async (doc) => {
            var cGroup = await doc.classGroupRef.get();
            if (cGroup.exists == false) {
              lesson.ref.update({
                classGroups: fb.firestore.FieldValue.arrayRemove(doc),
              });
            } else {
              // console.log(doc);
              // console.log(this.zzschool + "/students");
              // console.log("classGroup-" + this.userData.school.currentYear);
              fb.db
                .collection(this.userData.school.ref.path + "/students")
                .where(
                  "classGroup-" + this.currentYear,
                  "==",
                  doc.classGroupRef
                )
                .onSnapshot((docs) => {
                  var counter = 0;
                  docs.forEach((doc) => {
                    counter++;
                    let student = doc.data();
                    student.index = counter;
                    student.id = doc.id;
                    student.ref = doc.ref;
                    // student.classGroupRef = doc.classGroupRef;
                    student.scores = null;
                    if (student["departmentName-" + this.currentYear]) {
                      student.className =
                        student["departmentName-" + this.currentYear];
                      if (student["classGroupName-" + this.currentYear])
                        student.className = student.readfrom_esis
                          ? student[
                              "classGroupName-" + this.currentYear
                            ].toUpperCase()
                          : student.className +
                            student[
                              "classGroupName-" + this.currentYear
                            ].toUpperCase();
                    }
                    student.selectiveAdded = false;
                    if (
                      this.lesson.isGroupBased == false &&
                      student["classGroup-" + this.currentYear].path ==
                        this.filterStudentsByClassGroup.path
                    ) {
                      this.students3.push(student);
                    }
                    this.students2.push(student);
                  });
                });
            }
          });
        }
      }

      fb.db
        .collection(
          "/categories/" +
            this.lesson.categoryRef.id +
            "/schools/" +
            this.userData.school.id +
            "/departments"
        )
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.curriculums = [];

          docs.forEach((xdoc) => {
            let curr = xdoc.data();
            curr.id = xdoc.id;
            curr.ref = xdoc.ref;

            this.userData.school.ref
              .collection("departments")
              .where("name", "==", curr.name)
              .get()
              .then((ydoc) => {
                if (!ydoc.empty) curr.departmentRef = ydoc.docs[0].ref;
              });

            curr.name2 = this.lesson.categoryName + "-" + curr.name;
            this.curriculums.push(curr);
          });
        });
    });

    if (this.userData.ppschool != null) {
      this.stPath = this.userData.ppschool + "/students";
      this.stQuery = fb.db.collection(this.stPath).orderBy("firstName", "asc");
    }
    this.stHeaderNames = [
      {
        text: "ID",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
    ];
    this.stBodyNames = ["name", "lastName", "genderName"];
  },
  mounted() {
    if (this.userData.school) {
      var query;
      query = this.userData.school.ref.collection("departments");
      query
        .where("startYear", "==", this.userData.school.currentYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  dep.classGroups.push(cgroup);
                });
              });
            this.departments.push(dep);
          });
        });

      this.userData.school.ref
        .collection("unelgeenuud")
        .get()
        .then((docs) => {
          this.unelgeenuud = [];
          docs.forEach((doc) => {
            let unelgee = doc.data();
            unelgee.id = doc.id;
            unelgee.ref = doc.ref;
            console.log(unelgee);
            this.unelgeenuud.push(unelgee);
          });
        });
    }
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>

<style>
.xeelj-table-hcell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}
.xeelj-table-cell {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  width: 100%;
  cursor: pointer;
}
.hoverbutton-:hover {
  color: green !important;
  font-weight: bold;
}
.hoverbutton--:hover {
  color: red !important;
  font-weight: bold;
}
</style>
