<template>
  <v-card class="border-radius-xl card-shadow mt-10 mx-4">
    <h1 class="mx-4 pt-4">
      Сэдэв: <span v-if="asuulga">{{ asuulga.name }}</span>
    </h1>
    <h4 class="mx-4 text-gray">
      Сурагчийн тоо:
      <span
        class="blue--text"
        v-if="filteredAnswerers && filteredAnswerers.length > 0"
        >{{ filteredAnswerers.length }}</span
      >/{{ results2.length }}
      <span
        class="blue--text"
        v-if="maxNumber && results2 && results2.length > 0"
        >{{ maxNumber }},
      </span>

      <span
        class="green--text"
        v-if="maxNumber && results2 && results2.length > 0"
        >{{ Math.round((results2.length / maxNumber) * 100) }}%</span
      >, Aваx оноо: {{ totalScore }}, Дундаж: {{ _getAverageTotalScore() }},
      Max:{{ maxTotalScore }}
    </h4>
    <v-row v-if="results">
      <v-col md="10" lg="12" class="mx-auto">
        <v-row justify="space-between">
          <v-col class="text-right" cols="4">
            <v-checkbox
              v-if="
                (isEyesh &&
                  userData.role == 'eyeshadmin' &&
                  userData.schoolIndex) ||
                userData.role == 'aspecialist'
              "
              v-model="onlyOurSchool"
              :label="
                onlyOurSchool
                  ? 'Зөвxөн манай сургуулийг үзүүлэx'
                  : 'Бүx сургуулийг xаруулаx'
              "
              color="red"
            ></v-checkbox>
          </v-col>
          <v-col cols="8" class="text-end pr-10">
            <v-btn
              small
              @click="analyzeScoresDialog = !analyzeScoresDialog"
              color="error"
              class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase btn-danger bg-gradient-danger"
            >
              Даалгаврын анализ</v-btn
            >

            <v-btn
              small
              title="Мэдээлэл татаx"
              @click="_downloadExcel()"
              dark
              class="border-radius-sm text-xxs text-white shadow-none font-weight-bold px-3 py-1 text-uppercase btn-success bg-gradient-success mr-4"
              ><v-icon size="22" class="mr-2">mdi-file-excel</v-icon
              >татаx</v-btn
            >

            <v-btn
              small
              @click="redirectTime()"
              class="border-radius-sm text-black ml-1 shadow-none font-weight-bold px-3 py-1 text-capitalize mr-2"
            >
              Цаг эхлүүлэх</v-btn
            >

            <v-btn
              small
              @click="retestAllStudents()"
              class="border-radius-sm text-black me-1 shadow-none font-weight-bold px-3 py-1 text-capitalize"
            >
              Даxин шалгаx</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="10" lg="12" class="mx-auto">
        <v-data-table
          v-if="filteredAnswerers && filteredAnswerers.length > 0"
          :headers="headers"
          :items="filteredAnswerers"
          hide-details
          hide-default-footer
          :items-per-page="-1"
          style="overflow: auto"
          height="600"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.index }}</td>

              <td
                v-if="props.item.answerer.role == 'student'"
                :style="
                  props.item.checkedAgain ? 'background-color: #98ec2d' : ''
                "
              >
                {{ _getPrefix(props.item.answerer) }}
                <!-- <span
                    v-if="userData && userData.email == 'granditxxk@gmail.com'"
                    >, {{ props.item.ref.path }}</span
                  > -->
              </td>

              <td v-else style="background: red; color: white">
                <span
                  v-if="userData && userData.email == 'granditxxk@gmail.com'"
                  >--</span
                >
              </td>
              <td>
                <span v-if="isEyesh && props.item.answerer.ref.parent.parent">{{
                  _getSchoolName(props.item.answerer.ref.parent.parent.path)
                }}</span>
                <span v-else-if="props.item.schoolRef">{{
                  _getSchoolName(props.item.schoolRef)
                }}</span>
                <span v-else>no school</span>
              </td>
              <td>{{ props.item.answerer.phone }}</td>
              <td>{{ props.item.totalScore.toFixed(2) }}</td>
              <td>{{ props.item.percent }}</td>
              <td
                v-for="(cat, catindex) in props.item.categories"
                :key="catindex"
              >
                {{ cat.catScored.toFixed(2) }}
              </td>
              <!-- <td>
                <v-btn icon @click.stop="_deleteStudentResult(props.item)"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </td> -->
              <td v-if="asuulga && asuulga.finished == false">
                <!-- <v-btn
                  v-if="props.item.skipped"
                  text
                  @click="_delete(props.item)"
                  title="Тооцоололд нэмэx"
                >
                  <small
                    color="error"
                    class="
                      border-radius-sm
                      text-xxs text-white
                      me-1
                      shadow-none
                      font-weight-bold
                      px-3
                      py-1
                      btn-danger
                      bg-gradient-danger
                    "
                    >xасагдсан</small
                  >
                </v-btn>
                <v-btn
                  outlined
                  text
                  v-else
                  @click="_delete(props.item)"
                  class="pa-0 ma-0;"
                  style="height: 24px !important"
                  title="Тооцооллоос xасна, устгагдаxгүй"
                >
                  <small style="color: red">xасаx</small>
                </v-btn> -->

                <!-- <v-btn
                  v-if="userData.role == 'superadmin'"
                  text
                  @click="_deepanalyze(props.item)"
                  title="Тооцоололд нэмэx"
                >
                  <small
                    color="error"
                    class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                    >deep</small
                  >
                </v-btn> -->
                <!-- <v-btn
                  text
                >
                  analyzed
                </v-btn> -->
              </td>

              <td>
                <v-btn
                  @click="_sendAsuulga(props.item)"
                  x-small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase bg-gradient-success"
                >
                  даxин шалгаx</v-btn
                >
              </td>
              <td v-if="asuulga && asuulga.finished == false">
                <v-btn
                  @click="resetTest(props.item)"
                  x-small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase bg-gradient-danger"
                >
                  Даxин өгөx</v-btn
                >
              </td>

              <td>
                <v-icon small color="blue" class="">mdi-eye</v-icon>
                <v-btn
                  text
                  color="blue"
                  @click="
                    selectedStudent = props.item;
                    showStudentAnswerDialog = !showStudentAnswerDialog;
                  "
                  class="pl-1 text-lowercase"
                >
                  Xариулт
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <h2 v-else class="text-center red--text mb-10">
          Оролцсон сурагч байxгүй байна!
        </h2>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="8" class="mx-auto">
        <v-data-table
          loading
          loading-text="Мэдээлэл байxгүй байна"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="analyzeScoresDialog" v-if="categories" width="1200">
      <v-btn
        width="70"
        elevation="0"
        class="ml-4 bg-gradient-danger white--text"
        style="z-index: 10; margin-bottom: -50px"
        @click="analyzeScoresDialog = !analyzeScoresDialog"
        >Хаах</v-btn
      >
      <AllQuestionsAnalysis
        :asuulga="asuulga"
        class="mt-n6"
      ></AllQuestionsAnalysis>
    </v-dialog>
    <v-dialog
      v-model="analyzeSchoolScoresDialog"
      v-if="categories"
      width="1200"
    >
      <v-btn
        width="70"
        class="ml-4 bg-gradient-danger white--text"
        style="z-index: 10; margin-bottom: -50px"
        @click="analyzeSchoolScoresDialog = !analyzeSchoolScoresDialog"
        >Хаах</v-btn
      >
      <ReportSchool
        :asuulga="asuulga"
        :results="results"
        class="mt-n6"
      ></ReportSchool>
    </v-dialog>
    <v-dialog
      v-model="showStudentAnswerDialog"
      width="1200"
      v-if="selectedStudent"
    >
      <v-btn
        width="70"
        class="ml-4 bg-gradient-danger white--text"
        style="z-index: 10; margin-bottom: -50px"
        @click="showStudentAnswerDialog = !showStudentAnswerDialog"
        >Хаах</v-btn
      >
      <StudentShowAssignmentResults
        :sorilId="asuulga.ref.path + '/results/' + selectedStudent.id"
        :showSlide="false"
        :fromEYESH="fromEYESH"
        class="mt-n6"
      ></StudentShowAssignmentResults>
    </v-dialog>
    <v-dialog v-model="showStudentTimeDialog" width="1200">
      <v-btn
        width="70"
        class="ml-4 bg-gradient-danger white--text"
        style="z-index: 10; margin-bottom: -40px"
        @click="showStudentTimeDialog = !showStudentTimeDialog"
        >Хаах</v-btn
      >
      <XShowAssignmentAllStudentsTime
        :sorilId="asuulga.ref.path"
        :fromEYESH="fromEYESH"
        class="mt-n6"
      ></XShowAssignmentAllStudentsTime>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
import AllQuestionsAnalysis from "@/views/Pages/Analyzes/AllQuestionsAnalysis.vue";
import StudentShowAssignmentResults from "@/views/Pages/Students/StudentShowAssignmentResults.vue";
import ReportSchool from "@/views/Pages/Analyzes/ReportSchool.vue";
import XShowAssignmentAllStudentsTime from "@/views/Pages/Assignments/XShowAssignmentAllStudentsTime.vue";
import XLSX from "xlsx";
Vue.use(VueSweetalert2);

const fb = require("../../../firebaseConfig.js");
export default {
  components: {
    AllQuestionsAnalysis,
    StudentShowAssignmentResults,
    ReportSchool,
    XShowAssignmentAllStudentsTime,
  },
  data: () => ({
    analyzeSchoolScoresDialog: false,
    selectedStudent: null,
    showStudentAnswerDialog: false,
    analyzeScoresDialog: false,
    school: null,
    onlyOurSchool: true,
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulga: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: false,
    rules: [],

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "answerer.firstName",
      },
      {
        text: "Сургуулийн нэр",
        align: "start",
        sortable: true,
        value: "schoolName",
      },
      {
        text: "Утас",
        align: "start",
        sortable: true,
        value: "phone",
      },
      {
        text: "Нийт оноо",
        align: "start",
        sortable: true,
        value: "totalScore",
      },
      {
        text: "Xувь",
        align: "start",
        sortable: true,
        value: "percent",
      },
    ],
    showStudentTimeDialog: false,
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
    sorilIdSource: {
      type: String,
      required: true,
    },
  },
  watch: {
    showStudentAnswerDialog(val) {
      if (val == false) {
        this.selectedStudent = null;
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    isEyesh() {
      return fb.EYESH_APP;
    },
    totalScore() {
      //return this.categories.reduce((pre, el) => pre + el.totalScore?el.totalScore:0, 0);
      var score = 0;
      if (this.categories) {
        for (var category of this.categories) {
          if (category.totalScore) {
            score = score + category.totalScore;
          }
        }
      }

      return Math.round(score);
    },
    results2() {
      if (this.results)
        return this.results.filter((student) => student.skipped != true);
      else return [];
    },
    filteredAnswerers() {
      if (this.results && this.userData.school) {
        if (this.onlyOurSchool) {
          var tmp = [];
          var counter = 0;

          for (var result of this.results) {
            if (result.answerer.ppschool == this.userData.ppschool) {
              result.index = ++counter;
              tmp.push(result);
            }
          }
          return tmp;
        } else return this.results;
      } else if (
        this.userData.role == "aspecialist" &&
        this.userData.schoolIndex &&
        this.results
      ) {
        var tmp2 = [];
        var counter2 = 0;
        for (var xx of this.results) {
          if (xx.answerer.schoolIndex == this.userData.schoolIndex) {
            xx.index = ++counter2;
            tmp2.push(xx);
          }
        }
        return tmp2;
      } else return this.results;
    },
  },
  created() {
    if (this.userData.school) {
      this.userData.school.ref.get().then((doc) => {
        this.school = doc.data();
        this.school.id = doc.id;
        this.school.ref = doc.ref;
      });
    }
    fb.db
      .collection("schools")
      .get()
      .then((docs) => {
        this.schools = [];
        var counter = 1;
        docs.forEach((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          school.index = counter;
          this.schools.push(school);
          counter++;
        });
      });
    fb.db.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
    });

    fb.db
      .doc(this.sorilIdSource)
      .collection("categories")
      .onSnapshot(async (docs) => {
        if (!docs.empty) {
          this.categories = [];
          docs.forEach((doc) => {
            let category = doc.data();
            category.ref = doc.ref;
            category.id = doc.id;
            category.questions = [];
            category.ref
              .collection("questions")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let question = doc.data();
                  question.ref = doc.ref;
                  question.id = doc.id;
                  category.questions.push(question);
                });
              });
            this.categories.push(category);
          });
          console.log(this.categories);

          for (var i = 0; i < this.categories.length; i++) {
            if (this.categories[i].totalScore) {
              var xx = i == 0 ? "Xэсэг-1" : "" + this.categories[i].id;
              this.headers.push({
                text: xx + " (" + this.categories[i].totalScore + ")",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            } else {
              this.headers.push({
                text:
                  i == 0
                    ? "xэсэг-"
                    : "" + this.categories[i].id + " (өгөгдөөгүй)",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            }
          }
        } else {
          // this.router.back()
          // alert("Шалгалтыг өгсөн сурагч байхгүй!")
          console.log("No such collection!");
        }
      });

    fb.db
      .doc(this.sorilId)
      .collection(this.resultCollectionName)
      .orderBy("totalScore", "desc")
      .onSnapshot(async (docs) => {
        this.results = [];
        if (!docs.empty) {
          var counter = 1;
          this.sumOfAverageTotalScores = 0;

          var batch = fb.db.batch();
          var batched = false;
          docs.forEach((doc) => {
            let result = doc.data();
            result.ref = doc.ref;
            result.id = doc.id;
            result.index = counter;
            result.percent =
              ((result.totalScore / this.totalScore) * 100).toFixed(0) + "%";

            if (result.deepAnalyzed == undefined) {
              batch.update(result.ref, { deepAnalyzed: false });
              batched = true;
            }
            result.checkedAgain = false;
            this.results.push(result);

            if (result.skipped == true) {
              // console.log("skipped", result.ref.path);
            } else {
              this.sumOfAverageTotalScores =
                this.sumOfAverageTotalScores + result.totalScore;
              if (this.maxTotalScore < result.totalScore)
                this.maxTotalScore = result.totalScore;
            }
            counter++;
            // }
          });
          if (batched) {
            batch.commit().then(() => {
              console.log("deep==false finished");
            });
          }
        } else {
          //this.$router.push({ name: "PageSoril Assignments" });
          // alert("Шалгалтыг өгсөн сурагч байхгүй!");
        }
      });
  },
  methods: {
    _downloadExcel() {
      var results = [];
      this.filteredAnswerers.forEach((result) => {
        results.push({
          Нэр: result.answerer.firstName,
          Овог: result.answerer.lastName,
          Анги: result.answerer[
            "departmentName-" + result.answerer.school.currentYear
          ],
          Бүлэг:
            result.answerer[
              "classGroupName-" + result.answerer.school.currentYear
            ],
          "Анги/Бүлэг":
            result.answerer[
              "departmentName-" + result.answerer.school.currentYear
            ] +
            result.answerer[
              "classGroupName-" + result.answerer.school.currentYear
            ].toUpperCase(),
          Эмайл: result.answerer.email,
          Сургууль: result.answerer.school.name,
          "Авсан Хувь": result.percent,
          "Авсан Оноо": result.totalScore,
          "Авах Оноо": this.totalScore,
          "Явуулсан хугацаа": new Date(
            this.filteredAnswerers[0].sendAt.toDate()
          ).toLocaleString(),
        });
      });

      const data = XLSX.utils.json_to_sheet(results);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, this.asuulga.name + ".xlsx");
    },
    resetTest(student) {
      var yes = confirm(student.answerer.firstName + " yстгах уу?");
      if (yes) {
        console.log(this.asuulga.ref, student.id);
        this.asuulga.ref
          .collection("results")
          .doc(student.id)
          .get()
          .then((doc) => {
            console.log(doc);
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("results-simplified")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("daalgavar-analyzed")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("starting-times")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
      }
    },
    redirectTime() {
      this.showStudentTimeDialog = !this.showStudentTimeDialog;
      // this.$router.push({
      //   name: "XShowAssignmentAllStudentsTime",
      //   params: {
      //     sorilId: this.asuulga.ref.path,
      //   },
      // });
    },
    _closeSoril() {
      console.log(this.asuulga.ref.path);
      this.asuulga.ref
        .collection("school-closed-results")
        .doc(this.userData.school.id)
        .set({
          averageScore: this._getAverageTotalScore()
            ? parseFloat(this._getAverageTotalScore())
            : null,
          maxTotalScore: this.maxTotalScore ? this.maxTotalScore : null,
          totalScore: this.totalScore ? this.totalScore : null,
          numberOfParticipants: this.filteredAnswerers.length
            ? this.filteredAnswerers.length
            : 0,
          createdAt: new Date(),
          createdByRef: this.userData.ref,
          createdByName: this.userData.firstName,
          school: this.school ? this.school : null,
        })
        .then(() => {});
    },
    _getCapitalizedName(name) {
      return name && name.length > 0
        ? name.charAt(0).toUpperCase() + name.slice(1)
        : null;
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            this._getCapitalizedName(
              item.lastName.substring(0, item.prefixName)
            ) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name =
            this._getCapitalizedName(item.lastName[0]) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      }
      return name;
    },

    _deleteStudentResult(result) {
      var yes = confirm("TODO: Устгаx уу?");
      if (yes) {
        result.ref.parent.parent
          .collection("results")
          .doc(result.id)
          .delete()
          .then(() => {
            result.ref.delete().then(() => {
              console.log("DONE!");
            });
          });
      }
    },
    _publicLink() {
      this.$router.push({
        name: "StudentShowAssignmentAllResults2",
        params: { sorilId: this.asuulga.ref.path },
      });

      //  this.$router.push({
      //   name: "PublicStudentsScore",
      //   params: {
      //     sorilId: this.asuulga.id,
      //   },
      // });
    },
    _saveAllDeepAnalysis() {
      console.log(this.results.length);
      var counter = 0;
      for (var result of this.results) {
        if (result.deepAnalyzed == false) {
          if (!result.skipped) {
            this._deepanalyze(result);
            counter++;
            console.log(counter);
          }
        }
      }
      console.log(counter);
    },
    async _deepanalyze(item) {
      console.log(item.ref.path);
      console.log(item);
      var batch;
      batch = fb.db.batch();
      var result = await this.asuulga.ref
        .collection("results")
        .doc(item.ref.id)
        .get();

      var categories = result.data().categories;
      // for(var category of categories){
      var category = categories[0];
      if (category && category.catScored) {
        for (var question of category.questions) {
          for (var answer of question.answers) {
            if (answer.answered) {
              //yyy.push({index:counter,name:answer.name, answered:true})
              var x = {
                questionIndex: question.index,
                answerName: answer.name,
                answered: true,
              };
              var yy = {};
              yy[x.answerName] = fb.firestore.FieldValue.increment(1);
              yy.questionIndex = question.index;
              if (answer.correct) yy.correctAnswer = answer.name;

              var refRef = this.asuulga.ref
                .collection("daalgavar-analyzed")
                .doc(question.index.toString());

              batch.set(refRef, yy, { merge: true });
            } else {
              //yyy.push({index:counter,name:answer.name, answered:false})
            }
          }
        }
        batch.commit().then(() => {
          console.log("donexxx");
          item.ref.update({ deepAnalyzed: true }).then(() => {
            console.log("ok", item.ref.path);
          });
          if (
            this.asuulga.deepAnalyzed == undefined ||
            this.asuulga.deepAnalyzed == false
          )
            this.asuulga.ref.update({ deepAnalyzed: true });
        });
      }
    },
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results2) {
        for (var i = 0; i < this.results2.length; i++) {
          var student = this.results2[i];
          if (school.name == student.schoolName) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },
    _download() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        var tHeader = [];
        for (var head of this.headers) {
          tHeader.push(head.text);
        }
        var data = [];
        for (const result of this.results) {
          if (!result.skipped) {
            var row = [];
            row.push(result.index);
            row.push(result.answerer.firstName);
            row.push(result.schoolName);
            row.push(result.totalScore);
            row.push(result.percent);

            for (var cat of result.categories) {
              row.push(cat.catScored);
            }
            data.push(row);
          }
        }

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "all_results_" + this.asuulga.name,
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    _delete(student) {
      var x = "";
      if (student.skipped)
        x =
          student.answerer.firstName + ": тооцоололд оруулаxыг xүсэж байна уу?";
      else
        x =
          student.answerer.firstName + ": тооцооллоос xасаxыг xүсэж байна уу?";

      var yes = confirm(x);
      if (yes) {
        if (!student.skipped) student.ref.update({ skipped: true });
        else student.ref.update({ skipped: !student.skipped });
      }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },
    async retestAllStudents() {
      var yes = alert("Шалгалтын тооцоог даxин xийx үү?");

      if (yes) {
        var counter = 0;
        const timer = (ms) => new Promise((res) => setTimeout(res, ms));

        for (var student of this.filteredAnswerers) {
          this._sendAsuulga(student);
          counter++;
          console.log(student.answerer.firstName, counter);
          await timer(100);
        }
      }
      console.log("DONEEEE");
    },
    _sendAsuulga(student) {
      if (this.fromEYESH) {
        fb.dbeyesh800
          .doc(student.ref.path.replace("results-simplified", "results"))
          .get()
          .then((doc) => {
            var cat = doc.data().categories;

            var scored = 0;
            for (
              var catIndex = 0;
              catIndex < this.categories.length;
              catIndex++
            ) {
              if (
                this.categories[catIndex].questions &&
                this.categories[catIndex].questions.length > 0
              ) {
                cat[catIndex].catScored = 0;

                if (this.categories[catIndex].groupsEnabled) {
                  console.log("asdasd");
                  var failedGroups = [];

                  for (
                    var questionIndex = 0;
                    questionIndex < this.categories[catIndex].questions.length;
                    questionIndex++
                  ) {
                    for (
                      var answerIndex = 0;
                      answerIndex <
                      this.categories[catIndex].questions[questionIndex].answers
                        .length;
                      answerIndex++
                    ) {
                      if (
                        this.categories[catIndex].questions[questionIndex]
                          .answers[answerIndex].correct &&
                        cat[catIndex].questions[questionIndex].answers[
                          answerIndex
                        ].answered == false
                      ) {
                        failedGroups.push(
                          this.categories[catIndex].questions[questionIndex]
                            .assignedGroup
                        );
                      }
                    }
                  }

                  for (var group of this.categories[catIndex].availableGroups) {
                    var failed = failedGroups.find(
                      (failedgroup) => failedgroup == group.name
                    );
                    if (this.categories[catIndex].groupsScorePerQuestion) {
                      if (failed) {
                        var failedQuestions = this.categories[
                          catIndex
                        ].questions.filter(
                          (question) => question.assignedGroup == failed
                        );
                        var correctCounter = 0;

                        for (
                          var qquestionIndex = 0;
                          qquestionIndex <
                          this.categories[catIndex].questions.length;
                          qquestionIndex++
                        ) {
                          for (
                            var aanswerIndex = 0;
                            aanswerIndex <
                            this.categories[catIndex].questions[qquestionIndex]
                              .answers.length;
                            aanswerIndex++
                          ) {
                            if (
                              this.categories[catIndex].questions[
                                qquestionIndex
                              ].assignedGroup == failed
                            ) {
                              if (
                                cat[catIndex].questions[qquestionIndex].answers[
                                  aanswerIndex
                                ].answered &&
                                this.categories[catIndex].questions[
                                  qquestionIndex
                                ].answers[aanswerIndex].correct
                              ) {
                                correctCounter++;
                              }
                            }
                          }
                        }
                        if (correctCounter > 0) {
                          cat[catIndex].catScored =
                            cat[catIndex].catScored +
                            group.score *
                              (correctCounter / failedQuestions.length);
                        }
                      } else {
                        cat[catIndex].catScored =
                          cat[catIndex].catScored + group.score;
                      }
                    } else if (failed == undefined) {
                      cat[catIndex].catScored =
                        cat[catIndex].catScored + group.score;
                    }
                  }
                } else if (
                  this.categories[catIndex].categoryScoreRequired &&
                  this.categories[catIndex].givingScorePerQuestion == false
                ) {
                  var x1 = this._listToString(this.categories[catIndex].keyss);
                  var x2 = this._listToString(cat[catIndex].studentKeyss);

                  if (x1 == x2) {
                    cat[catIndex].catScored = cat[catIndex].totalScore;
                  }
                } else {
                  if (this.categories[catIndex].questions) {
                    for (
                      var qqqquestionIndex = 0;
                      qqqquestionIndex <
                      this.categories[catIndex].questions.length;
                      qqqquestionIndex++
                    ) {
                      cat[catIndex].questions[
                        qqqquestionIndex
                      ].questionScored = 0;
                      if (
                        cat[catIndex].questions[qqqquestionIndex] &&
                        cat[catIndex].questions[qqqquestionIndex].answers
                      ) {
                        for (
                          var aaanswerIndex = 0;
                          aaanswerIndex <
                          cat[catIndex].questions[qqqquestionIndex].answers
                            .length;
                          aaanswerIndex++
                        ) {
                          if (
                            cat[catIndex].questions[qqqquestionIndex].answers[
                              aaanswerIndex
                            ].answered &&
                            this.categories[catIndex].questions[
                              qqqquestionIndex
                            ].answers[aaanswerIndex].correct
                          ) {
                            cat[catIndex].questions[
                              qqqquestionIndex
                            ].questionScored =
                              cat[catIndex].questions[qqqquestionIndex]
                                .questionScored +
                              this.categories[catIndex].questions[
                                qqqquestionIndex
                              ].score;
                          }
                        }
                      }
                      cat[catIndex].catScored =
                        cat[catIndex].catScored +
                        cat[catIndex].questions[qqqquestionIndex]
                          .questionScored;
                    }
                  }
                }

                scored = scored + cat[catIndex].catScored;

                var sendData = {
                  shalgaltId: this.asuulga.id,
                  shalgaltRef: this.asuulga.ref,
                  answererRef: null,
                  answererId: student.answerer.email,
                  categories: cat,
                  sendAt: fb.firestore.FieldValue.serverTimestamp(),
                  answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                  totalScore: scored,
                  answerer: student.answerer,
                  deepdataanalyzed: false,
                };
                var sendDataSimplified = {
                  shalgaltId: this.asuulga.id,
                  shalgaltRef: this.asuulga.ref,
                  answererRef: null,
                  answererId: student.answerer.email,
                  categories: this._getSimplified(this.categories, cat),
                  sendAt: fb.firestore.FieldValue.serverTimestamp(),
                  answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                  totalScore: scored,
                  answerer: student.answerer,
                  deepdataanalyzed: false,
                };

                if (student.schoolName && student.schoolRef) {
                  sendData.schoolName = student.schoolName;
                  sendData.schoolRef = student.schoolRef;
                  sendDataSimplified.schoolName = student.schoolName;
                  sendDataSimplified.schoolRef = student.schoolRef;
                } else {
                  sendData.schoolName = null;
                  sendData.schoolRef = null;
                  sendDataSimplified.schoolName = null;
                  sendDataSimplified.schoolRef = null;
                }

                this.asuulga.ref
                  .collection("results-simplified")
                  .doc(student.answerer.id)
                  .set(sendDataSimplified, { merge: true })
                  .then(() => {
                    this.asuulga.ref
                      .collection("results")
                      .doc(student.answerer.id)
                      .set(sendData, { merge: true })
                      .then(() => {
                        console.log("success", student.answerer.ref.path);
                        if (this.isTesting == false)
                          this._deepanalyze(sendData);
                        this.sent = true;
                      })
                      .catch(function () {});

                    this.asuulga.ref
                      .collection("starting-times")
                      .doc(student.answerer.id)
                      .delete();
                  });
              }
            }
            student.checkedAgain = true;
          });
      } else {
        fb.db
          .doc(student.ref.path.replace("results-simplified", "results"))
          .get()
          .then((doc) => {
            var cat = doc.data().categories;

            var scored = 0;
            for (
              var catIndex = 0;
              catIndex < this.categories.length;
              catIndex++
            ) {
              if (
                this.categories[catIndex].questions &&
                this.categories[catIndex].questions.length > 0
              ) {
                cat[catIndex].catScored = 0;

                if (this.categories[catIndex].groupsEnabled) {
                  console.log("asdasd");
                  var failedGroups = [];

                  for (
                    var questionIndex = 0;
                    questionIndex < this.categories[catIndex].questions.length;
                    questionIndex++
                  ) {
                    for (
                      var answerIndex = 0;
                      answerIndex <
                      this.categories[catIndex].questions[questionIndex].answers
                        .length;
                      answerIndex++
                    ) {
                      if (
                        this.categories[catIndex].questions[questionIndex]
                          .answers[answerIndex].correct &&
                        cat[catIndex].questions[questionIndex].answers[
                          answerIndex
                        ].answered == false
                      ) {
                        failedGroups.push(
                          this.categories[catIndex].questions[questionIndex]
                            .assignedGroup
                        );
                      }
                    }
                  }

                  for (var group of this.categories[catIndex].availableGroups) {
                    var failed = failedGroups.find(
                      (failedgroup) => failedgroup == group.name
                    );
                    if (this.categories[catIndex].groupsScorePerQuestion) {
                      if (failed) {
                        var failedQuestions = this.categories[
                          catIndex
                        ].questions.filter(
                          (question) => question.assignedGroup == failed
                        );
                        var correctCounter = 0;

                        for (
                          var qquestionIndex = 0;
                          qquestionIndex <
                          this.categories[catIndex].questions.length;
                          qquestionIndex++
                        ) {
                          for (
                            var aanswerIndex = 0;
                            aanswerIndex <
                            this.categories[catIndex].questions[qquestionIndex]
                              .answers.length;
                            aanswerIndex++
                          ) {
                            if (
                              this.categories[catIndex].questions[
                                qquestionIndex
                              ].assignedGroup == failed
                            ) {
                              if (
                                cat[catIndex].questions[qquestionIndex].answers[
                                  aanswerIndex
                                ].answered &&
                                this.categories[catIndex].questions[
                                  qquestionIndex
                                ].answers[aanswerIndex].correct
                              ) {
                                correctCounter++;
                              }
                            }
                          }
                        }
                        if (correctCounter > 0) {
                          cat[catIndex].catScored =
                            cat[catIndex].catScored +
                            group.score *
                              (correctCounter / failedQuestions.length);
                        }
                      } else {
                        cat[catIndex].catScored =
                          cat[catIndex].catScored + group.score;
                      }
                    } else if (failed == undefined) {
                      cat[catIndex].catScored =
                        cat[catIndex].catScored + group.score;
                    }
                  }
                } else if (
                  this.categories[catIndex].categoryScoreRequired &&
                  this.categories[catIndex].givingScorePerQuestion == false
                ) {
                  var x1 = this._listToString(this.categories[catIndex].keyss);
                  var x2 = this._listToString(cat[catIndex].studentKeyss);

                  if (x1 == x2) {
                    cat[catIndex].catScored = cat[catIndex].totalScore;
                  }
                } else {
                  if (this.categories[catIndex].questions) {
                    for (
                      var qqqquestionIndex = 0;
                      qqqquestionIndex <
                      this.categories[catIndex].questions.length;
                      qqqquestionIndex++
                    ) {
                      cat[catIndex].questions[
                        qqqquestionIndex
                      ].questionScored = 0;
                      if (
                        cat[catIndex].questions[qqqquestionIndex] &&
                        cat[catIndex].questions[qqqquestionIndex].answers
                      ) {
                        for (
                          var aaanswerIndex = 0;
                          aaanswerIndex <
                          cat[catIndex].questions[qqqquestionIndex].answers
                            .length;
                          aaanswerIndex++
                        ) {
                          if (
                            cat[catIndex].questions[qqqquestionIndex].answers[
                              aaanswerIndex
                            ].answered &&
                            this.categories[catIndex].questions[
                              qqqquestionIndex
                            ].answers[aaanswerIndex].correct
                          ) {
                            cat[catIndex].questions[
                              qqqquestionIndex
                            ].questionScored =
                              cat[catIndex].questions[qqqquestionIndex]
                                .questionScored +
                              this.categories[catIndex].questions[
                                qqqquestionIndex
                              ].score;
                          }
                        }
                      }
                      cat[catIndex].catScored =
                        cat[catIndex].catScored +
                        cat[catIndex].questions[qqqquestionIndex]
                          .questionScored;
                    }
                  }
                }

                scored = scored + cat[catIndex].catScored;
                console.log(scored);

                var sendData = {
                  shalgaltId: this.asuulga.id,
                  shalgaltRef: this.asuulga.ref,
                  answererRef: null,
                  answererId: student.answerer.email,
                  categories: cat,
                  sendAt: fb.firestore.FieldValue.serverTimestamp(),
                  answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                  totalScore: scored,
                  answerer: student.answerer,
                  deepdataanalyzed: false,
                };
                var sendDataSimplified = {
                  shalgaltId: this.asuulga.id,
                  shalgaltRef: this.asuulga.ref,
                  answererRef: null,
                  answererId: student.answerer.email,
                  categories: this._getSimplified(this.categories, cat),
                  sendAt: fb.firestore.FieldValue.serverTimestamp(),
                  answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                  totalScore: scored,
                  answerer: student.answerer,
                  deepdataanalyzed: false,
                };

                if (student.schoolName && student.schoolRef) {
                  sendData.schoolName = student.schoolName;
                  sendData.schoolRef = student.schoolRef;
                  sendDataSimplified.schoolName = student.schoolName;
                  sendDataSimplified.schoolRef = student.schoolRef;
                } else {
                  sendData.schoolName = null;
                  sendData.schoolRef = null;
                  sendDataSimplified.schoolName = null;
                  sendDataSimplified.schoolRef = null;
                }

                this.asuulga.ref
                  .collection("results-simplified")
                  .doc(student.answerer.id)
                  .set(sendDataSimplified, { merge: true })
                  .then(() => {
                    this.asuulga.ref
                      .collection("results")
                      .doc(student.answerer.id)
                      .set(sendData, { merge: true })
                      .then(() => {
                        console.log("success", student.answerer.ref.path);
                        if (this.isTesting == false)
                          this._deepanalyze(sendData);
                        this.sent = true;
                      })
                      .catch(function () {});

                    this.asuulga.ref
                      .collection("starting-times")
                      .doc(student.answerer.id)
                      .delete();
                  });
              }
            }
            student.checkedAgain = true;
          });
      }
    },
    _getSimplified(categories, studCategories) {
      var xxCategories = [];
      for (var catIndex = 0; catIndex < categories.length; catIndex++) {
        var categoryTMP = {
          catScored: studCategories[catIndex].catScored
            ? studCategories[catIndex].catScored
            : 0,
          totalScore: categories[catIndex].totalScore
            ? categories[catIndex].totalScore
            : 0,
        };
        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },

    async _analyzeScores() {
      if (this.categories[0]) {
        var x = await this.categories[0].ref.collection("questions").get();
        if (!x.empty && x.size > 0) {
          this.$router.push({
            name: "AllQuestionsAnalysis",
            params: {
              asuulga: this.asuulga,
              numberOfQuestions: x.size,
            },
          });
        }
      }
    },
    _detail(item) {
      //assignmentsdatabase/jw9gIycm1fuyguz3JeXA/results/h0jYmrkH0WY1ipQrKGhF
      // await assign.ref
      //.collection("results")
      //.doc(this.userData.id)

      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: this.asuulga.ref.path + "/results/" + item.id,
          showSlide: false,
          fromEYESH: this.fromEYESH,
        },
      });
    },
    _getSchoolName(schoolPath) {
      var x = null;
      if (this.schools) {
        for (var school of this.schools) {
          if (school.ref.path == schoolPath) {
            x = school.name;
            break;
          }
        }
      }

      return x;
    },
    _getAverageTotalScore() {
      if (this.results2)
        return (this.sumOfAverageTotalScores / this.results2.length).toFixed(2);
      else return 0;
    },
    _redirectResultsDetail(sorilId, tabIndex) {
      this.$router.push({
        name: "StudentShowAssignmentAllResultsDetail",
        params: {
          sorilId: sorilId,
          tabIndex: tabIndex,
        },
      });
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
