<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Cорилууд</h5>
        <p class="text-body">
          Сорилын болон даалгаврын санг энд зоxион байгуулна.
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил+</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="start" v-if="ownAssignments && ownAssignments.length > 0">
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in ownAssignments"
        :key="'mysoriluud' + assignment.id"
      >
        <AssignmentCard
          :assignment="assignment"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
          :fromEYESH="false"
        ></AssignmentCard>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор та сорил үүсгээгүй байна!
      </h5>
    </v-row>
    <div v-if="!lesson">
      <h3 class="text-typo my-4 font-weight-bold mt-10">
        Сургуулийн сорилын сан
      </h3>
      <p class="text-body">Сургуулийн бусад багш нарын үүсгэсэн сорилын сан</p>
      <v-row
        justify="start"
        v-if="schoolAssignments && schoolAssignments.length > 0"
      >
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in schoolAssignments"
          :key="'soriluud2' + assignment.id"
        >
          <AssignmentCard
            :assignment="assignment"
            @click="_detail(assignment)"
            :assignmentsTypes="assignmentsTypes"
            v-bind="$attrs"
            :lessonCategories="lessonCategories"
            :fromEYESH="false"
          ></AssignmentCard>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <h5 class="text-center py-16 red--text">
          Одоогоор сорил байxгүй байна.
        </h5>
      </v-row>
    </div>
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ сорил/шалгалт үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n3 ml-n1">
              <v-radio-group disabled v-model="editedItem.assignmentType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  :items="[
                    { name: 'Xичээлийн xүрээнд', sorilIndex: 3 },
                    { name: 'Сургуулийн xүрээнд', sorilIndex: 2 },
                    { name: 'Тусгай сорил', sorilIndex: 1 },
                  ]"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="sorilIndex"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="6" sm="6" md="6" class="py-2">
                <small
                  class="red--text"
                  v-if="!editedItem.selectedLessonCategory"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12" class="pa-0 mt-n4 ml-3">
                <v-switch
                  color="red"
                  v-model="editedItem.forAllSchool"
                  :label="
                    editedItem.forAllSchool
                      ? 'Бүх сургуульд зориулсан'
                      : 'Өөрийн сургуульд зориулсан'
                  "
                ></v-switch>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  height="60"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCard from "./AssignmentCard.vue";

export default {
  components: { AssignmentCard },
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    allAssignments: null,
    schoolAssignments: null,
    ownAssignments: null,

    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 2, name: "Түлxүүр үгтэй шалгалт" },

      // { value: 1, name: "Стандарт" },
    ],
    lessonCategories: null,
    lessonCategories2: null,
  }),
  props: {
    lesson: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
    },

    otherAssignments() {
      if (this.assignments)
        return this.assignments.filter(
          (el) => el.createdByEmail != (this.userData && this.userData.email)
        );
      else return null;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.selectedLessonCategory &&
        this.editedItem.examScope
      ) {
        console.log("saving...");
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.finalScore = 0;
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        console.log(
          this.editedItem.schoolIndex,
          this.userData.school.schoolIndex
        );
        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            console.log(this.zzschool);
            console.log(this.editedItem);
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              if (this.editedItem.forAllSchool == undefined)
                this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }
          this.editedItem.assignmentType = this.assignmentsTypes[0].value;
          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          this.editedItem.schoolIndex = this.userData.school.schoolIndex
            ? this.userData.school.schoolIndex
            : null;
          if (this.userData.school) {
            this.userData.school.ref
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
                location.reload()
                console.log(this.editedItem);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      var counter = 0;
      fb.db
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 =
              ++counter + ". " + item.name + " - " + item.lessonTypeName;
            this.lessonCategories.push(item);
          });
        });

      if (this.userData && this.userData.school) {
        this.userData.school.ref
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories2 = [];
            docs.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 =
                ++counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories2.push(item);
            });
          });
      }

      var query = null;
      if (
        this.userData.role == "superadmin" ||
        this.userData.role == "aspecialist" ||
        this.userData.role == "eyeshadmin"
      ) {
        if (this.userData.school)
          query = fb.db
            .collection(this.assignmentsDatabasePath)
            .where("createdBySchoolRef", "==", this.userData.school.ref)
            .where("createdByEmail", "!=", "granditxxk@gmail.com")
            .where("deleted", "==", false);
        // if (this.userData.schoolIndex) {
        //   query = fb.db
        //     .collection(this.assignmentsDatabasePath)
        //     .where("schoolIndex", "==", this.userData.schoolIndex)
        //     .where("deleted", "==", false)
        //     .orderBy("createdAt", "desc");
        // } else
        // else
        //   query = fb.db
        //     .collection(this.assignmentsDatabasePath)
        //     .where("deleted", "==", false)
        //     .where("createdByEmail", "!=", "granditxxk@gmail.com");
        if (query)
          query.onSnapshot((docs) => {
   
            this.schoolAssignments = [];
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;
              console.log(assign.ref.path);
              this.schoolAssignments.push(assign);
              console.log(this.schoolAssignments);
            });
            // if (this.userData.school) {

            // }
          });
        if (this.userData.schoolIndex) {
          var query3 = fb.db
            .collectionGroup(this.assignmentsDatabasePath)
            .where("examScope.sorilIndex", "==", 1)
            .where("schoolIndex", "==", this.userData.schoolIndex)
            .where("deleted", "==", false);

          if (this.lesson) {
            query3 = query3
              .where("categoryRef", "==", this.lesson.categoryRef)
              .orderBy("createdAt", "asc");
            console.log(this.lesson);
          } else {
            query3 = query3.orderBy("createdAt", "asc");
          }
        }
        // } else {
        //   var query2 = fb.db
        //     .collection(this.assignmentsDatabasePath)
        //     .where("deleted", "==", false)
        //     .where("createdByEmail", "==", this.userData.email)
        //     .orderBy("createdAt", "desc");
        // }

        // query2.onSnapshot((docs) => {
        //   console.log(docs);
        //   this.ownAssignments = [];
        //   docs.forEach((doc) => {
        //     let assign = doc.data();
        //     assign.id = doc.id;
        //     assign.ref = doc.ref;
        //     this.ownAssignments.push(assign);
        //   });
        //   if (this.userData.school) {
        //     this.userData.school.ref
        //       .collection(this.assignmentsDatabasePath)
        //       .where("deleted", "==", false)
        //       .where("createdByEmail", "==", this.userData.email)
        //       .orderBy("createdAt", "desc")
        //       .onSnapshot((docs) => {
        //         docs.forEach((doc) => {
        //           let assign = doc.data();
        //           assign.id = doc.id;
        //           assign.ref = doc.ref;
        //           this.ownAssignments.push(assign);
        //         });
        //       });
        //   }

        // });
        if (query3) {
          query3.onSnapshot((docs) => {
 
            this.ownAssignments = [];
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;

              this.ownAssignments.push(assign);
            });
          });
        }
      } else if (
        this.userData.role == "schooladmin" ||
        this.userData.role == "manager"
      ) {
        query = fb.db
          .collection(this.assignmentsDatabasePath)
          .where("createdBySchoolRef", "==", this.userData.school.ref)
          .where("createdByEmail", "!=", "granditxxk@gmail.com")
          .where("deleted", "==", false);

        query.onSnapshot((docs) => {
          this.schoolAssignments = [];

          docs.forEach((doc) => {
            let assign = doc.data();
            assign.id = doc.id;
            assign.ref = doc.ref;
            this.schoolAssignments.push(assign);
          });
          this.userData.school.ref
            .collection(this.assignmentsDatabasePath)
            .where("deleted", "==", false)
            .onSnapshot((docs) => {

              docs.forEach((doc) => {
                let assign = doc.data();
                assign.id = doc.id;
                assign.ref = doc.ref;
                this.schoolAssignments.push(assign);
              });
            });
        });
      }
    },
  },
};
</script>
