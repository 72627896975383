var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:_vm.selectingSubtopic ? 'pa-0' : 'pa-10'},[_c('section',[_c('v-card',{attrs:{"min-height":"600"}},[_c('section',{staticClass:"pa-2"},[_c('v-row',{staticClass:"ma-2",staticStyle:{"align-items":"center"}},[_c('h4',[_vm._v("Нэгж сэдвүүд")]),_c('v-spacer'),_c('h5',{staticClass:"primary--text"},[_vm._v("Нийт цаг: "+_vm._s(_vm.timesOfCurrentTopics))])],1),_c('hr'),(_vm.topics != undefined && _vm.topics != null)?_vm._l((_vm.topics),function(topic,tindex){return _c('v-container',{key:tindex},[_c('v-container',{staticClass:"mt-4",staticStyle:{"background":"#f2f2f2"}},[_c('v-row',{staticStyle:{"align-items":"center"},attrs:{"justify":"space-between"}},[_c('v-col',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(tindex + 1)+". "+_vm._s(topic.name)+" ")]),_c('span',[_vm._v("(ээлжит сэдэв: "),_c('span',[_c('strong',[_vm._v(_vm._s(topic.topicCount))])]),(
                        topic.duration != null && topic.duration != undefined
                      )?_c('span'):_vm._e(),_vm._v(", ээлжит зааx цаг: "),_c('strong',[_vm._v(_vm._s(topic.duration))]),_vm._v("/"),_c('strong',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm._getSumOfSubTopicDurations(topic)))]),_vm._v(")")])])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"two-line":""}},[(
                  topic.subtopics != undefined &&
                  topic.subtopics != null &&
                  topic.subtopics.length > 0
                )?_vm._l((topic.subtopics),function(item,index){return _c('v-card',{key:index,staticClass:"my-6",attrs:{"flat":""}},[_c('v-layout',{staticClass:"pa-2 item",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('div',{staticClass:"caption grey--text ml-3"},[_vm._v("Сэдвийн нэр")]),(item.editMode == false)?_c('div',{staticClass:"px-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(index + 1))]),_vm._v(". "+_vm._s(item.name)+" ")]):_c('div',{staticClass:"px-2"},[_c('v-text-field',{staticClass:"form-control input-sm",on:{"change":function($event){return _vm._changeItem(item, 'name', false)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})],1)]),(_vm.selectingSubtopic == false)?_c('v-flex',{attrs:{"xs1":"","sm1":"","md1":""}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Зааx цаг")]),(item.editMode == false)?_c('div',{class:{
                          'mt-0 mx-0 px-0': _vm.$vuetify.breakpoint.smAndDown,
                        }},[_vm._v(" "+_vm._s(item.duration)+" ")]):_c('div',[_c('v-text-field',{staticClass:"form-control input-sm",attrs:{"type":"number","min":"0"},on:{"change":function($event){return _vm._changeItem(item, 'duration', false)}},model:{value:(item.duration),callback:function ($$v) {_vm.$set(item, "duration", _vm._n($$v))},expression:"item.duration"}})],1)]):_vm._e(),(
                        item.editMode == false && _vm.selectingSubtopic == false
                      )?_c('v-flex',{staticClass:"pt-0",attrs:{"xs6":"","sm6":"","md3":""}},[_c('div',{staticClass:"mt-0 pt-0"},[_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"caption grey--text ml-3"},[_vm._v("Тайлбар")]),(
                              item.description != undefined &&
                              item.description != null
                            )?_c('span',[_vm._v(_vm._s(item.description.substring(0, 100)))]):_vm._e()])])]):(_vm.selectingSubtopic == false)?_c('v-flex',{staticClass:"pt-0",attrs:{"xs12":"","sm12":"","md6":""}},[_c('div',{staticClass:"mt-0 pt-0"},[_c('div',{staticClass:"px-2"},[_c('v-textarea',{attrs:{"filled":"","auto-grow":"","rows":"4","row-height":"30","label":"Тайлбараа бичнэ үү."},on:{"change":function($event){return _vm._changeItem(item, 'description', false)}},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.description"}})],1)])]):_vm._e(),(_vm.selectingSubtopic)?_c('v-spacer'):_vm._e(),(_vm.selectingSubtopic)?_c('div',[(
                          _vm.selectedSubtopic &&
                          _vm.selectedSubtopic.ref.path == item.ref.path
                        )?_c('v-btn',{staticClass:"bg-gradient-success white--text mr-2",on:{"click":function($event){return _vm.sendSubtopicToParent(item)}}},[_vm._v("Сонгосон")]):_c('v-btn',{staticClass:"mr-2",attrs:{"color":"rgb(218, 218, 218)"},on:{"click":function($event){return _vm.sendSubtopicToParent(item)}}},[_vm._v("Сонгох")])],1):_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('small',{staticClass:"border-radius-sm text-xxs text-white shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning",staticStyle:{"cursor":"pointer"},attrs:{"small":"","elevation":"0","ripple":false},on:{"click":function($event){_vm.addNewItem = true;
                            _vm.editedItem = item;}}},[_vm._v("video/slide")]),_c('small',{staticClass:"ml-1 border-radius-sm text-xxs shadow-none font-weight-bold px-3 py-1 bg-gradient-info",staticStyle:{"cursor":"pointer","background":"#eef4fa","color":"white"},attrs:{"small":"","elevation":"0","ripple":false},on:{"click":function($event){_vm.selectedItem = item;
                            _vm.previewDialog = true;}}},[_vm._v("preview")])]),_c('div',{staticClass:"d-flex flex-row mt-2"},_vm._l((item.teachedGroups),function(teachedGroup,tindex){return _c('v-btn',{key:'teachedGroup' + tindex,staticClass:"border-radius-sm text-xxs shadow-none font-weight-bold pa-1 mr-1 btn-danger bg-gradient-danger",attrs:{"dark":"","elevation":"0","height":22,"min-width":0}},[_vm._v(_vm._s(teachedGroup.name2))])}),1)])],1),_c('v-divider')],1)}):_vm._e()],2)],1)}):_vm._e()],2)]),(_vm.editedItem)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.addNewItem),callback:function ($$v) {_vm.addNewItem=$$v},expression:"addNewItem"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('v-card-text',{staticClass:"card-padding"},[_c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":"Youtube линк"},model:{value:(_vm.editedItem.videoUrl),callback:function ($$v) {_vm.$set(_vm.editedItem, "videoUrl", $$v)},expression:"editedItem.videoUrl"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":"Слайд линк"},model:{value:(_vm.editedItem.slideUrl),callback:function ($$v) {_vm.$set(_vm.editedItem, "slideUrl", $$v)},expression:"editedItem.slideUrl"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-between mt-n10"},[_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){_vm.addNewItem = false;
              _vm.editedItem = null;}}},[_vm._v("Цуцлаx")]),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm._save}},[_vm._v("Xадгалаx")])],1)],1)],1):_vm._e(),(_vm.selectedItem)?_c('v-dialog',{model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl d-flex flex-row"},[(_vm.selectedItem.slideUrl)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getSlideIframe(_vm.selectedItem.slideUrl))}})]):_vm._e(),(_vm.selectedItem.videoUrl)?_c('v-col',{staticClass:"pl-2",attrs:{"cols":"6"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getYoutubeIframe(_vm.selectedItem.videoUrl))}})]):_vm._e()],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }