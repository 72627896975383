<template>
  <v-card
    :color="schoolScoreData ? '#C8E6C9' : ''"
    class="card-shadow border-radius-xl"
    @click="_detail(assignment)"
    :ripple="false"
  >
    <div class="px-4 pt-4">
      <v-row>
        <v-col md="10" lg="10" cols="10">
          <h6 class="text-h6 text-typo font-weight-bold">
            {{ assignment.name }}
          </h6>
          <p v-if="userData.role == 'superadmin' && assignment.categoryRef">
            {{ assignment.categoryRef.path }}
          </p>
          <span v-if="assignment.selectedLessonCategory">{{
            assignment.selectedLessonCategory.name
          }}</span>
          <span v-if="assignment.assignmentType == 2" class="font-weight-bold"
            >, ЭЕШ-сорил</span
          >
          <span v-else style="color: red">, Стандарт сорил </span>
          <p v-if="assignment.examScope" class="red--text">
            {{ assignment.examScope.name }}
          </p>
          <small class="blue--text" v-if="assignment.createdYear">
            {{ assignment.createdYear }}/{{ assignment.createdMonth }}</small
          >
          <br />
          <small  @click.stop="_makeAssignmentPublic(assignment)"
            small
            class="border-radius-sm text-white me-1 shadow-none px-3 py-1"
            :class="
              assignment.isPublicForSchool
                ? 'bg-gradient-success'
                : 'bg-gradient-info'
            "
            elevation="0"
            :ripple="false"
          >
            <span v-if="assignment.isPublicForSchool">Нээлттэй</span>
            <span v-else> Xаалттай</span>
          </small>
          <br />
          <hr class="horizontal dark my-3" />
        </v-col>
        <v-col md="2" lg="2" cols="2" class="text-end">
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                class="text-secondary ms-auto mt-3"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="16">fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item
                class="list-item-hover-active"
                @click.stop="_makeAssignmentPublic(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title class="red--text font-weight-bold">
                    <span v-if="assignment.isPublicForSchool">Xаалттай болгоx</span>
                    <span v-else>Нээлттэй болгоx</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="list-item-hover-active"
                @click.stop="_detail(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title> Зөв xариулт xараx </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="list-item-hover-active"
                @click.stop="_shareLink(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    Туршиx
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <p class="text-sm mt-4 text-body">{{ assignment.description }}</p>
    </div>
    <v-card-actions>
      <v-row justify="space-between" class="px-2">
        <v-col cols="auto">
          <small
            @click.stop="openSorilResults = !openSorilResults"
            small
            class="border-radius-sm text-white me-1 shadow-none px-3 py-1 btn-primary bg-gradient-danger"
            elevation="0"
            :ripple="false"
            >Үр дүн</small
          >
        </v-col>
        <v-col cols="auto" class="text-end">
          <v-btn
            @click="_detail(assignment)"
            small
            color="#ececec"
            class="border-radius-sm me-1 shadow-none px-3 py-1 text-capitalize"
            elevation="0"
            :ripple="false"
            >Материал xараx</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions class="d-flex flex-row justify-end px-4 pb-4">
      <h4 class="mr-1">{{ assignment.duration }}</h4>
      <span v-if="assignment.duration">минут</span>
      <span v-else class="text-alert font-weight-bold" style="color: red"
        >Xугацаа оруулаагүй</span
      >
      <v-spacer></v-spacer>
      <h4 class="mr-1" v-if="assignment.finalScore" style="color: red">
        {{ assignment.finalScore }}
      </h4>
      <span v-if="assignment.finalScore">оноо</span>
      <span v-else class="text-alert font-weight-bold" style="color: red"
        >Оноо оруулаагүй</span
      >
      <!-- <v-btn
        v-if="assignment.finished"
        class="
          ml-4
          border-radius-sm
          text-xxs text-white
          shadow-none
          font-weight-bold
          px-3
          py-1
          btn-warning
          bg-gradient-warning
        "
        elevation="0"
        @click.stop="_redirectResults(assignment)"
        :ripple="false"
        >Дүн харах</v-btn
      > -->
    </v-card-actions>
    <v-dialog
      v-model="deleteDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-toolbar flat>
          <v-toolbar-title class="font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="editedItem.name" class="pa-4 black--text">{{
          editedItem.name
        }}</v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn color="red" dark class="font-weight-bold" @click="_deleteOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ сорил үүсгэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="editedItem.createdMonth"
                  label="Сар"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="editedItem.createdYear"
                  label="Жил"
                  :items="[2022, 2023]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row>
              <v-radio-group v-model="editedItem.assignmentType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in $attrs.assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="$attrs.lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-switch
                  color="red"
                  v-model="editedItem.forAllSchool"
                  :label="
                    editedItem.forAllSchool
                      ? 'Бүх сургуульд нээлттэй'
                      : 'Өөрийн сургуульд нээлттэй'
                  "
                ></v-switch>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  :items="[
                    { name: 'Xичээлийн xүрээнд', sorilIndex: 3 },
                    { name: 'Сургуулийн xүрээнд', sorilIndex: 2 },
                    { name: 'Тусгай сорил', sorilIndex: 1 },
                  ]"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="name"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openSorilResults" scrollable persistent>
      <v-card>
        <!-- <v-btn
          @click="openSorilResults = !openSorilResults"
          class="bg-gradient-danger white--text mt-12 ml-4 mb-n8"
          >Буцах</v-btn
        > -->
        <v-card-title>
          <v-row>
            <v-col cols="6" md="6" lg="6"> </v-col>
            <v-col cols="6" md="6" lg="6" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="openSorilResults = !openSorilResults"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="assignment.assignmentType == 1">
            <XShalgaltResults :item="assignment"></XShalgaltResults>
          </div>
          <div v-else>
            <XShowAssignmentAllResults
              :sorilId="assignment.ref.path"
              :maxNumber="assignment.yyy"
              :fromEYESH="fromEYESH"
            ></XShowAssignmentAllResults>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="openSorilResults = !openSorilResults"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import XShalgaltResults from "./XShalgaltResults";
import XShowAssignmentAllResults from "./XShowAssignmentAllResults";
import { sync } from "vuex-pathify";
export default {
  components: {
    XShalgaltResults,
    XShowAssignmentAllResults,
  },
  data: function () {
    return {
      openSorilResults: false,
      schoolScoreData: null,
      closedAverageScore: null,
      rules: {
        required: (value) => !!value || "Шаардлагатай.",
      },
      deleteDialog: false,
      editedItem: {},
      messageNotification: null,
      newDialog: false,
      lessonCategories: null,
      defaultCategories: [
        {
          name: "1",
          abc: true,
          catIndex: 0,
          categoryScoreRequired: false,
          givingScorePerQuestion: true,
          withKeys: false,
        },
        {
          name: "2.1",
          abc: false,
          catIndex: 1,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.2",
          abc: false,
          catIndex: 2,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.3",
          abc: false,
          catIndex: 3,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.4",
          abc: false,
          catIndex: 4,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
      ],
    };
  },
  computed: {
    ...sync("*"),
    _getProgress() {
      var number = 0;
      if (this.lessonCategories) {
        for (var lcategory of this.lessonCategories) {
          console.log(
            lcategory.ref,
            this.assignment.categoryRef.path,
            lcategory.ref.path == this.assignment.categoryRef.path
          );
          if (lcategory.ref.path == this.assignment.categoryRef.path) {
            number = lcategory.counter;
            break;
          }
        }
      }
      return number;
    },
  },

  props: {
    assignment: {
      type: Object,
      required: true,
    },
    fromEYESH: {
      type: Boolean,
      required: true,
    },
    noDetail: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  created() {},
  mounted() {
    var number = 0;
    if (this.lessonCategories) {
      for (var lcategory of this.lessonCategories) {
        console.log(
          lcategory.ref.path
          // this.assignment.categoryRef.path,
          // lcategory.ref.path == this.assignment.categoryRef.path
        );
        if (lcategory.ref.path == this.assignment.categoryRef.path) {
          number = lcategory.counter;
          break;
        }
      }
    }
    console.log(number);
    if (this.userData.school) {
      this.assignment.ref
        .collection("school-closed-results")
        .doc(this.userData.school.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let schoolScoreData = doc.data();
            schoolScoreData.id = doc.id;
            schoolScoreData.ref = doc.ref;
            this.schoolScoreData = schoolScoreData;
          }
        });
    }
  },
  methods: {
    _makeAssignmentPublic(assignment) {
      var copiedAssignment = Object.assign({}, assignment);
      if (fb.EYESH_APP == false) {
        copiedAssignment.categoryRef = null;
        copiedAssignment.createdByRef = null;
        copiedAssignment.selectedLessonCategory = null;
        copiedAssignment.ref = null;
        copiedAssignment._originalAssignmentRef = null;
      }
      if (copiedAssignment.isPublicForSchool == false) {
        console.log(
          this.userData.school.ref.path +
            "/opened-assignments/" +
            copiedAssignment.id
        );
        this.userData.school.ref
          .collection("opened-assignments")
          .doc(copiedAssignment.id)
          .set(copiedAssignment, { merge: true })
          .then(() => {
            assignment.isPublicForSchool = true;
            console.log(
              this.userData.school.ref.path +
                "/opened-assignments/" +
                copiedAssignment.id
            );
          });
      } else {
        this.userData.school.ref
          .collection("opened-assignments")
          .doc(copiedAssignment.id)
          .delete()
          .then(() => {
            assignment.isPublicForSchool = false;
            console.log("BBBAA");
          });
      }
    },
    _log() {
      console.log(this.assignment.ref.path);
    },
    _closeSoril(assignment) {
      if (this.userData.school) {
        var counter = 1;
        var sumOfAverageTotalScores = 0;
        var maxTotalScore = 0;
        assignment.ref
          .collection("results-simplified")
          .orderBy("totalScore", "desc")
          .onSnapshot(async (docs) => {
            this.results = [];
            if (!docs.empty) {
              docs.forEach((doc) => {
                let result = doc.data();
                result.ref = doc.ref;
                result.id = doc.id;
                result.index = counter;
                result.percent =
                  ((result.totalScore / this.totalScore) * 100).toFixed(0) +
                  "%";

                if (result.skipped == false) {
                  sumOfAverageTotalScores =
                    sumOfAverageTotalScores + result.totalScore;
                  if (maxTotalScore < result.totalScore)
                    maxTotalScore = result.totalScore;
                }
                counter++;
              });
            } //after loop
            console.log(sumOfAverageTotalScores);
            console.log(counter);
            console.log(maxTotalScore);

            if (this.schoolScoreData) {
              assignment.ref
                .collection("school-closed-results")
                .doc(this.userData.school.id)
                .delete()
                .then(() => {
                  this.schoolScoreData = null;
                });
            } else {
              assignment.ref
                .collection("school-closed-results")
                .doc(this.userData.school.id)
                .set({
                  averageScore: sumOfAverageTotalScores / counter,
                  sumOfAverageTotalScores: sumOfAverageTotalScores,
                  maxTotalScore: maxTotalScore,
                  numberOfParticipants: counter,
                  createdAt: new Date(),
                  createdByRef: this.userData.ref,
                  createdByName: this.userData.firstName,
                })
                .then(() => {});
            }
          });
      }
    },
    _copyTest(assignment) {
      assignment.ref.get().then((test) => {
        let assignmentData = test.data();
        assignmentData.createdByRef = null;
        assignmentData.selectedLessonCategory = null;
        assignmentData.ref = null;
        assignmentData.categoryRef = null;
        assignmentData.description = null;
        console.log(assignment.ref.path);
        assignmentData.createdByEmail = "granditxxk@gmail.com";
        assignmentData.schoolIndex = null;

        fb.dbeyesh800
          .collection("assignmentsdatabase")
          .doc(assignmentData.id)
          .set(assignmentData)
          .then(() => {
            fb.db
              .doc(assignment.ref.path)
              .collection("categories")
              .get()
              .then((categories) => {
                categories.forEach((category) => {
                  let categoryData = category.data();
                  categoryData.ref = null;
                  categoryData.questions = null;
                  // console.log(categoryData)
                  fb.dbeyesh800
                    .collection("assignmentsdatabase")
                    .doc(assignmentData.id)
                    .collection("categories")
                    .doc(category.id)
                    .set(categoryData)
                    .then(() => {
                      fb.db
                        .doc(category.ref.path)
                        .collection("questions")
                        .get()
                        .then((questions) => {
                          questions.forEach((question) => {
                            let questionData = question.data();

                            //questionData.ref = questionData.ref.path;
                            questionData.ref = null;
                            console.log(questionData);

                            fb.dbeyesh800
                              .collection("assignmentsdatabase")
                              .doc(assignmentData.id)
                              .collection("categories")
                              .doc(category.id)
                              .collection("questions")
                              .doc(questionData.id)
                              .set(questionData)
                              .then(() => {
                                console.log("complete");
                              });
                          });
                        });
                    });
                });
              });
          });
      });
    },
    _seeResults(assignment) {
      console.log(assignment, assignment.assignmentType);
      if (this.assignment.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: this.assignment },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: {
            sorilId: this.assignment.ref.path,
            maxNumber: this.assignment.yyy,
          },
        });
      }
    },
    _isAllowedToEdit() {
      if (this.userData.email == this.asuulga.createdByEmail) return true;
      else return false;
    },
    _shareLink(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          testingtesting: false,
          fromEYESH: this.fromEYESH,
        },
      });
    },
    _notShowAnswers(assignment) {
      console.log(assignment.ref.path);
      assignment.ref.update({
        showingCorrectAnswers: !assignment.showingCorrectAnswers,
      });
    },
    _finishAssignment(assignment) {
      if (assignment.finished)
        assignment.ref.update({ finished: !assignment.finished });
      else assignment.ref.update({ finished: true });
    },
    _redirectResults(soril) {
      if (soril.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: soril },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: { sorilId: soril.ref.path },
        });
      }
    },
    _makePublic(assignment) {
      assignment.public = !assignment.public;
      assignment.ref.update(assignment);
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        console.log(this.editedItem.ref.path);
        this.editedItem.ref.update({ deleted: true }).then(() => {
          this.deleteDialog = false;
          this.editedItem = {};
        });
      }
    },
    _editItem(item) {
      this.newDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.selectedLessonCategory
      ) {
        // this.editedItem.createdAt = new Date();
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;
        this.editedItem.schoolIndex = this.userData.school.schoolIndex
          ? this.userData.school.schoolIndex
          : null;
        this.editedItem.ref.update(this.editedItem);
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _detail(item) {
      this.$router.push({
        name: "XShalgaltViewEyesh",
        params: {
          xpath: item.ref.path,
          fromEYESH: this.fromEYESH,
        },
      });
    },
    _isAllowed(assignment) {
      if (
        (this.userData && assignment.createdByEmail == this.userData.email) ||
        this.userData.role == "superadmin" ||
        this.userData.role == "eyeshadmin" ||
        this.userData.role == "aspecialist"
      )
        return true;
      // else if (
      //   // this.userData.role == "aspecialist" ||
      //   // this.userData.role == "eyeshadmin" ||
      //   this.userData.role == "superadmin"
      // )
      //   return true;
      else return false;
    },
  },
};
</script>
