<template>
  <v-card
    class="card-shadow border-radius-xl"
    style="height: 400px; overflow-y: auto"
    v-if="students != null && students != undefined"
  >
    <v-card-text class="px-0 py-0 scoreTables">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="(item, i) in students" :key="item.id">
              <td :class="{ 'border-bottom': i != students.length - 1 }">
                <v-list class="py-0" >
                  <v-list-item class="px-0 py-1">
                    <!-- <v-list-item-avatar rounded :size="36" class="my-0 me-5">
                      {{item.index}}
                      <v-img
                        :src='require("@/assets/img/team-2.jpg")'
                        class="border-radius-lg"
                      ></v-img>
                    </v-list-item-avatar> -->
                    {{ item.index }}.
                    <!-- <v-avatar color="#f6f6f6" dark size="32" class="mx-4"
                      ><span class="black--text">{{
                        item.firstName.substring(0, 2).toUpperCase()
                      }}</span></v-avatar
                    > -->

                    <v-list-item-content class="ml-2">
                      <v-list-item-title
                        class="mb-0 text-sm text-typo font-weight-bold"
                        >{{ item.firstName }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "light-table-transparent",
  data() {
    return {
      search: "",

      headers2: [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "1%",
          fixed: true,
        },
        {
          text: "Name",
          align: "start",
          value: "firstName",
          sortable: true,
          fixed: true,
        },
      ],
    };
  },
  computed: {},
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  methods: {},
  created() {},
};
</script>
 