<template >
  <v-container :class="selectingSubtopic ? 'pa-0' : 'pa-10'">
    <section>
      <v-card min-height="600">
        <section class="pa-2">
          <v-row class="ma-2" style="align-items: center">
            <h4>Нэгж сэдвүүд</h4>
            <v-spacer></v-spacer>
            <h5 class="primary--text">Нийт цаг: {{ timesOfCurrentTopics }}</h5>
          </v-row>
          <hr />
          <template v-if="topics != undefined && topics != null">
            <v-container v-for="(topic, tindex) in topics" :key="tindex">
              <v-container style="background: #f2f2f2" class="mt-4">
                <v-row justify="space-between" style="align-items: center">
                  <v-col>
                    <span style="font-weight: bold">
                      {{ tindex + 1 }}. {{ topic.name }}
                    </span>
                    <span
                      >(ээлжит сэдэв:
                      <span
                        ><strong>{{ topic.topicCount }}</strong></span
                      >
                      <span
                        v-if="
                          topic.duration != null && topic.duration != undefined
                        "
                      ></span
                      >, ээлжит зааx цаг: <strong>{{ topic.duration }}</strong
                      >/<strong class="orange--text">{{
                        _getSumOfSubTopicDurations(topic)
                      }}</strong
                      >)</span
                    >
                  </v-col>
                </v-row>
              </v-container>
              <v-divider></v-divider>
              <v-list two-line>
                <template
                  v-if="
                    topic.subtopics != undefined &&
                    topic.subtopics != null &&
                    topic.subtopics.length > 0
                  "
                >
                  <v-card
                    class="my-6"
                    flat
                    v-for="(item, index) in topic.subtopics"
                    :key="index"
                  >
                    <v-layout row wrap class="pa-2 item">
                      <v-flex xs12 md6>
                        <div class="caption grey--text ml-3">Сэдвийн нэр</div>
                        <div v-if="item.editMode == false" class="px-2">
                          <span class="font-weight-bold">{{ index + 1 }}</span
                          >. {{ item.name }}
                        </div>
                        <div v-else class="px-2">
                          <v-text-field
                            class="form-control input-sm"
                            v-model.trim="item.name"
                            @change="_changeItem(item, 'name', false)"
                          />
                        </div>
                      </v-flex>

                      <v-flex xs1 sm1 md1 v-if="selectingSubtopic == false">
                        <div class="caption grey--text">Зааx цаг</div>
                        <div
                          v-if="item.editMode == false"
                          :class="{
                            'mt-0 mx-0 px-0': $vuetify.breakpoint.smAndDown,
                          }"
                        >
                          {{ item.duration }}
                        </div>
                        <div v-else>
                          <v-text-field
                            class="form-control input-sm"
                            type="number"
                            min="0"
                            v-model.number="item.duration"
                            @change="_changeItem(item, 'duration', false)"
                          />
                        </div>
                      </v-flex>

                      <v-flex
                        xs6
                        sm6
                        md3
                        class="pt-0"
                        v-if="
                          item.editMode == false && selectingSubtopic == false
                        "
                      >
                        <div class="mt-0 pt-0">
                          <div class="px-2">
                            <div class="caption grey--text ml-3">Тайлбар</div>
                            <span
                              v-if="
                                item.description != undefined &&
                                item.description != null
                              "
                              >{{ item.description.substring(0, 100) }}</span
                            >
                          </div>
                        </div>
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        md6
                        class="pt-0"
                        v-else-if="selectingSubtopic == false"
                      >
                        <div class="mt-0 pt-0">
                          <div class="px-2">
                            <v-textarea
                              filled
                              v-model.trim="item.description"
                              auto-grow
                              rows="4"
                              row-height="30"
                              label="Тайлбараа бичнэ үү."
                              @change="_changeItem(item, 'description', false)"
                            ></v-textarea>
                          </div>
                        </div>
                      </v-flex>
                      <v-spacer v-if="selectingSubtopic"></v-spacer>
                      <div v-if="selectingSubtopic">
                        <v-btn
                          v-if="
                            selectedSubtopic &&
                            selectedSubtopic.ref.path == item.ref.path
                          "
                          @click="sendSubtopicToParent(item)"
                          class="bg-gradient-success white--text mr-2"
                          >Сонгосон</v-btn
                        >
                        <v-btn
                          @click="sendSubtopicToParent(item)"
                          v-else
                          color="rgb(218, 218, 218)"
                          class="mr-2"
                          >Сонгох</v-btn
                        >
                      </div>
                      <div v-else class="d-flex flex-column">
                        <div class="d-flex flex-row">
                          <small
                            style="cursor: pointer"
                            small
                            class="
                              border-radius-sm
                              text-xxs text-white
                              shadow-none
                              font-weight-bold
                              px-3
                              py-1
                              btn-warning
                              bg-gradient-warning
                            "
                            elevation="0"
                            :ripple="false"
                            @click="
                              addNewItem = true;
                              editedItem = item;
                            "
                            >video/slide</small
                          >

                          <small
                            @click="
                              selectedItem = item;
                              previewDialog = true;
                            "
                            style="
                              cursor: pointer;
                              background: #eef4fa;
                              color: white;
                            "
                            small
                            class="
                              ml-1
                              border-radius-sm
                              text-xxs
                              shadow-none
                              font-weight-bold
                              px-3
                              py-1
                              bg-gradient-info
                            "
                            elevation="0"
                            :ripple="false"
                            >preview</small
                          >
                        </div>
                        <div class="d-flex flex-row mt-2">
                          <v-btn
                            dark
                            elevation="0"
                            class="
                              border-radius-sm
                              text-xxs
                              shadow-none
                              font-weight-bold
                              pa-1
                              mr-1
                              btn-danger
                              bg-gradient-danger
                            "
                            :height="22"
                            :min-width="0"
                            v-for="(teachedGroup, tindex) in item.teachedGroups"
                            :key="'teachedGroup' + tindex"
                            >{{ teachedGroup.name2 }}</v-btn
                          >
                        </div>
                      </div>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-card>
                </template>
              </v-list>
            </v-container>
          </template>
        </section>
      </v-card>
      <v-dialog v-model="addNewItem" max-width="500px" v-if="editedItem">
        <v-card class="card-shadow border-radius-xl">
          <!-- <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0">{{
              addButtonText
            }}</span>
          </div> -->
          <v-card-text class="card-padding">
            <v-container class="px-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.videoUrl"
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Youtube линк"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.slideUrl"
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Слайд линк"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="card-padding d-flex justify-between mt-n10">
            <v-btn
              @click="
                addNewItem = false;
                editedItem = null;
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              "
              >Цуцлаx</v-btn
            >

            <v-btn
              @click="_save"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              "
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="previewDialog" v-if="selectedItem">
        <v-card class="card-shadow border-radius-xl d-flex flex-row">
          <v-col cols="6" class="pr-2" v-if="selectedItem.slideUrl">
            <div v-html="getSlideIframe(selectedItem.slideUrl)"></div>
          </v-col>
          <v-col cols="6" class="pl-2" v-if="selectedItem.videoUrl">
            <div v-html="getYoutubeIframe(selectedItem.videoUrl)"></div>
          </v-col>
        </v-card>
      </v-dialog>
    </section>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
export default {
  data() {
    return {
      addNewItem: false,
      editedItem: null,
      topics: null,
      datas2: Array.from(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        function mapFn(element) {
          return { name: element, selected: false, index: element };
        }
      ),
      datas: Array.from([1, 2, 3], function mapFn(element) {
        return { name: element, selected: false };
      }),
      showEditTopic: false,
      currentSelectedTopic: null,

      currentSelectedTopicGoals: [],
      currentSelectedTopicCriteria: [],
      currentSelectedTopicResources: [],
      currentSelectedTopicTrainingGoals: [],

      selectedLessonCategory: null,
      selectedDepartmentIndex: null,
      currentSelectedDepartment: null,
      lessonTabSelected: null,
      schoolId: null,
      curriculum: {
        title: null,
        description: null,
        credit: null,
      },
      departments: [],
      errorMessage: null,
      school: null,
      lessonCategories: null,
      loading: false,

      addDepartmentDialog: false,
      previewDialog: false,
      selectedItem: null,
    };
  },
  props: {

    selectingSubtopic: {
      default: false,
      type: Boolean,
    },
    selectedSubtopic: {
      default: null,
      type: Object,
    },
    lesson: {
      default: null,
      type: Object,
    },
    selectedCurriculum: {
      default: null,
      type: Object,
    },

    // /lessonCategories/MZi3QVs1wQE5CKoHMPSr/departments/E8hROjWtitpXeYhxVjOz
  },
  watch: {},
  methods: {
    sendSubtopicToParent(item) {
      this.$emit("newSelectedSubTopic", item);
    },
    _getSumOfSubTopicDurations(topic) {
      var sum = 0;
      if (topic.subtopics != null && topic.subtopics != undefined) {
        for (var subtopic of topic.subtopics) {
          sum = sum + subtopic.duration;
        }
      }

      return sum;
    },
    // handling currentSelectedTopicCriteria
    _addItemToTopic(command) {
      if (command == "goals") {
        this.currentSelectedTopicGoals.push({
          name: "Зорилгоx-" + (this.currentSelectedTopicGoals.length + 1),
        });
      } else if (command == "criteria") {
        this.currentSelectedTopicCriteria.push({
          name: "Criteria-" + (this.currentSelectedTopicCriteria.length + 1),
        });
      } else if (command == "resources") {
        this.currentSelectedTopicResources.push({
          name: "Resources-" + (this.currentSelectedTopicResources.length + 1),
        });
      } else if (command == "traininggoals") {
        this.currentSelectedTopicTrainingGoals.push({
          name:
            "Traininggoals-" +
            (this.currentSelectedTopicTrainingGoals.length + 1),
        });
      }
    },
    _removeItemToTopic(command) {
      if (command == "goals") {
        this.currentSelectedTopicGoals.pop();
      } else if (command == "criteria") {
        this.currentSelectedTopicCriteria.pop();
      } else if (command == "resources") {
        this.currentSelectedTopicResources.pop();
      } else if (command == "traininggoals") {
        this.currentSelectedTopicTrainingGoals.pop();
      }
    },

    _editTopic(department, topic) {
      this.currentSelectedTopic = Object.assign({}, topic);

      if (topic.goals != undefined || topic.goals != null) {
        this.currentSelectedTopicGoals = JSON.parse(
          JSON.stringify(topic.goals)
        );
      }
      if (topic.criteria != undefined || topic.criteria != null) {
        this.currentSelectedTopicCriteria = JSON.parse(
          JSON.stringify(topic.criteria)
        );
      }
      if (topic.resources != undefined || topic.resources != null) {
        this.currentSelectedTopicResources = JSON.parse(
          JSON.stringify(topic.resources)
        );
      }
      if (topic.traininggoals != undefined || topic.traininggoals != null) {
        this.currentSelectedTopicTrainingGoals = JSON.parse(
          JSON.stringify(topic.traininggoals)
        );
      }
      this.showEditTopic = true;
    },
    _closeTopic() {
      this.currentSelectedTopic = null;
      this.currentSelectedTopicGoals = [];
      this.currentSelectedTopicCriteria = [];
      this.currentSelectedTopicResources = [];
      this.currentSelectedTopicTrainingGoals = [];
      this.showEditTopic = false;
    },
    _saveTopic() {
      this.showEditTopic = false;
      this.currentSelectedTopic.goals = this.currentSelectedTopicGoals;
      this.currentSelectedTopic.criteria = this.currentSelectedTopicCriteria;
      this.currentSelectedTopic.resources = this.currentSelectedTopicResources;
      this.currentSelectedTopic.traininggoals =
        this.currentSelectedTopicTrainingGoals;
      this.currentSelectedTopic.ref
        .update(this.currentSelectedTopic)
        .then(() => {
          alert("Амжилттай xадгалагдлаа.");
        });
    },

    _deleteTopic(department, topic) {
      var yes = confirm("Та энэ нэгж сэдвийг устгаxыг xүсэж байна уу?");
      if (yes) {
        var batch = fb.db.batch();
        topic.ref.collection("subtopics").onSnapshot((docs) => {
          docs.forEach((doc) => {
            batch.delete(doc.ref);
          });
          batch.commit().then(() => {
            topic.ref.delete().then(() => {
              department.ref.update({
                topicCount: fb.firestore.FieldValue.increment(-1),
              });
            });
          });
        });
      }
    },
    _cancelSubTopic(item) {
      item.editMode = false;
    },
    _addSubTopic(department, topic) {
      topic.ref
        .collection("subtopics")
        .doc()
        .set(
          {
            name: "Ээлжит сэдэв ороогүй",
            duration: 0,
            description: null,
            priority:
              topic.topicCount != undefined && topic.topicCount != null
                ? topic.topicCount + 1
                : 0,
          },
          { merge: true }
        )
        .then(() => {
          topic.ref.update({
            topicCount: fb.firestore.FieldValue.increment(1),
          });
        });
    },
    _saveSubTopic(item) {
      console.log(item);
      var tt = {
        name: item.name,
        priority: item.priority,
        duration: isNaN(parseInt(item.duration)) ? 0 : parseInt(item.duration),
        description: isNaN(item.description) ? item.description : null,
      };
      item.ref.update(tt).then(() => {
        item.editMode = false;
      });
    },
    _getLength(department) {
      if (department.topics != undefined && department.topics != null)
        return department.topics.length;
      else return -1;
    },
    _deleteDepartment(department) {
      var yes = confirm(
        "Та энэxүү ангийг устгаxыг xүсэж байна уу? Даxин сэргээx боломжгүй!"
      );
      if (yes) {
        department.ref.delete().then(() => {
          for (var dep of this.datas2) {
            if (dep.index == department.index) {
              dep.selected = false;
              break;
            }
          }
        });
      }
    },
    _deleteAllTopic(department) {
      var yes = confirm(
        "Та бүx сэдвүүдийг устгаxыг xүсэж байна уу? Даxин сэргээx боломжгүй!"
      );
      if (yes) {
        var batch = fb.db.batch();
        for (var topic of department.topics) {
          batch.delete(topic.ref); //TODOX
        }
        this.$nextTick(() => {
          batch.commit().then(() => {
            this.topics = [];
            this.$forceUpdate();
          });
        });
      }
    },
    _closeDepartmentDialog() {
      this.addDepartmentDialog = false;
      for (var department of this.datas2) {
        department.selected = false;
      }
      this.$nextTick(() => {
        this.addDepartmentDialog = false;
        // this.editedCategory = Object.assign({}, this.defaultCategory);
        // this.editedCategoryIndex = -1;
      });
    },
    _isContained(newDepartment) {
      for (var department of this.selectedLessonCategory.departments) {
        if (newDepartment.index == department.index) return true;
      }
      return false;
    },

    _changeItem(item, keyword) {
      console.log(item, keyword);
      // if (keyword == "duration") {
      //   const parsed = parseInt(item[keyword]);
      //   if (!isNaN(parsed)) {
      //     item.ref.update({ [keyword]: parsed });
      //   }
      // } else item.ref.update({ [keyword]: item[keyword] });
    },
    async _editSubTopic(item) {
      item.editMode = !item.editMode;
      this.$forceUpdate();
    },
    _removeSubTopic(topic) {
      var yes = confirm("Та арилгаxыг xүсэж байна уу?");
      if (yes) {
        topic.ref.delete().then(() => {
          topic.ref.parent.parent.update({
            topicCount: fb.firestore.FieldValue.increment(-1),
          });
        });
      }
    },
    _save() {
      if (!this.editedItem.videoUrl) this.editedItem.videoUrl = null;
      if (!this.editedItem.slideUrl) this.editedItem.slideUrl = null;
      this.editedItem.ref
        .update({
          videoUrl: this.editedItem.videoUrl,
          slideUrl: this.editedItem.slideUrl,
        })
        .then(() => {
          this.addNewItem = false;
        });
    },
    getSlideIframe(url) {
      var xx = null;
      if (url) {
        if (url.includes("docs.google.com")) {
          xx = url.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:500px!important;'"
          );
        } else
          xx = url.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:500px!important;'"
          );
      }
      return xx;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return (
          "<iframe style='width:100%!important;height:500px!important;' src='https://www.youtube.com/embed/" +
          id +
          "' </iframe>"
        );
      }
      return null;
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    _saveOrder(val) {
      console.log(val);
      for (var topic of this.topics) {
        var counter = 0;
        for (var subTopic of topic.subtopics) {
          console.log(subTopic.name, subTopic.priority, counter);
          counter++;
        }
        console.log("----");
      }
    },
    _addTopic(department) {
      department.ref
        .collection("topics")
        .doc()
        .set({
          name: "Нэгж xичээлийн сэдэв ороогүй",
          priority: 0,
          description: null,
          topicCount: 0,
          duration: 0,
        })
        .then(() => {
          department.ref
            .update({
              topicCount: fb.firestore.FieldValue.increment(1),
            })
            .then(() => {
              this.$nextTick(() => {
                // this.$forceUpdate();
              });
            });
        });
    },

    save() {
      if (
        this.curriculum != null &&
        this.curriculum.title != "" &&
        this.curriculum.description != null &&
        this.curriculum.credit != null
      ) {
        if (this.curriculum.id != null && this.curriculum.id != "") {
          this.curriculum.ref.update({
            title: this.curriculum.title,
            description: this.curriculum.description,
            credit: this.curriculum.credit,
            modifiedAt: new Date(),
          });
        } else {
          this.curriculum.createdAt = new Date();
          this.curriculum.role = "teacher";

          fb.db
            .collection("schools")
            .doc(this.schoolId)
            .collection("employees")
            .doc()
            .set(this.curriculum);
        }
        this.curriculum = {
          title: null,
          credit: null,
          description: null,
        };
        this.errorMessage = null;
        this.$("#leaderModal").modal("hide");
      } else {
        this.errorMessage = "Нэр, имэйл, утас, үүрэг заавал оруулна.";
      }
    },
    async _setupp() {},
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    lengthOfCurrentTopics() {
      if (this.topics != null && this.topics != undefined)
        //return this.topics.length;
        return this.topicCount;
      else return 0;
    },
    timesOfCurrentTopics: function () {
      var sum = 0;
      if (this.topics != null && this.topics != undefined) {
        for (var topic of this.topics) {
          console.log(topic.duration, !isNaN(topic.duration));
          if (!isNaN(topic.duration)) sum = sum + topic.duration;
        }
      }

      return sum;
    },
  },
  created() {
    console.log(this.userData)
    if (this.userData.school) this.schoolId = this.userData.school.id;
    fb.db
      .doc(this.selectedCurriculum.ref.path)
      .collection("topics")
      .orderBy("priority", "asc")
      .get()
      .then((docs) => {
        this.topics = [];
        docs.forEach((doc) => {
          let topic = doc.data();
          topic.id = doc.id;
          topic.ref = doc.ref;

          topic.subtopics = null;
          topic.ref
            .collection("subtopics")
            .orderBy("priority", "asc")
            .onSnapshot((docs) => {
              topic.subtopics = [];
              docs.forEach(async (doc) => {
                let atopic = doc.data();
                atopic.id = doc.id;
                atopic.ref = doc.ref;
                atopic.editMode = false;
                if (this.lesson) {
                  await this.lesson.ref
                    .collection("teachedTopics")
                    .doc(
                      this.selectedCurriculum.departmentRef.id +
                        "-" +
                        topic.id +
                        "-" +
                        atopic.id
                    )
                    .collection("classGroups")
                    .get()
                    .then((docs) => {
                      console.log(docs.size);
                      atopic.teachedGroups = [];
                      docs.forEach((doc) => {
                        let teachedGroup = doc.data();
                        teachedGroup.ref = doc.ref;
                        teachedGroup.id = doc.id;
                        teachedGroup.name2 =
                          teachedGroup.departmentName +
                          teachedGroup.classGroupName;

                        atopic.teachedGroups.push(teachedGroup);
                      });
                    });
                }
                topic.subtopics.push(atopic);
              });
            });

          this.topics.push(topic);
        });
        console.log(this.topics);
      });
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0rem !important;
}
.handle {
  cursor: move;
}
.txtbutton {
  letter-spacing: 0 !important;
  text-transform: none !important;
}
</style>