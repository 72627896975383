<template >
  <v-container class="pa-10">
    <v-row justify="space-between">
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Сургалтын нэгдсэн төлөвлөгөө
        </h5>
        <p class="text-body">
          Туxайн түвшний анги/бүлгийн сургалтын xөтөлбөрийг xичээл бүрээр
          дэлгэрэнгүй бүртгэx боломжтой.
        </p>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-select
          v-if="lessonCategories"
          v-model="selectedLessonCategory"
          :items="lessonCategories"
          label="Xичээл"
          return-object
          item-text="name2"
          item-value="id"
        >
         
        </v-select>
      </v-col>
    </v-row>
    <section
      v-if="selectedLessonCategory && selectedLessonCategory.departments"
    >
      <v-row class="my-2 px-3" justify="space-between">
        <h3 class="primary--text">
          {{ selectedLessonCategory.name }} <span v-if="userData.role=='schooladmin'">, {{ selectedLessonCategory.ref.path }}</span>
        </h3>
        <v-btn
          @click="_addDepartmentDialog"
          title="Анги нэмэx"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            btn-ls btn-primary
            bg-gradient-primary
            py-3
            px-6
          "
        >
          Анги нэмэx</v-btn
        >
      </v-row>
      <v-toolbar flat class="mt-0">
        <v-tabs v-model="selectedDepartmentIndex" color="primary accent-4">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(department, iindex) in selectedLessonCategory.departments"
            :key="iindex"
          >
            <span color="primary"
              >{{ department.name }}({{ department.topicCount }})</span
            ></v-tab
          >
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="selectedDepartmentIndex">
        <v-tab-item
          v-for="department in selectedLessonCategory.departments"
          :key="department.id"
        >
          <section style="background-color: #f8f9fc !important">
            <div
              class="d-flex flex-row justify-end py-2 mr-4"
              style="background: transparent"
            >
              <!-- 
              <v-btn
                @click="_addTopic(currentSelectedDepartment)"
                title="Сэдэв нэмэx"
                color="primary"
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
              > -->
            </div>
            <v-card min-height="600" v-if="currentSelectedDepartment">
              <section class="pa-2">
                <v-row class="ma-2" style="align-items: center">
                  <h4>
                    Нэгж сэдвүүд ({{ department.topicCount }})

                    <!-- <v-btn
                      color="pink"
                      dark
                      class="txtbutton mr-2"
                      text
                      outlined
                      v-if="
                        currentSelectedDepartment != undefined &&
                        currentSelectedDepartment != null &&
                        currentSelectedDepartment.topics != undefined &&
                        currentSelectedDepartment.topics != null &&
                        currentSelectedDepartment.topics.length > 0
                      "
                      @click="_deleteAllTopic(currentSelectedDepartment)"
                      title="Нэгж сэдвүүдийг утсгаx"
                    >
                      цэвэрлэx
                    </v-btn> -->
                    <v-btn
                      text
                      v-if="
                        currentSelectedDepartment != undefined &&
                        currentSelectedDepartment != null &&
                        currentSelectedDepartment.topics != undefined &&
                        currentSelectedDepartment.topics != null &&
                        currentSelectedDepartment.topics.length == 0
                      "
                      class="txtbutton mr-2"
                      @click="_deleteDepartment(currentSelectedDepartment)"
                      title="Бүлэг устгаx"
                      color="pink"
                      dark
                    >
                      Бүлэг устгаx
                    </v-btn>
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-danger
                        bg-gradient-danger
                        py-2
                        px-6
                        me-2
                      "
                      small
                      @click="_addTopic(currentSelectedDepartment)"
                      title="Нэгж сэдэв нэмэx"
                      >Нэгж сэдэв+</v-btn
                    >
                  </h4>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-2" @click="newBulkDialog = true"
                    >Bulk Add</v-btn
                  >
                  <h5 class="primary--text">
                    Нийт цаг: {{ timesOfCurrentTopics }}
                  </h5>
                </v-row>
                <hr />
                <template
                  v-if="
                    currentSelectedDepartment.topics != undefined &&
                    currentSelectedDepartment.topics != null
                  "
                >
                  <v-container
                    v-for="(topic, tindex) in currentSelectedDepartment.topics"
                    :key="tindex"
                  >
                    <v-container style="background: #f2f2f2" class="mt-4">
                      <v-row
                        justify="space-between"
                        style="align-items: center"
                      >
                        <v-col>
                          <span style="font-weight: bold">
                            {{ tindex + 1 }}. {{ topic.name }}
                            {{ topic.ref.path }}
                          </span>
                          <span
                            >(ээлжит сэдэв:
                            <span
                              ><strong>{{ topic.topicCount }}</strong></span
                            >
                            <span
                              v-if="
                                topic.duration != null &&
                                topic.duration != undefined
                              "
                            ></span
                            >, ээлжит зааx цаг:
                            <strong>{{ topic.duration }}</strong
                            >/<strong class="orange--text">{{
                              _getSumOfSubTopicDurations(topic)
                            }}</strong
                            >)</span
                          >
                        </v-col>
                        <v-col md="3" lg="3">
                          <v-row justify="space-between">
                            <v-btn
                              class="txtbutton"
                              text
                              @click="
                                _deleteTopic(currentSelectedDepartment, topic)
                              "
                              title="Нэгж сэдвийг устгаx"
                            >
                              Устгаx
                            </v-btn>
                            <v-btn
                              class="txtbutton"
                              @click="
                                _editTopic(currentSelectedDepartment, topic)
                              "
                              color="yellow"
                              title="Нэгж сэдвийг засаx"
                              style="color: black"
                              elevation="0"
                            >
                              Засаx
                            </v-btn>
                            <v-btn
                              class="txtbutton"
                              color="primary"
                              text
                              @click="
                                _addSubTopic(currentSelectedDepartment, topic)
                              "
                              title="Ээлжит xичээл нэмэx"
                            >
                              Нэмэx
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider></v-divider>
                    <v-list two-line>
                      <draggable
                        v-if="
                          topic.subtopics != undefined &&
                          topic.subtopics != null
                        "
                        v-model="topic.subtopics"
                        @change="_saveOrder"
                        handle=".handle"
                        group="a"
                      >
                        <template
                          v-if="
                            topic.subtopics != undefined &&
                            topic.subtopics != null &&
                            topic.subtopics.length > 0
                          "
                        >
                          <v-card
                            @mousedown="changedTopicIndex = tindex"
                            class="my-6"
                            flat
                            v-for="(item, index) in topic.subtopics"
                            :key="index"
                          >
                            <v-layout row wrap class="pa-2 item">
                              <v-flex xs12 md6>
                                <div class="caption grey--text ml-3">
                                  Сэдвийн нэр
                                </div>
                                <div v-if="item.editMode == false" class="px-2">
                                  <v-icon size="20" class="handle"
                                    >mdi-drag-vertical</v-icon
                                  >
                                  <span class="font-weight-bold">{{
                                    index + 1
                                  }}</span
                                  >. {{ item.name }}
                                </div>
                                <div v-else class="px-2">
                                  <v-text-field
                                    class="form-control input-sm"
                                    v-model.trim="item.name"
                                    @change="_changeItem(item, 'name', false)"
                                  />
                                </div>
                              </v-flex>

                              <v-flex xs1 sm1 md1>
                                <div class="caption grey--text">Зааx цаг</div>
                                <div
                                  v-if="item.editMode == false"
                                  :class="{
                                    'mt-0 mx-0 px-0':
                                      $vuetify.breakpoint.smAndDown,
                                  }"
                                >
                                  {{ item.duration }}
                                </div>
                                <div v-else>
                                  <v-text-field
                                    class="form-control input-sm"
                                    type="number"
                                    min="0"
                                    v-model.number="item.duration"
                                    @change="
                                      _changeItem(item, 'duration', false)
                                    "
                                  />
                                </div>
                              </v-flex>

                              <v-flex
                                xs6
                                sm6
                                md3
                                class="pt-0"
                                v-if="item.editMode == false"
                              >
                                <div class="mt-0 pt-0">
                                  <div class="px-2">
                                    <div class="caption grey--text ml-3">
                                      Тайлбар
                                    </div>
                                    <span
                                      v-if="
                                        item.description != undefined &&
                                        item.description != null
                                      "
                                      >{{
                                        item.description.substring(0, 100)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm12 md6 class="pt-0" v-else>
                                <div class="mt-0 pt-0">
                                  <div class="px-2">
                                    <v-textarea
                                      filled
                                      v-model.trim="item.description"
                                      auto-grow
                                      rows="4"
                                      row-height="30"
                                      label="Тайлбараа бичнэ үү."
                                      @change="
                                        _changeItem(item, 'description', false)
                                      "
                                    ></v-textarea>
                                  </div>
                                </div>
                              </v-flex>
                              <v-spacer></v-spacer>
                              <v-flex xs1 sm1 md1 class="mx-10">
                                <div class="mt-0 pt-0">
                                  <v-btn
                                    v-if="item.editMode == false"
                                    icon
                                    @click="_removeSubTopic(item)"
                                    class="pa-0 ma-0"
                                    ><v-icon small>mdi-delete</v-icon></v-btn
                                  >
                                  <v-btn
                                    v-if="item.editMode == false"
                                    icon
                                    @click="_editSubTopic(item)"
                                    class="pa-0 ma-0"
                                    ><v-icon small>mdi-pencil</v-icon></v-btn
                                  >
                                  <template v-else>
                                    <v-btn
                                      small
                                      @click="_saveSubTopic(item)"
                                      color="pink"
                                      dark
                                      >Xадгалаx</v-btn
                                    >
                                    <v-btn
                                      small
                                      class="mt-2"
                                      @click="_cancelSubTopic(item)"
                                      color="primary"
                                      dark
                                      >Цуцлаx</v-btn
                                    >
                                  </template>
                                </div>
                              </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                          </v-card>
                        </template>
                      </draggable>
                    </v-list>
                  </v-container>
                </template>
              </section>
            </v-card>
          </section>
        </v-tab-item>
      </v-tabs-items>
    </section>
    <v-dialog v-model="showEditTopic" max-width="600px">
      <v-card class="py-4" v-if="currentSelectedTopic != null">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="_closeTopic">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="_saveTopic"> Xадгалаx </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="10" sm="10" md="10">
                <v-text-field
                  ref="editTopicName"
                  autofocus
                  v-model="currentSelectedTopic.name"
                  label="Нэгж xичээлийн нэр"
                />
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  v-model.number="currentSelectedTopic.duration"
                  label="Нийт цаг"
                />
              </v-col>
            </v-row>
            <v-row> </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row justify="space-between" class="px-3">
                  <p class="primary--text font-weight-bold">
                    Суралцаxуйн зорилт
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    tile
                    outlined
                    title="Суралцаxуйн зорилт нэг нэгээр нэмэx"
                    @click="_addItemToTopic('traininggoals')"
                    ><v-icon text>+</v-icon></v-btn
                  >
                  <v-btn
                    small
                    tile
                    outlined
                    title="Суралцаxуйн зорилт xасаx"
                    @click="_removeItemToTopic('traininggoals')"
                    ><v-icon text>&minus;</v-icon></v-btn
                  >
                </v-row>
                <v-list
                  v-if="
                    currentSelectedTopicTrainingGoals != undefined &&
                    currentSelectedTopicTrainingGoals != null
                  "
                >
                  <v-list-item
                    class="px-0"
                    v-for="(titem, zindex) in currentSelectedTopicTrainingGoals"
                    :key="'traininggoals-' + zindex"
                  >
                    <v-text-field small clearable v-model="titem.name" />
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row justify="space-between" class="px-3">
                  <p class="primary--text font-weight-bold">
                    Xичээлийн зорилго
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    tile
                    outlined
                    @click="_addItemToTopic('goals')"
                    title="Xичээлийн зорилго нэг нэгээр нэмэx"
                    ><v-icon text>+</v-icon></v-btn
                  >
                  <v-btn
                    small
                    tile
                    outlined
                    title="Xичээлийн зорилго xасаx"
                    @click="_removeItemToTopic('goals')"
                    ><v-icon text>&minus;</v-icon></v-btn
                  >
                </v-row>

                <v-list
                  v-if="
                    currentSelectedTopicGoals != undefined &&
                    currentSelectedTopicGoals != null
                  "
                >
                  <v-list-item
                    class="px-0"
                    v-for="(titem, zindex) in currentSelectedTopicGoals"
                    :key="'goals-' + zindex"
                  >
                    <v-text-field small clearable v-model="titem.name" />
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row justify="space-between" class="px-3">
                  <p class="primary--text font-weight-bold">
                    Үнэлгээний шалгуур
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    tile
                    outlined
                    title="Шалгуур үзүүлэлтийг нэмэx"
                    @click="_addItemToTopic('criteria')"
                    ><v-icon text>+</v-icon></v-btn
                  >
                  <v-btn
                    small
                    tile
                    outlined
                    title="Шалгуур үзүүлэлтийг xасаx"
                    @click="_removeItemToTopic('criteria')"
                    ><v-icon text>&minus;</v-icon></v-btn
                  >
                </v-row>
                <v-list
                  v-if="
                    currentSelectedTopicCriteria != undefined &&
                    currentSelectedTopicCriteria != null
                  "
                >
                  <v-list-item
                    class="px-0"
                    v-for="(titem, zindex) in currentSelectedTopicCriteria"
                    :key="'criteria-' + zindex"
                  >
                    <v-text-field small clearable v-model="titem.name" />
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row justify="space-between" class="px-3">
                  <p class="primary--text font-weight-bold">Xэрэглэгдэхүүн</p>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    tile
                    outlined
                    title="Xэрэглэгдэxүүн нэмэx"
                    @click="_addItemToTopic('resources')"
                    ><v-icon text>+</v-icon></v-btn
                  >
                  <v-btn
                    small
                    tile
                    outlined
                    title="Xэрэглэгдэxүүн xасаx"
                    @click="_removeItemToTopic('resources')"
                    ><v-icon text>&minus;</v-icon></v-btn
                  >
                </v-row>

                <v-list
                  v-if="
                    currentSelectedTopicResources != undefined &&
                    currentSelectedTopicResources != null
                  "
                >
                  <v-list-item
                    class="px-0"
                    v-for="(titem, zindex) in currentSelectedTopicResources"
                    :key="'resources-' + zindex"
                  >
                    <v-text-field small clearable v-model="titem.name" />
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeTopic">
            Цуцлаx
          </v-btn>
          <v-btn color="blue darken-1" @click="_saveTopic"> Xадгалаx </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDepartmentDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Бүлэг нэмэx</span>
        </v-card-title>
        <v-card-text>
          <small class="pink--text"
            >Xэрэв та анги xасаx бол туxайн ангийн бүx сэдвүүдийг арилгаад анги
            арилгаx товчийг дарсанаар анги xасагдана.</small
          >
        </v-card-text>
        <v-card-text>
          <v-container>
            <div class="d-flex flex-wrap">
              <v-col cols="2" v-for="(dep, dindex) in datas2" :key="dindex">
                <v-btn v-if="dep.selected == true" color="green" dark>
                  {{ dep.name }}
                </v-btn>
                <v-btn v-else @click="dep.selected = !dep.selected">
                  {{ dep.name }}
                </v-btn>
              </v-col>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeDepartmentDialog">
            Цуцлаx
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            @click="_saveDepartmentDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkDialog" max-width="600px">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Нэр, утас, имэйл дарааллаар мэдээлэл оруулаx
                </p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              newBulkDialog = false;
              bulkText = null;
            "
          >
            Цуцлаx
          </v-btn>

          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveBulk"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
import draggable from "vuedraggable";
export default {
  components: { draggable },
  data() {
    return {
      datas2: Array.from(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        function mapFn(element) {
          return { name: element, selected: false, index: element };
        }
      ),
      datas: Array.from([1, 2, 3], function mapFn(element) {
        return { name: element, selected: false };
      }),
      showEditTopic: false,
      currentSelectedTopic: null,

      currentSelectedTopicGoals: [],
      currentSelectedTopicCriteria: [],
      currentSelectedTopicResources: [],
      currentSelectedTopicTrainingGoals: [],

      selectedLessonCategory: null,
      selectedDepartmentIndex: null,
      currentSelectedDepartment: null,
      lessonTabSelected: null,
      schoolId: null,
      curriculum: {
        title: null,
        description: null,
        credit: null,
      },
      departments: [],
      topics: [],
      errorMessage: null,
      school: null,
      lessonCategories: null,
      loading: false,

      addDepartmentDialog: false,
      changedTopicIndex: null,
      newBulkDialog: false,
      bulkText: null,
    };
  },
  props: {
    lesson: {
      required: false,
      type: Object,
    },
  },
  watch: {
    selectedLessonCategory: function () {
      this.selectedLessonCategory.departments = null;
      this.selectedLessonCategory.ref
        .collection("schools")
        .doc(this.schoolId)
        .collection("departments")
        .orderBy("index", "asc")
        .onSnapshot((docs) => {
          this.selectedLessonCategory.departments = [];
          docs.forEach((doc) => {
            let dep = doc.data();
            dep.ref = doc.ref;
            dep.id = doc.id;
            dep.topics = null;
            this.selectedLessonCategory.departments.push(dep);
          });
        });
    },
    currentSelectedDepartment: function () {},
    selectedDepartmentIndex: function (val) {
      var department = this.selectedLessonCategory.departments[val];
      this.currentSelectedDepartment = department;
      if (
        this.currentSelectedDepartment != undefined &&
        this.currentSelectedDepartment != null
      ) {
        this.currentSelectedDepartment.topics = null;
        this.currentSelectedDepartment.ref
          .collection("topics")
          .orderBy("priority", "asc")
          .onSnapshot((docs) => {
            this.currentSelectedDepartment.topics = [];
            docs.forEach((doc) => {
              let topic = doc.data();
              topic.id = doc.id;
              topic.ref = doc.ref;
              topic.editMode = false;

              topic.subtopics = null;
              topic.ref
                .collection("subtopics")
                .orderBy("priority", "asc")
                .onSnapshot((docs) => {
                  topic.subtopics = [];
                  docs.forEach((doc) => {
                    let atopic = doc.data();
                    atopic.id = doc.id;
                    atopic.ref = doc.ref;
                    atopic.editMode = false;
                    topic.subtopics.push(atopic);
                  });
                });

              this.currentSelectedDepartment.topics.push(topic);
              this.$nextTick(() => {
                this.$forceUpdate();
              });
            });
          });
      }
    },
  },
  methods: {
    saveBulk() {
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          var topicPriority = 0;
          var topics = [];
          for (var value of arrayOfLines) {
            var lineArray = value.trim().split("\t");
            console.log(lineArray);
            var newDate = new Date();
            if (lineArray.length == 4) {
              var subtopics = [
                {
                  description: lineArray[3],
                  duration: 0,
                  editMode: false,
                  name: lineArray[2],
                  priority: lineArray[1] - 1,
                  createdAt: newDate,
                },
              ];
              topics.push({
                name: lineArray[0],
                priority: topicPriority,
                subtopics: subtopics,
                createdAt: newDate,
              });
              topicPriority++;
              this.currentSelectedDepartment.ref.update({
                topicCount: fb.firestore.FieldValue.increment(1),
              });
            } else if (lineArray.length == 3) {
              var subtopics2 = {
                description: lineArray[2],
                duration: 0,
                editMode: false,
                name: lineArray[1],
                priority: lineArray[0] - 1,
                createdAt: newDate,
              };
              topics[topics.length - 1].subtopics.push(subtopics2);
            }
          }

          topics.forEach((topic) => {
            this.currentSelectedDepartment.ref
              .collection("topics")
              .add({
                name: topic.name,
                priority: topic.priority,
                createdAt: topic.createdAt,
              })
              .then((topicRef) => {
                var ref = topicRef;
                topic.subtopics.forEach((subtopic) => {
                  if (ref) ref.collection("subtopics").add(subtopic);
                });
              });
          });

          this.newBulkDialog = false;
          this.bulkText = null;
        }
      }
    },
    _getSumOfSubTopicDurations(topic) {
      var sum = 0;
      if (topic.subtopics != null && topic.subtopics != undefined) {
        for (var subtopic of topic.subtopics) {
          sum = sum + subtopic.duration;
        }
      }

      return sum;
    },
    // handling currentSelectedTopicCriteria
    _addItemToTopic(command) {
      if (command == "goals") {
        this.currentSelectedTopicGoals.push({
          name: "Зорилгоx-" + (this.currentSelectedTopicGoals.length + 1),
        });
      } else if (command == "criteria") {
        this.currentSelectedTopicCriteria.push({
          name: "Criteria-" + (this.currentSelectedTopicCriteria.length + 1),
        });
      } else if (command == "resources") {
        this.currentSelectedTopicResources.push({
          name: "Resources-" + (this.currentSelectedTopicResources.length + 1),
        });
      } else if (command == "traininggoals") {
        this.currentSelectedTopicTrainingGoals.push({
          name:
            "Traininggoals-" +
            (this.currentSelectedTopicTrainingGoals.length + 1),
        });
      }
    },
    _removeItemToTopic(command) {
      if (command == "goals") {
        this.currentSelectedTopicGoals.pop();
      } else if (command == "criteria") {
        this.currentSelectedTopicCriteria.pop();
      } else if (command == "resources") {
        this.currentSelectedTopicResources.pop();
      } else if (command == "traininggoals") {
        this.currentSelectedTopicTrainingGoals.pop();
      }
    },

    _editTopic(department, topic) {
      this.currentSelectedTopic = Object.assign({}, topic);

      if (topic.goals != undefined || topic.goals != null) {
        this.currentSelectedTopicGoals = JSON.parse(
          JSON.stringify(topic.goals)
        );
      }
      if (topic.criteria != undefined || topic.criteria != null) {
        this.currentSelectedTopicCriteria = JSON.parse(
          JSON.stringify(topic.criteria)
        );
      }
      if (topic.resources != undefined || topic.resources != null) {
        this.currentSelectedTopicResources = JSON.parse(
          JSON.stringify(topic.resources)
        );
      }
      if (topic.traininggoals != undefined || topic.traininggoals != null) {
        this.currentSelectedTopicTrainingGoals = JSON.parse(
          JSON.stringify(topic.traininggoals)
        );
      }
      this.showEditTopic = true;
    },
    _closeTopic() {
      this.currentSelectedTopic = null;
      this.currentSelectedTopicGoals = [];
      this.currentSelectedTopicCriteria = [];
      this.currentSelectedTopicResources = [];
      this.currentSelectedTopicTrainingGoals = [];
      this.showEditTopic = false;
    },
    _saveTopic() {
      this.showEditTopic = false;
      this.currentSelectedTopic.goals = this.currentSelectedTopicGoals;
      this.currentSelectedTopic.criteria = this.currentSelectedTopicCriteria;
      this.currentSelectedTopic.resources = this.currentSelectedTopicResources;
      this.currentSelectedTopic.traininggoals =
        this.currentSelectedTopicTrainingGoals;
      this.currentSelectedTopic.ref
        .update(this.currentSelectedTopic)
        .then(() => {});
    },

    _deleteTopic(department, topic) {
      var yes = confirm("Та энэ нэгж сэдвийг устгаxыг xүсэж байна уу?");
      if (yes) {
        var batch = fb.db.batch();
        topic.ref.collection("subtopics").onSnapshot((docs) => {
          docs.forEach((doc) => {
            batch.delete(doc.ref);
          });
          batch.commit().then(() => {
            topic.ref.delete().then(() => {
              department.ref.update({
                topicCount: fb.firestore.FieldValue.increment(-1),
              });
            });
          });
        });
      }
    },
    _cancelSubTopic(item) {
      item.editMode = false;
    },
    _addSubTopic(department, topic) {
      topic.ref
        .collection("subtopics")
        .doc()
        .set(
          {
            name: "Ээлжит сэдэв ороогүй",
            duration: 0,
            description: null,
            priority:
              topic.topicCount != undefined && topic.topicCount != null
                ? topic.topicCount + 1
                : 0,
          },
          { merge: true }
        )
        .then(() => {
          topic.ref.update({
            topicCount: fb.firestore.FieldValue.increment(1),
          });
        });
    },
    _saveSubTopic(item) {
      console.log(item);
      var tt = {
        name: item.name,
        priority: item.priority,
        duration: isNaN(parseInt(item.duration)) ? 0 : parseInt(item.duration),
        description: isNaN(item.description) ? item.description : null,
      };
      item.ref.update(tt).then(() => {
        item.editMode = false;
      });
    },
    _getLength(department) {
      if (department.topics != undefined && department.topics != null)
        return department.topics.length;
      else return -1;
    },
    _deleteDepartment(department) {
      var yes = confirm(
        "Та энэxүү ангийг устгаxыг xүсэж байна уу? Даxин сэргээx боломжгүй!"
      );
      if (yes) {
        department.ref.delete().then(() => {
          for (var dep of this.datas2) {
            if (dep.index == department.index) {
              dep.selected = false;
              break;
            }
          }
        });
      }
    },
    _deleteAllTopic(department) {
      var yes = confirm(
        "Та бүx сэдвүүдийг устгаxыг xүсэж байна уу? Даxин сэргээx боломжгүй!"
      );
      if (yes) {
        var batch = fb.db.batch();
        for (var topic of department.topics) {
          batch.delete(topic.ref); //TODOX
        }
        this.$nextTick(() => {
          batch.commit().then(() => {
            this.currentSelectedDepartment.topics = [];
            this.$forceUpdate();
          });
        });
      }
    },
    _closeDepartmentDialog() {
      this.addDepartmentDialog = false;
      for (var department of this.datas2) {
        department.selected = false;
      }
      this.$nextTick(() => {
        this.addDepartmentDialog = false;
        // this.editedCategory = Object.assign({}, this.defaultCategory);
        // this.editedCategoryIndex = -1;
      });
    },
    _isContained(newDepartment) {
      for (var department of this.selectedLessonCategory.departments) {
        if (newDepartment.index == department.index) return true;
      }
      return false;
    },
    _saveDepartmentDialog() {
      this.addDepartmentDialog = false;
      for (var newDepartment of this.datas2) {
        if (newDepartment.selected) {
          if (this._isContained(newDepartment) == false) {
            //new
            this.selectedLessonCategory.ref
              .collection("schools")
              .doc(this.schoolId)
              .set({
                createdAt: new Date(),
                schoolName: this.userData.school.name,
                schoolRef: this.userData.school.ref
              });
            this.selectedLessonCategory.ref
              .collection("schools")
              .doc(this.schoolId)
              .collection("departments")
              .doc()
              .set({
                name: newDepartment.index,
                index: newDepartment.index,
                topicCount: 0,
              });
          } else {
            console.log("already: " + newDepartment.index);
          }
          console.log("------");
        } else {
          if (this._isContained(newDepartment) == true) {
            console.log("removed:", newDepartment.index);
          }
        }
      }
    },

    _addDepartmentDialog() {
      this.addDepartmentDialog = true;
      for (var department of this.selectedLessonCategory.departments) {
        this.datas2[department.index - 1].selected = true;
      }
    },
    _changeItem(item, keyword) {
      console.log(item, keyword);
      // if (keyword == "duration") {
      //   const parsed = parseInt(item[keyword]);
      //   if (!isNaN(parsed)) {
      //     item.ref.update({ [keyword]: parsed });
      //   }
      // } else item.ref.update({ [keyword]: item[keyword] });
    },
    async _editSubTopic(item) {
      item.editMode = !item.editMode;
      this.$forceUpdate();
    },
    _removeSubTopic(topic) {
      var yes = confirm("Та арилгаxыг xүсэж байна уу?");
      if (yes) {
        topic.ref.delete().then(() => {
          topic.ref.parent.parent.update({
            topicCount: fb.firestore.FieldValue.increment(-1),
          });
        });
      }
    },
    _saveOrder(val) {
      console.log(val);
      console.log(
        this.currentSelectedDepartment.topics[this.changedTopicIndex]
      );
      if (
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.newIndex
        ] &&
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.newIndex
        ].ref
      )
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.newIndex
        ].ref.update({ priority: val.oldIndex });

      if (
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.oldIndex
        ] &&
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.oldIndex
        ].ref
      )
        this.currentSelectedDepartment.topics[this.changedTopicIndex].subtopics[
          val.oldIndex
        ].ref.update({ priority: val.newIndex });

      // for (var topic of this.currentSelectedDepartment.topics) {
      //   var counter = 0;
      //   for (var subTopic of topic.subtopics) {
      //     console.log(subTopic.name, subTopic.priority, counter);
      //     counter++;
      //   }
      //   console.log("----");
      // }
      // var batch = fb.db.batch();
      // for (
      //   let index = 0;
      //   index < this.currentSelectedDepartment.topics.length;
      //   index++
      // ) {
      //   var t = this.currentSelectedDepartment.topics[index];
      //   batch.update(t.ref, { priority: index });
      // }
      // batch.commit().then(() => {});
    },
    _addTopic(department) {
      department.ref
        .collection("topics")
        .doc()
        .set({
          name: "Нэгж xичээлийн сэдэв ороогүй",
          priority: 0,
          description: null,
          topicCount: 0,
          duration: 0,
        })
        .then(() => {
          department.ref
            .update({
              topicCount: fb.firestore.FieldValue.increment(1),
            })
            .then(() => {
              this.$nextTick(() => {
                // this.$forceUpdate();
              });
            });
        });
    },

    save() {
      if (
        this.curriculum != null &&
        this.curriculum.title != "" &&
        this.curriculum.description != null &&
        this.curriculum.credit != null
      ) {
        if (this.curriculum.id != null && this.curriculum.id != "") {
          this.curriculum.ref.update({
            title: this.curriculum.title,
            description: this.curriculum.description,
            credit: this.curriculum.credit,
            modifiedAt: new Date(),
          });
        } else {
          this.curriculum.createdAt = new Date();
          this.curriculum.role = "teacher";

          fb.db
            .collection("schools")
            .doc(this.schoolId)
            .collection("employees")
            .doc()
            .set(this.curriculum);
        }
        this.curriculum = {
          title: null,
          credit: null,
          description: null,
        };
        this.errorMessage = null;
        this.$("#leaderModal").modal("hide");
      } else {
        this.errorMessage = "Нэр, имэйл, утас, үүрэг заавал оруулна.";
      }
    },
    async _setupp() {
      var schoolRef = await fb.db.doc(this.zzschool).get();
      if (schoolRef.exists) {
        let school = schoolRef.data();
        school.id = schoolRef.id;
        school.ref = schoolRef.ref;
        this.school = school;

        fb.db
          .collection("categories")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            var counter = 0
            this.lessonCategories = [];
            querySnapshot.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              lesson.departments = null;
              lesson.name2 = (++counter) +". " + lesson.name + " ("+lesson.lessonTypeName+") "  
              fb.db
                .collection("categories")
                .doc(lesson.id)
                .collection("schools")
                .doc(this.schoolId)
                .collection("departments")
                .onSnapshot((querySnapshot) => {
                  lesson.departments = [];
                  querySnapshot.forEach((doc) => {
                    let dep = doc.data();
                    dep.id = doc.id;
                    dep.ref = doc.ref;
                    lesson.departments.push(dep);
                  });
                });
              this.lessonCategories.push(lesson);
            });
          });
      }
      this._getDepartments(this.school.currentYear);
    },
    _getDepartments(startYear) {
      if (this.school != null) {
        fb.db
          .doc(this.zzschool)
          .collection("departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "asc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              this.departments.push(lesson);
            });
          });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    lengthOfCurrentTopics() {
      if (
        this.currentSelectedDepartment != null &&
        this.currentSelectedDepartment != undefined &&
        this.currentSelectedDepartment.topics != null &&
        this.currentSelectedDepartment.topics != undefined
      )
        //return this.currentSelectedDepartment.topics.length;
        return this.currentSelectedDepartment.topicCount;
      else return 0;
    },
    timesOfCurrentTopics: function () {
      var sum = 0;
      if (
        this.currentSelectedDepartment != null &&
        this.currentSelectedDepartment != undefined &&
        this.currentSelectedDepartment.topics != null &&
        this.currentSelectedDepartment.topics != undefined
      ) {
        for (var topic of this.currentSelectedDepartment.topics) {
          console.log(topic.duration, !isNaN(topic.duration));
          if (!isNaN(topic.duration)) sum = sum + topic.duration;
        }
      }

      return sum;
    },
  },

  created() {
    if (this.userData.school) this.schoolId = this.userData.school.id;

    if (this.zzschool != null && this.zzschool != "") this._setupp();
    if (this.lesson) this.selectedLessonCategory = this.lesson;
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0rem !important;
}
.handle {
  cursor: move;
}
.txtbutton {
  letter-spacing: 0 !important;
  text-transform: none !important;
}
</style>