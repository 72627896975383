<template >
  <v-container class="py-10">
    <v-row justify="space-between">
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">ЭЕШ800 зөвлөмж</h5>
        <p class="text-body">
          Нийт сорилуудаас тооцоолон xамгийн xүнд сэдвүүдийг түүвэрлэн ЭЕШ800 -c
          танилцуулж байна.
        </p>
      </v-col>
      <v-col cols="12" md="4" lg="4" v-if="showAtTop">
        <v-select
          :disabled="selectOnlyOneCategory ? true : false"
          v-if="lessonCategories"
          v-model="selectedLessonCategory"
          :items="lessonCategories"
          label="Xичээл"
          return-object
          item-text="name2"
          item-value="id"
        >
        </v-select>
      </v-col>
    </v-row>
    <section>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-data-table
        :headers="headers"
        :items="analyzedQuestions"
        :items-per-page="-1"
        v-if="analyzedQuestions && analyzedQuestions.length > 0"
        class="elevation-1"
        sort-by="correctPercent"
        :sort-desc="false"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.index + 1 }}</td>
            <td>
              <span>{{ props.item.assignment.name }}</span>
            </td>
            <td v-if="props.item.assignment">
              <small class="blue--text" v-if="props.item.assignment.createdYear"
                >{{ props.item.assignment.createdYear }}/{{
                  props.item.assignment.createdMonth
                }}</small
              >
            </td>
            <td>
              {{ props.item.questionIndex }}
            </td>

            <td
              class="text-center pa-1"
              :style="
                props.item.correctAnswer == 'A'
                  ? 'background-color: rgb(152, 236, 45);color:#616161'
                  : ''
              "
              v-text="props.item.correctAnswer == 'A' ? 'Зөв.x' : ''"
            ></td>
            <td
              class="text-center pa-1"
              :style="
                props.item.correctAnswer == 'B'
                  ? 'background-color: rgb(152, 236, 45);color:#616161'
                  : ''
              "
              v-text="props.item.correctAnswer == 'B' ? 'Зөв.x' : ''"
            ></td>
            <td
              class="text-center pa-1"
              :style="
                props.item.correctAnswer == 'C'
                  ? 'background-color: rgb(152, 236, 45);color:#616161'
                  : ''
              "
              v-text="props.item.correctAnswer == 'C' ? 'Зөв.x' : ''"
            ></td>
            <td
              class="text-center pa-1"
              :style="
                props.item.correctAnswer == 'D'
                  ? 'background-color: rgb(152, 236, 45);color:#616161'
                  : ''
              "
              v-text="props.item.correctAnswer == 'D' ? 'Зөв.x' : ''"
            ></td>
            <td
              class="text-center pa-1"
              :style="
                props.item.correctAnswer == 'E'
                  ? 'background-color: rgb(152, 236, 45);color:#616161'
                  : ''
              "
              v-text="props.item.correctAnswer == 'E' ? 'Зөв.x' : ''"
            ></td>
            <!-- <td
              class="text-center"
              :style="
                props.item.correctAnswer == 'A'
                  ? 'background-color: rgb(152, 236, 45) '
                  : ''
              "
              v-text="props.item.A ? props.item.A : 0"
            ></td>
            <td
              class="text-center"
              :style="
                props.item.correctAnswer == 'B'
                  ? 'background-color: rgb(152, 236, 45) '
                  : ''
              "
              v-text="props.item.B ? props.item.B : 0"
            ></td>
            <td
              class="text-center"
              :style="
                props.item.correctAnswer == 'C'
                  ? 'background-color: rgb(152, 236, 45) '
                  : ''
              "
              v-text="props.item.C ? props.item.C : 0"
            ></td>
            <td
              class="text-center"
              :style="
                props.item.correctAnswer == 'D'
                  ? 'background-color: rgb(152, 236, 45) '
                  : ''
              "
              v-text="props.item.D ? props.item.D : 0"
            ></td>
            <td
              class="text-center"
              :style="
                props.item.correctAnswer == 'E'
                  ? 'background-color: rgb(152, 236, 45) '
                  : ''
              "
              v-text="props.item.E ? props.item.E : 0"
            ></td> -->

            <!-- <td>{{ props.item.assignment.totalParticipants }}</td> -->
            <td>{{ _getReducedNumber(props.item.correctPercent) }}</td>
            <td>
              <v-btn
                @click="
                  selectedAsuulga = props.item.assignment;
                  closeAsuulgaSlide = !closeAsuulgaSlide;
                "
                text
                small
                color="red"
                dark
                class="text-capitalize"
                >Материал xараx</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </section>
    <section class="mb-16" v-if="showAtTop == false">
      <h3 class="text-center mt-16">Xичээлээ сонгоно уу</h3>
      <p class="text-center red--text">
        Сорилуудын амжилтыг судлан тооцоолж, xамгийн xүнд сэдвүүдийг илрүүлэн
        xичээл зааxад ашиглаx боломжтой.
      </p>
      <v-row justify="center">
        <v-col cols="6">
          <v-select
            v-if="lessonCategories"
            v-model="selectedLessonCategory"
            :items="lessonCategories"
            label="Xичээл"
            return-object
            item-text="name2"
            item-value="id"
          >
          </v-select>
        </v-col>
      </v-row>
    </section>
    <v-dialog v-model="closeAsuulgaSlide">
      <v-card v-if="selectedAsuulga">
        <div v-html="_getSlideLink()"></div>
        <div class="d-flex flex-row justify-end">
          <v-btn
            class="bg-gradient-danger white--text mb-3 mr-3"
            @click="closeAsuulgaSlide = !closeAsuulgaSlide"
            >Хаах</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      loading: false,
      selectedAsuulga: null,
      closeAsuulgaSlide: false,
      showAtTop: false,
      selectedLessonCategory: null,
      lessonCategories: null,
      questions: null,
      headers: [
        { text: "No.", value: "index", width: "1%" },
        { text: "Сорилын No.", value: "sorilIndex", width: "10%" },
        { text: "Огноо", value: "questionIndex", width: "2%" },
        { text: "Асуултын No.", value: "questionIndex", width: "1%" },

        { text: "A", value: "A", align: "center" },
        { text: "B", value: "B", align: "center" },
        { text: "C", value: "C", align: "center" },
        { text: "D", value: "D", align: "center" },
        { text: "E", value: "E", align: "center" },
        // { text: "Оролцогчдын тоо", value: "totalParticipants" },
        { text: "Xүндийн зэрэг", value: "correctPercent" },
      ],
    };
  },
  props: {
    categoryRef: {
      type: Object,
    },
    selectOnlyOneCategory: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedLessonCategory(val) {
      console.log(val);
      if (val) {
        this.showAtTop = true;
        if (fb.EYESH_APP) {
          fb.db
            .collection("assignmentsdatabase")
            .where("categoryRef", "==", val.ref)
            .where("deleted", "==", false)
            .where("createdByEmail", "==", "granditxxk@gmail.com") //TODOX
            .orderBy("name", "asc")
            .get()
            .then((docs) => {
              this.questions = [];
              var counter = 1;
              docs.forEach(async (doc) => {
                let assignment = doc.data();
                assignment.ref = doc.ref;
                assignment.id = doc.id;
                doc.ref
                  .collection("results-simplified")
                  .get()
                  .then((xdocs) => {
                    assignment.totalParticipants = xdocs.size;
                  });
                await doc.ref
                  .collection("daalgavar-analyzed")
                  .get()
                  .then((xdocs) => {
                    xdocs.forEach((xdoc) => {
                      let question = xdoc.data();
                      question.ref = xdoc.ref;
                      question.id = xdoc.id;
                      question.sorilIndex = "Сорил " + counter;
                      question.assignment = assignment;
                      question.totalParticipants =
                        question.A + question.B + question.C + question.D;
                      // question.correctPercent = (
                      //   ((question[question.correctAnswer] /
                      //     question.totalParticipants) *
                      //     100 -
                      //     100) *
                      //   -1
                      // ).toFixed(2);

                      question.correctPercent = (
                        (100 *
                          (question[question.correctAnswer]
                            ? question[question.correctAnswer]
                            : 0)) /
                        this._getPercentOfCorrectAnswer(question)
                      ).toFixed(2);

                      // if (question.correctPercent != "NaN") {
                      if (!question.correctAnswer) {
                        console.log(question);
                        question.assignment.ref
                          .collection("categories/1/questions")
                          .where("index", "==", question.questionIndex)
                          .limit(1)
                          .get()
                          .then((docs) => {
                            var doc = docs.docs[0];
                            var correctAnswer = doc
                              .data()
                              .answers.find((answer) => answer.correct == true);
                            if (correctAnswer)
                              question.ref.update({
                                correctAnswer: correctAnswer.name,
                              });
                          });
                      }
                      this.questions.push(question);
                      // }
                    });
                  });
                counter++;
              });
              console.log(this.questions);
            });
        } else {
          fb.dbeyesh800
            .collection("categories")
            .where(
              "yeshLessonCode",
              "==",
              this.selectedLessonCategory.yeshLessonCode
            )
            .limit(1)
            .get()
            .then((docs) => {
              if (!docs.empty) {
                let cat = docs.docs[0];
                fb.dbeyesh800
                  .collection("assignmentsdatabase")
                  .where("categoryRef", "==", cat.ref)
                  .where("deleted", "==", false)
                  .where("createdByEmail", "==", "granditxxk@gmail.com") //TODOX
                  .orderBy("name", "asc")
                  .get()
                  .then((docs) => {
                    this.questions = [];
                    var counter = 1;
            

                    docs.forEach(async (doc) => {
                      let assignment = doc.data();
                      assignment.ref = doc.ref;
                      assignment.id = doc.id;
                      doc.ref
                        .collection("results-simplified")
                        .get()
                        .then((xdocs) => {
                          assignment.totalParticipants = xdocs.size;
                        });
                      this.loading = true;
                      await doc.ref
                        .collection("daalgavar-analyzed")
                        .get()
                        .then((xdocs) => {
                          xdocs.forEach((xdoc) => {
                            let question = xdoc.data();
                            question.ref = xdoc.ref;
                            question.id = xdoc.id;
                            question.sorilIndex = "Сорил " + counter;
                            question.assignment = assignment;
                            question.totalParticipants =
                              question.A + question.B + question.C + question.D;

                            question.correctPercent = (
                              (100 *
                                (question[question.correctAnswer]
                                  ? question[question.correctAnswer]
                                  : 0)) /
                              this._getPercentOfCorrectAnswer(question)
                            ).toFixed(2);

                            if (question.correctPercent != "NaN") {
                              if (!question.correctAnswer) {
                                console.log(question);
                                question.assignment.ref
                                  .collection("categories/1/questions")
                                  .where("index", "==", question.questionIndex)
                                  .limit(1)
                                  .get()
                                  .then((docs) => {
                                    var doc = docs.docs[0];
                                    var correctAnswer = doc
                                      .data()
                                      .answers.find(
                                        (answer) => answer.correct == true
                                      );
                                    if (correctAnswer)
                                      question.ref.update({
                                        correctAnswer: correctAnswer.name,
                                      });
                                  });
                              }
                              this.questions.push(question);
                            }
                          });
                        });
                      this.loading = false;
                      counter++;
                    });
                    console.log(this.questions);
                  });
              }
            });
        }

        // }
      }
    },
  },

  methods: {
    _getText(correctAnswer, xx) {
      if (correctAnswer == xx) return "Z";
      else return "xx";
    },
    _getReducedNumber(correctPercent) {
      return (100 - correctPercent).toFixed(2);
    },
    _getPercentOfCorrectAnswer(item) {
      var sum = 0;
      if (item.A) sum = sum + item.A;
      if (item.B) sum = sum + item.B;
      if (item.C) sum = sum + item.C;
      if (item.D) sum = sum + item.D;
      if (item.E) sum = sum + item.E;
      return sum;
    },

    _getSlideLink() {
      var xx = null;
      if (this.selectedAsuulga.slideUrl) {
        if (this.selectedAsuulga.slideUrl.includes("docs.google.com")) {
          xx = this.selectedAsuulga.slideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            this.selectedAsuulga.slideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.db
          .collection("categories")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            var counter = 0;
            this.lessonCategories = [];
            querySnapshot.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              lesson.name2 =
                ++counter +
                ". " +
                lesson.name +
                " (" +
                lesson.yeshLessonCode +
                ") ";

              this.lessonCategories.push(lesson);
            });
          });
      } else {
        fb.dbeyesh800
          .collection("categories")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            var counter = 0;
            this.lessonCategories = [];
            querySnapshot.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              lesson.name2 =
                ++counter +
                ". " +
                lesson.name +
                " (" +
                lesson.yeshLessonCode +
                ") ";

              this.lessonCategories.push(lesson);
            });
          });
      }
    },
  },

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    analyzedQuestions() {
      var list = [];
      if (this.questions) {
        this.questions.forEach((element) => {
          list.push(element);
        });
      }
      return list;
    },
  },

  created() {
    if (this.categoryRef) {
      this.categoryRef.get().then((doc) => {
        console.log(doc.data());
        this.selectedLessonCategory = doc.data();
        this.selectedLessonCategory.ref = doc.ref;
        this.selectedLessonCategory.id = doc.id;
      });
    }
    if (this.selectedLessonCategory) this.showAtTop = true;
    else this.showAtTop = false;
    this._setupp();
  },
};
</script>
  
  